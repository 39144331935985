import { StatusCode } from 'constants/API';
import { call, put, takeLatest } from 'redux-saga/effects';
import APIs from 'APIs';
import Slice from '.';
import _ from 'lodash';

function* createMemberFlow({ payload }) {
  try {
    const response = yield call(APIs.createMemberFromInvite, payload);
    if (response.status === StatusCode.SUCCESS_CREATED) {
      yield put({
        type: Slice.createMemberSuccess.type,
        payload: response.data,
      });
    } else {
      yield put({
        type: Slice.createMemberFail.type,
        payload: response.data,
      });
    }
  } catch (error) {
    yield put({
      type: Slice.createMemberFail.type,
      payload:
        _.get(error, 'response.data.detail') ||
        _.get(error, 'response.data') ||
        '',
    });
  }
}

function* getInviteMetaDataFlow({ payload }) {
  try {
    const response = yield call(APIs.getInviteMetadata, payload);
    if (response.status === StatusCode.SUCCESS_COMMON) {
      yield put({
        type: Slice.getInviteMetaDataSuccess.type,
        payload: response.data,
      });
    } else {
      yield put({
        type: Slice.getInviteMetaDataFail.type,
        payload: response.data,
      });
    }
  } catch (error) {
    yield put({
      type: Slice.getInviteMetaDataFail.type,
      payload:
        _.get(error, 'response.data.detail') ||
        _.get(error, 'response.data') ||
        '',
    });
  }
}

function* teamWatcher() {
  yield takeLatest(Slice.createMemberRequest, createMemberFlow);
  yield takeLatest(Slice.getInviteMetaDataRequest, getInviteMetaDataFlow);
}

export default teamWatcher;

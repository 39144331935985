import React, { useEffect, useState } from 'react';
// UI
import { Helmet } from 'react-helmet-async';

import {
  Box,
  Stack,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
  Paper,
  Toolbar,
  Button,
} from '@mui/material';

import { Wrapper, Content } from 'app/components/Wrapper';
import { Container } from 'app/components/Container';

// Redux & Sagas
import { useDispatch, useSelector } from 'react-redux';
import { useGlobalSlice } from 'app/pages/GlobalContainer/slice';
import Path from 'config/clientPath';
import { useLocation } from 'react-router';
import { Loader } from 'app/components/Loader';
import { checkIsRequesting } from 'utils/helper';
import { selectGlobal } from '../GlobalContainer/slice/selectors';
import moment from 'moment';
import { selectTimesheet } from '../TimesheetPage/slice/selectors';
import { useTimesheetSlice } from '../TimesheetPage/slice';
import queryString from 'query-string';
import _ from 'lodash';
import FilterBar from './components/FilterBar';
import { TagComponent } from 'app/components/TagComponent';
import { TASK_STATUS_LIST } from 'constants/common';
import { RequestStatus } from 'constants/API';
import styled from 'styled-components';
import { ProjectLink } from 'app/components/ProjectLink';

export function ReportReview() {
  const dispatch = useDispatch();

  const location = useLocation();
  const { setBreadcumbs, setSuccessMessages, setErrorMessages } =
    useGlobalSlice().actions;
  const [month, setMonth] = useState<string>('');
  const [project, setProject] = useState('');

  const {
    timesheetList,
    getTimesheetStatus,
    requestApprovalStatus,
    cancelRequestStatus,
    getAllProjectsStatus,
  } = useSelector(selectTimesheet);

  const {
    actions: {
      getTimesheetRequest,
      resetTimesheetRequestStatus,
      requestApprovalRequest,
      cancelRequestRequest,
      getAllProjectsRequest,
    },
  } = useTimesheetSlice();

  const {
    userSessionData: { profile },
  } = useSelector(selectGlobal);

  useEffect(() => {
    if (month) {
      dispatch(
        getAllProjectsRequest({
          month,
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [month]);

  const reloadData = () => {
    const params = queryString.parse(window.location.search);
    const newCurrentDate = params?.month ? moment(params?.month) : moment();
    setMonth(newCurrentDate.format('YYYY-MM'));
    const project = params?.project || '';
    setProject(project);
    dispatch(
      getTimesheetRequest({
        date_start: newCurrentDate
          .clone()
          .startOf('month')
          .format('yyyy-MM-DD'),
        date_end: newCurrentDate.clone().endOf('month').format('yyyy-MM-DD'),
        member: profile?.id,
        project,
        page: +params?.page || 1,
        paging: false,
      }),
    );
  };

  useEffect(
    () => () => {
      dispatch(resetTimesheetRequestStatus());
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    ['componentWillUnMount'],
  );

  const isLoading = checkIsRequesting([
    getTimesheetStatus,
    getAllProjectsStatus,
    requestApprovalStatus,
    cancelRequestStatus,
  ]);

  const onSuccess = (messages: string[]) => {
    dispatch(setSuccessMessages(messages));
    reloadData();
  };

  useEffect(() => {
    if (requestApprovalStatus === RequestStatus.SUCCESS) {
      onSuccess(['Request Approval success']);
    }
    if (requestApprovalStatus === RequestStatus.ERROR) {
      dispatch(setErrorMessages(['Oops.. Something went wrong!']));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestApprovalStatus]);

  useEffect(() => {
    if (cancelRequestStatus === RequestStatus.SUCCESS) {
      onSuccess(['Cancel Request success']);
    }
    if (cancelRequestStatus === RequestStatus.ERROR) {
      dispatch(setErrorMessages(['Oops.. Something went wrong!']));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cancelRequestStatus]);

  useEffect(() => {
    reloadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  useEffect(() => {
    dispatch(
      setBreadcumbs([
        { title: 'Report', path: Path.TIMESHEET_REPORT },
        { title: 'Report Review' },
      ]),
    );
    const params = queryString.parse(window.location.search);
    const newCurrentDate = params?.month ? moment(params?.month) : moment();
    setMonth(newCurrentDate.format('YYYY-MM'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const curentStatus = _.head(timesheetList)?.status;
  return (
    <>
      <Helmet>
        <title>Report Review</title>
        <meta name="description" content="Palette" />
      </Helmet>
      <Container>
        <Wrapper>
          <Loader open={isLoading} />
          <Content spacing={2}>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
            >
              <Typography variant="h6" component="h6">
                Report Review
              </Typography>
            </Box>

            <FilterBar />
            <Box>
              {!_.isEmpty(timesheetList) ? (
                <Toolbar
                  sx={{
                    pl: { sm: 2 },
                    pr: { xs: 1, sm: 1 },
                    ...{
                      bgcolor: '#BDD8FF',
                      borderRadius: '8px 8px 0 0',
                    },
                  }}
                >
                  <Stack
                    direction="row"
                    display="flex"
                    spacing={1}
                    alignItems="center"
                    justifyContent="space-between"
                    style={{ width: '100%' }}
                  >
                    <Box>
                      Total:{' '}
                      <Box component="span" style={{ fontWeight: 600 }}>
                        {` ${_.sumBy(timesheetList, v => +v.hours)}h (${
                          TASK_STATUS_LIST[curentStatus]?.admin?.label || ''
                        })`}
                      </Box>
                    </Box>
                    {!_.isEmpty(timesheetList) && (
                      <Stack direction="row" spacing={1}>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => {
                            dispatch(
                              requestApprovalRequest({
                                month,
                                project,
                              }),
                            );
                          }}
                        >
                          {'Request approve all'}
                        </Button>
                        <Button
                          variant="contained"
                          style={{ backgroundColor: '#dedede', color: '#000' }}
                          onClick={() => {
                            dispatch(
                              cancelRequestRequest({
                                month,
                                project,
                              }),
                            );
                          }}
                        >
                          {'Cancel request all'}
                        </Button>
                      </Stack>
                    )}
                  </Stack>
                </Toolbar>
              ) : (
                ''
              )}
              <WrapTable>
                <TableContainer component={Paper}>
                  <Table stickyHeader>
                    <TableHead>
                      <TableRow>
                        <TableCell>No</TableCell>
                        <TableCell>Project</TableCell>
                        <TableCell>Date</TableCell>
                        <TableCell>Duration</TableCell>
                        <TableCell>Tag</TableCell>
                        <TableCell>Description</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {timesheetList.map((task, key) => {
                        return (
                          <TableRow key={`approval_row_${key}`}>
                            <TableCell width={60}>{key + 1}</TableCell>
                            <TableCell>
                              <ProjectLink
                                project_id={task?.project?.id}
                                project_name={task?.project?.name}
                              ></ProjectLink>
                            </TableCell>

                            <TableCell>
                              {moment(task?.report_date).format(
                                'YYYY-MM-DD ddd',
                              )}
                            </TableCell>

                            <TableCell>{task?.hours + 'h'}</TableCell>
                            <TableCell>
                              <Stack spacing={1}>
                                {task?.tags.map((v, k) => (
                                  <TagComponent data={v} key={k} />
                                ))}
                              </Stack>
                            </TableCell>
                            <TableCell width={180}>
                              {task?.description}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                    {_.isEmpty(timesheetList) && (
                      <TableBody>
                        <TableRow>
                          <TableCell colSpan={9}>No data</TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                  </Table>
                </TableContainer>
              </WrapTable>
            </Box>
          </Content>
        </Wrapper>
      </Container>
    </>
  );
}

const WrapTable = styled(Box)`
  & .MuiTableContainer-root {
    max-height: calc(100vh - 288px);
  }
`;

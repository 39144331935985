import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
// UI
import {
  Box,
  IconButton,
  Collapse,
  Stack,
  Typography,
  Paper,
} from '@mui/material';
import styled from 'styled-components/macro';
import { RequestStatus } from 'constants/API';
import { selectProject } from 'app/pages/ProjectPage/slice/selectors';

import { HourTag } from 'app/components/HourTag';
import { useDispatch } from 'react-redux';
import {
  KeyboardArrowUp as KeyboardArrowUpIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,
} from '@mui/icons-material';

import Assignment from 'app/components/Assignment';
import { useGlobalSlice } from 'app/pages/GlobalContainer/slice';
export function CollapseTable(props) {
  const dispatch = useDispatch();
  const { getProjectListStatus, updateProjectStatus } =
    useSelector(selectProject);
  const { setSuccessMessages, setErrorMessages } = useGlobalSlice().actions;
  const { initInfo } = props;
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    if (updateProjectStatus === RequestStatus.SUCCESS) {
      dispatch(setSuccessMessages(['Project update successful!']));
    }
    if (updateProjectStatus === RequestStatus.ERROR) {
      dispatch(setErrorMessages(['Opps..Project update failed!']));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getProjectListStatus, updateProjectStatus]);
  return (
    <CollapseTableWrapper>
      <Paper elevation={0}>
        <Box
          display="flex"
          flexDirection="row"
          style={{ padding: '10px 0 10px 5px' }}
          sx={{ cursor: 'pointer' }}
          onClick={() => {
            setOpen(!open);
          }}
        >
          <IconButton
            aria-label="expand row"
            size="small"
            style={{ marginRight: '5px' }}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
          <Stack direction="row" spacing={1} alignItems="center">
            <Typography variant="h6" gutterBottom component="span" mb={0}>
              {initInfo.name}
            </Typography>
            <Typography variant="h6" gutterBottom component="span">
              <HourTag
                data={{
                  backgroundColor: '#BDD8FF',
                  label: `${
                    initInfo.total_hours ? initInfo.total_hours : '0'
                  }h`,
                }}
              ></HourTag>
            </Typography>
          </Stack>
        </Box>
        <Collapse in={open} timeout="auto">
          <Assignment
            showHeader={false}
            showMember={true}
            showProject={false}
            projectInfo={initInfo}
          />
        </Collapse>
      </Paper>
    </CollapseTableWrapper>
  );
}
const CollapseTableWrapper = styled(Box)`
  width: 100%;
  margin: 10px 0px;
  .MuiTypography-root {
    margin-bottom: 0px;
  }
  .MuiTableCell-root {
    border: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  }
  .MuiTableContainer-root {
    margin-top: 0px;
    border-top: 1px solid rgba(0, 0, 0, 0.2);
  }
`;
export default CollapseTable;

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import CompanyA from 'assets/img/company_A.png';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { ICustomer } from '../slice/types';
import { MainSectionWrapper } from './About';
import { Heading } from './Heading';
import { Colors, device } from './Variables';

interface Props {
  customers: ICustomer[] | null;
}

export const Customers = (props: Props) => {
  const { customers } = props;

  return (
    <MainSectionWrapper id="Customers">
      <Heading>Our clients</Heading>
      <GridBox sx={{ flexGrow: 1 }}>
        <Grid
          container
          spacing={{ xs: 1, md: 2 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
        >
          {customers != null
            ? customers.map(it => (
                <Grid item xs={2} sm={3} md={3} key={it.id}>
                  <Link
                    to={{ pathname: it.url }}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <ImgBox>
                      <img src={it.logo_url ?? CompanyA} alt={it.name} />
                    </ImgBox>
                  </Link>
                </Grid>
              ))
            : ''}
        </Grid>
      </GridBox>
    </MainSectionWrapper>
  );
};

const ImgBox = styled.p`
  display: inline-block;
  margin: 0;
  background: ${Colors.lightGrey};
  img {
    width: 230px;
    height: 120px;
    max-width: 100%;
    object-fit: cover;
    @media ${device.laptop} {
      width: 210px;
      height: 100px;
    }
  }
`;

const GridBox = styled(Box)`
  padding: 20px;

  .MuiGrid-item {
    width: calc(33% - 8px);
    @media ${device.mobileM} {
      margin-bottom: 8px;
    }
  }
  .MuiGrid-container {
    justify-content: center;
  }
  @media ${device.mobileXL} {
    .MuiGrid-container {
      justify-content: flex-start;
    }
  }
  @media ${device.mobileM} {
    padding: 20px 0;
  }
`;

import { StatusCode } from 'constants/API';
import { call, put, takeLatest } from 'redux-saga/effects';
import APIs from 'APIs';
import Slice from '.';
import _ from 'lodash';

function* getProjectListFlow({ payload }) {
  try {
    const response = yield call(APIs.getProjectList, payload);
    if (response.status === StatusCode.SUCCESS_COMMON) {
      const { type } = Slice.getProjectListSuccess;
      if (!payload.paging) {
        yield put({
          type: Slice.getProjectListMetaDataSuccess.type,
          payload: response.data,
        });
      } else {
        yield put({
          type,
          payload: response.data,
        });
      }
      yield put({
        type,
        payload: response.data,
      });
    } else {
      yield put({ type: Slice.getProjectListFail.type });
    }
  } catch (error) {
    yield put({ type: Slice.getProjectListFail.type });
  }
}

function* getProjectFlow({ payload }) {
  try {
    const response = yield call(APIs.getProject, payload);
    if (response.status === StatusCode.SUCCESS_COMMON) {
      yield put({
        type: Slice.getProjectSuccess.type,
        payload: response.data,
      });
    } else {
      yield put({ type: Slice.getProjectFail.type });
    }
  } catch (error) {
    yield put({ type: Slice.getProjectFail.type });
  }
}

function* getProjectSummaryFlow({ payload }) {
  try {
    const response = yield call(APIs.getProjectSummary, payload);
    if (response.status === StatusCode.SUCCESS_COMMON) {
      yield put({
        type: Slice.getProjectSummarySuccess.type,
        payload: response.data,
      });
    } else {
      yield put({ type: Slice.getProjectSummaryFail.type });
    }
  } catch (error) {
    yield put({ type: Slice.getProjectSummaryFail.type });
  }
}

function* createProjectFlow({ payload }) {
  try {
    const response = yield call(APIs.createProject, payload);
    if (response.status === StatusCode.SUCCESS_CREATED) {
      payload.id = response.data.id;
      yield uploadProjectImageFlow({ payload });

      yield put({
        type: Slice.createProjectSuccess.type,
        payload: response.data,
      });
    } else {
      yield put({ type: Slice.createProjectFail.type });
    }
  } catch (error) {
    yield put({ type: Slice.createProjectFail.type });
  }
}

function* updateProjectFlow({ payload }) {
  try {
    const response = yield call(APIs.updateProject, payload);
    if (response.status === StatusCode.SUCCESS_COMMON) {
      yield put({
        type: Slice.updateProjectSuccess.type,
        payload: response.data,
      });
    } else {
      yield put({ type: Slice.updateProjectFail.type });
    }
  } catch (error) {
    yield put({ type: Slice.updateProjectFail.type });
  }
}

function* deleteProjectFlow({ payload }) {
  try {
    const response = yield call(APIs.deleteProject, payload);
    if (response.status === StatusCode.NO_CONTENT) {
      yield put({
        type: Slice.deleteProjectSuccess.type,
      });
    } else {
      yield put({ type: Slice.deleteProjectFail.type });
    }
  } catch (error) {
    yield put({ type: Slice.deleteProjectFail.type });
  }
}

function* uploadProjectImageFlow({ payload }) {
  try {
    const formData = new FormData();

    if ('image_url' in payload) {
      const d = _.get(payload, 'image_url[0]');
      const fn = _.get(payload, 'image_url[0].name');
      if (d && fn) {
        formData.append('image_url', d, fn);
        yield call(APIs.uploadProjectImage, payload.id, formData);
      }
    }
  } catch (error) {
    console.error(error);
  }
}

function* projectsWatcher() {
  yield takeLatest(Slice.getProjectListRequest, getProjectListFlow);
  yield takeLatest(Slice.getProjectRequest, getProjectFlow);
  yield takeLatest(Slice.createProjectRequest, createProjectFlow);
  yield takeLatest(Slice.updateProjectRequest, updateProjectFlow);
  yield takeLatest(Slice.updateProjectImageRequest, uploadProjectImageFlow);
  yield takeLatest(Slice.deleteProjectRequest, deleteProjectFlow);
  yield takeLatest(Slice.getProjectSummaryRequest, getProjectSummaryFlow);
}

export default projectsWatcher;

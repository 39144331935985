import { StatusCode } from 'constants/API';
import { call, put, takeLatest } from 'redux-saga/effects';
import APIs from 'APIs';
import { getProfileRequest, getProfileSuccess, getProfileFail } from '.';

const { getMemberInfo } = APIs;
function* getProfileFlow({ payload }) {
  try {
    const response = yield call(getMemberInfo, payload);
    if (response.status === StatusCode.SUCCESS_COMMON) {
      const { type } = getProfileSuccess;
      yield put({
        type,
        payload: response.data,
      });
    } else {
      yield put({ type: getProfileFail.type });
    }
  } catch (error) {
    yield put({ type: getProfileFail.type });
  }
}

function* profileWatcher() {
  yield takeLatest(getProfileRequest, getProfileFlow);
}

export default profileWatcher;

import React, { useState, useEffect } from 'react';

// UI
import {
  DialogTitle,
  DialogContent,
  Grid,
  FormLabel,
  TextField,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  RadioGroup,
  FormControlLabel,
  Radio,
  DialogActions,
  Button,
  Typography,
  Dialog,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';

import { LoadingIndicator } from 'app/components/LoadingIndicator';

// Redux & Sagas
import { useDispatch, useSelector } from 'react-redux';
import { useTeamSlice } from '../../slice';
import { selectTeam } from '../../slice/selectors';
import { RequestStatus } from 'constants/API';
import { ITeamSchema } from '../../slice/types';

//Library
import _ from 'lodash';
import { useForm, Controller, FormProvider } from 'react-hook-form';
import styled from 'styled-components/macro';
import { COUNTRY_LIST } from 'constants/common';
import { useMemberSlice } from 'app/pages/MemberPage/slice';
import MemberRow from '../MemberRow';
import { useGlobalSlice } from 'app/pages/GlobalContainer/slice';

interface IDialogForm {
  open: boolean;
  data: ITeamSchema | null | undefined;
  onClose: Function;
}

export function DialogForm(props: IDialogForm) {
  const dispatch = useDispatch();
  const { actions } = useTeamSlice();
  const { setErrorMessages, setSuccessMessages } = useGlobalSlice().actions;
  const teamSlice = useSelector(selectTeam);
  const [isEditMode, setIsEditMode] = useState(false);
  const {
    actions: { getMemberListRequest },
  } = useMemberSlice();
  const { open, data, onClose } = props;

  const memberForm = useForm();
  const {
    reset,
    register,
    handleSubmit,
    getValues,
    setValue,
    watch,
    control,
    setError,
    formState: { errors },
  } = memberForm;
  useEffect(() => {
    setIsEditMode(data ? true : false);
  }, [data]);

  React.useEffect(() => {
    dispatch(getMemberListRequest({ paging: false }));
    setValue('members', []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    reset({
      code: data?.code,
      name: data?.name,
      country: data?.country || 'vi',
      status: data?.status.toString() || '1',
      members: data?.members,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (teamSlice.updateTeamInfoStatus === RequestStatus.SUCCESS) {
      dispatch(setSuccessMessages(['Team update successful!']));
      onClose();
      reset();
    }
    if (teamSlice.createTeamInfoStatus === RequestStatus.SUCCESS) {
      dispatch(actions.resetTeamInfoRequestStatus());
      dispatch(setSuccessMessages(['Team create successful!']));
      onClose();
      reset();
    }
    if (
      teamSlice.createTeamInfoStatus === RequestStatus.ERROR ||
      teamSlice.updateTeamInfoStatus === RequestStatus.ERROR
    ) {
      dispatch(actions.resetTeamInfoRequestStatus());
      dispatch(setErrorMessages(['Oops.. Something went wrong!']));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamSlice.createTeamInfoStatus, teamSlice.updateTeamInfoStatus, onClose]);

  const onSave = formData => {
    const members = formData?.members || [];
    formData.members = members
      .map(it => {
        const d = {
          member: Number(it.id),
          role: Number(it.role_id),
        };
        return d;
      })
      .filter(o => o.member !== '' && o.role !== '');
    if (data && data.id > 0) {
      dispatch(
        actions.updateTeamInfoRequest({
          objectId: data.id,
          formData,
        }),
      );
    } else {
      dispatch(actions.createTeamInfoRequest(formData));
    }
  };
  useEffect(() => {
    if (
      teamSlice.createTeamInfoStatus === RequestStatus.SUCCESS ||
      teamSlice.updateTeamInfoStatus === RequestStatus.SUCCESS
    ) {
      reset();
      onClose();
    }
    if (
      teamSlice.createTeamInfoStatus === RequestStatus.ERROR ||
      teamSlice.updateTeamInfoStatus === RequestStatus.ERROR
    ) {
      if (teamSlice.listError) {
        for (var field_name in teamSlice.listError) {
          var message = teamSlice.listError[field_name];
          setError(field_name, {
            type: 'manual',
            message: message,
          });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamSlice.createTeamInfoStatus, teamSlice.updateTeamInfoStatus, onClose]);

  return (
    <CustomerForm open={open} maxWidth="md" fullWidth scroll="paper">
      <DialogTitle>
        {isEditMode ? 'UPDATE TEAM INFO' : 'ADD NEW TEAM'}
      </DialogTitle>
      <DialogContent dividers>
        <Grid
          container
          spacing={2}
          pt={1}
          component="form"
          id="customer-form"
          onSubmit={handleSubmit(onSave)}
        >
          <Grid item xs={6}>
            <FormLabel>Code</FormLabel>
            <TextField
              placeholder="Team code"
              variant="outlined"
              fullWidth
              margin="dense"
              size="small"
              error={errors.code ? true : false}
              {...register<keyof ITeamSchema>('code', {
                required: 'This is required.',
                maxLength: 255,
              })}
              helperText={errors.code?.message}
            />
          </Grid>
          <Grid item xs={6}>
            <InputLabel>Country</InputLabel>
            <FormControl fullWidth margin="dense">
              <Controller
                control={control}
                defaultValue={getValues(['country'])}
                name="country"
                render={({ field }) => {
                  const { onBlur, onChange, value } = field;
                  return (
                    <Select
                      onBlur={onBlur}
                      onChange={onChange}
                      value={value}
                      size="small"
                    >
                      {_.map(COUNTRY_LIST, (label, code) => (
                        <MenuItem key={`menu_item_${code}`} value={code}>
                          {label}
                        </MenuItem>
                      ))}
                    </Select>
                  );
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormLabel>Team Name</FormLabel>
            <TextField
              placeholder="Team Name"
              variant="outlined"
              fullWidth
              margin="dense"
              size="small"
              error={errors.name ? true : false}
              {...register<keyof ITeamSchema>('name', {
                required: 'This is required.',
                maxLength: 255,
              })}
              helperText={errors.name?.message}
            />
          </Grid>
          <Grid item xs={12}>
            <FormProvider {...memberForm}>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell>No</TableCell>
                    <TableCell width={480}>Name</TableCell>
                    <TableCell width={200}>Team Role</TableCell>
                    <TableCell width={120}>Action</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {!_.size(watch('members')) && (
                    <TableRow>
                      <TableCell colSpan={3}>No member</TableCell>
                    </TableRow>
                  )}
                  {_.times(_.size(watch('members')), memberIndex => {
                    return (
                      <MemberRow
                        key={`assignment_row_${memberIndex}`}
                        memberIndex={memberIndex}
                        keyName="members"
                      />
                    );
                  })}
                </TableBody>
              </Table>
            </FormProvider>
            <Button
              variant="outlined"
              onClick={() => {
                setValue(
                  'members',
                  _.concat(
                    _.remove(getValues('members'), function (member) {
                      return member !== {};
                    }),
                    {},
                  ),
                );
              }}
            >
              Add new Member
            </Button>
            {/* <SearchMember listMember={listMember?.data}></SearchMember> */}
          </Grid>
          <Grid item xs={12}>
            <FormLabel>Status</FormLabel>
            <FormControl fullWidth margin="dense">
              <Controller
                control={control}
                defaultValue={getValues(['status']).toString()}
                name="status"
                render={({ field }) => {
                  const { onBlur, onChange, value } = field;
                  return (
                    <RadioGroup
                      row
                      value={value}
                      onBlur={onBlur}
                      onChange={onChange}
                    >
                      <FormControlLabel
                        value={'1'}
                        control={<Radio />}
                        label="Active"
                      />
                      <FormControlLabel
                        value={'2'}
                        control={<Radio />}
                        label="Deactive"
                      />
                    </RadioGroup>
                  );
                }}
              />
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose()}>Cancel</Button>
        <Button
          color="primary"
          variant="contained"
          type="submit"
          form="customer-form"
          disabled={
            teamSlice.createTeamInfoStatus === RequestStatus.REQUESTING ||
            teamSlice.updateTeamInfoStatus === RequestStatus.REQUESTING
          }
        >
          {(teamSlice.createTeamInfoStatus === RequestStatus.REQUESTING ||
            teamSlice.updateTeamInfoStatus === RequestStatus.REQUESTING) && (
            <Typography component="span" mr={1}>
              <LoadingIndicator small />
            </Typography>
          )}
          {isEditMode ? 'Update' : 'Create'}
        </Button>
      </DialogActions>
    </CustomerForm>
  );
}

const CustomerForm = styled(Dialog)`
  .MuiDialogContent-root {
    padding: 0px 24px !important;
  }
`;

const roles = {
  // ALL
  CUSTOMER_ALL: 'palette.[a-z]+_customer',
  PROJECT_ALL: 'palette.[a-z]+_project',
  MEMBER_ALL: 'palette.[a-z]+_member',
  TEAM_ALL: 'palette.[a-z]+_team',
  WORKREPORT_ALL: 'palette.[a-z]+_workreport',
  INVITE_MEMBER_ALL: 'palette.[a-z]+_invitedmember',
  TEAM_PROPOSAL_ALL: 'palette.[a-z]+_proposal',

  // Role
  IS_ADMIN: 'palette.is_admin',
  IS_CUSTOMER: 'palette.is_customer',
  IS_LEADER: 'palette.is_leader',
  IS_NORMAL_USER: 'palette.is_normal_user',

  //
  MEMBER_ADD: 'palette.add_member',
  MEMBER_CHANGE: 'palette.change_member',
  MEMBER_VIEW: 'palette.view_member',
  MEMBER_DELETE: 'palette.delete_member',

  PROJECT_ADD: 'palette.add_project',
  PROJECT_CHANGE: 'palette.change_project',
  PROJECT_DELETE: 'palette.delete_project',
  PROJECT_VIEW: 'palette.view_project',

  CUSTOMER_ADD: 'palette.add_customer',
  CUSTOMER_DELETE: 'palette.delete_customer',
  CUSTOMER_VIEW: 'palette.view_customer',
  CUSTOMER_CHANGE: 'palette.change_customer',

  WORKREPORT_ADD: 'palette.add_workreport',
  WORKREPORT_CHANGE: 'palette.change_workreport',
  WORKREPORT_VIEW: 'palette.view_workreport',
  WORKREPORT_DELETE: 'palette.delete_workreport',
  WORKREPORT_APPROVE: 'palette.approve_workreport',

  TEAM_ADD: 'palette.add_team',
  TEAM_CHANGE: 'palette.change_team',
  TEAM_VIEW: 'palette.view_team',
  TEAM_DELETE: 'palette.delete_team',

  INVITE_MEMBER_ADD: 'palette.add_invitedmember',
  INVITE_MEMBER_CHANGE: 'palette.change_invitedmember',
  INVITE_MEMBER_VIEW: 'palette.view_invitedmember',
  INVITE_MEMBER_DELETE: 'palette.delete_invitedmember',

  CV_SHARE: 'palette.view_cv_share',
  TEAM_PROPOSAL_ADD: 'palette.add_proposal',
  TEAM_PROPOSAL_UPDATE: 'palette.change_proposal',
  TEAM_PROPOSAL_DELETE: 'palette.delete_proposal',
  TEAM_PROPOSAL_VIEW: 'palette.view_proposal',
};

export default roles;

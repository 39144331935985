import { StatusCode } from 'constants/API';
import { call, put, takeLatest } from 'redux-saga/effects';
import APIs from 'APIs';
import Slice from '.';

function* getCustomerListFlow({ payload }) {
  try {
    const response = yield call(APIs.getLandingPageCustomers, payload);
    if (response.status === StatusCode.SUCCESS_COMMON) {
      yield put({
        type: Slice.getCustomerListSuccess.type,
        payload: response.data.data,
      });
    } else {
      yield put({ type: Slice.getCustomerListFail.type });
    }
  } catch (error) {
    yield put({ type: Slice.getCustomerListFail.type });
  }
}

function* getProjectListFlow({ payload }) {
  try {
    const response = yield call(APIs.getLandingPageProjects, payload);
    if (response.status === StatusCode.SUCCESS_COMMON) {
      if (!payload.paging) {
        yield put({
          type: Slice.getProjectListMetaDataSuccess.type,
          payload: response.data.data,
        });
      } else {
        yield put({
          type: Slice.getProjectListSuccess.type,
          payload: response.data.data,
        });
      }
    } else {
      yield put({ type: Slice.getProjectListFail.type });
    }
  } catch (error) {
    yield put({ type: Slice.getProjectListFail.type });
  }
}

function* getMemberListFlow({ payload }) {
  try {
    const response = yield call(APIs.getLandingPageMembers, payload);
    if (response.status === StatusCode.SUCCESS_COMMON) {
      yield put({
        type: Slice.getMemberListSuccess.type,
        payload: response.data.data,
      });
    } else {
      yield put({ type: Slice.getMemberListFail.type });
    }
  } catch (error) {
    yield put({ type: Slice.getMemberListFail.type });
  }
}

function* getTechnologiesListFlow({ payload }) {
  try {
    const response = yield call(APIs.getLandingPageTechnologies, payload);
    if (response.status === StatusCode.SUCCESS_COMMON) {
      yield put({
        type: Slice.getTechnologyListSuccess.type,
        payload: response.data.data,
      });
    } else {
      yield put({ type: Slice.getTechnologyListFail.type });
    }
  } catch (error) {
    yield put({ type: Slice.getTechnologyListFail.type });
  }
}

function* landingWatcher() {
  yield takeLatest(Slice.getCustomerListRequest, getCustomerListFlow);
  yield takeLatest(Slice.getProjectListRequest, getProjectListFlow);
  yield takeLatest(Slice.getMemberListRequest, getMemberListFlow);
  yield takeLatest(Slice.getTechnologyListRequest, getTechnologiesListFlow);
}

export default landingWatcher;

import React from 'react';

// UI
import {
  Grid,
  Stack,
  Typography,
  FormLabel,
  TextField,
  FormControl,
  FormHelperText,
  Box,
  Button,
  MenuItem,
  Select,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

import DatePicker from '@mui/lab/DatePicker';
import InputImage from 'app/components/InputImage';
import SaveIcon from '@mui/icons-material/Save';

import { ReactComponent as CheckIcon } from 'assets/icons/check.svg';

// Redux & Sagas
import { RequestStatus } from 'constants/API';
import { useDispatch, useSelector } from 'react-redux';
import {
  Controller,
  UseFormReturn,
  SubmitHandler,
  FieldValues,
} from 'react-hook-form';
import { IMemberSchema } from 'app/pages/MemberPage/slice/types';
import { useMemberSlice } from 'app/pages/MemberPage/slice';
import { selectMember } from 'app/pages/MemberPage/slice/selectors';
import { MEMBER_STATUSES } from 'constants/common';
import { useForgotPasswordSlice } from 'app/pages/ForgotPasswordPage/slice';
import { selectForgotPassword } from 'app/pages/ForgotPasswordPage/slice/selectors';
import { useGlobalSlice } from 'app/pages/GlobalContainer/slice';

interface IBasicInfoProps {
  memberForm: UseFormReturn;
  handleSaveData: SubmitHandler<FieldValues>;
  callAPIUploadImage: any;
}

export default function BasicInfo(props: IBasicInfoProps) {
  const dispatch = useDispatch();

  const {
    actions: { getProjectRoleListRequest },
  } = useMemberSlice();

  const { project_role_metadata, updateMemberInfoStatus } =
    useSelector(selectMember);
  const { setSuccessMessages, setErrorMessages } = useGlobalSlice().actions;

  const { forgotPasswordRequest, resetForgotPasswordStatus } =
    useForgotPasswordSlice().actions;
  const { forgotPasswordStatus } = useSelector(selectForgotPassword);

  const {
    reset,
    register,
    getValues,
    control,
    handleSubmit,
    formState: { errors },
  } = props.memberForm;

  const sendLinkResetPassword = () => {
    dispatch(forgotPasswordRequest({ email: getValues(['email'].toString()) }));
  };

  React.useEffect(() => {
    if (forgotPasswordStatus === RequestStatus.SUCCESS) {
      dispatch(setSuccessMessages(['Send email reset password success']));
    }
    if (forgotPasswordStatus === RequestStatus.ERROR) {
      dispatch(setErrorMessages(['Send email reset password have errors']));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forgotPasswordStatus]);

  React.useEffect(
    () => () => {
      dispatch(resetForgotPasswordStatus());
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    ['componentWillUnMount'],
  );

  React.useEffect(() => {
    dispatch(getProjectRoleListRequest({}));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Grid container spacing={6}>
      <Grid
        item
        xs={12}
        lg={4}
        display="flex"
        alignItems="center"
        // direction="column"
      >
        <InputImage
          form={props.memberForm}
          inputName="avatar"
          size={300}
          callAPIUploadImage={props.callAPIUploadImage}
        />
      </Grid>
      <Grid item xs={12} lg={8}>
        <Stack>
          <Typography variant="h5" component="h5">
            Persona
          </Typography>

          <Grid container spacing={2} pb={4} pt={2}>
            <Grid item xs={12}>
              <FormLabel>Full Name</FormLabel>
              <TextField
                variant="outlined"
                fullWidth
                margin="dense"
                size="small"
                error={errors.full_name ? true : false}
                {...register<keyof IMemberSchema>('full_name', {
                  required: 'This is required.',
                  maxLength: 60,
                })}
                helperText={errors.full_name?.message}
              />
            </Grid>

            <Grid item xs={10}>
              <FormLabel>Status</FormLabel>
              <FormControl fullWidth margin="dense">
                <Controller
                  control={control}
                  name="status"
                  render={({ field }) => {
                    const { onBlur, onChange, value } = field;
                    return (
                      <RadioGroup
                        row
                        value={value ? value.toString() : null}
                        onBlur={onBlur}
                        onChange={onChange}
                      >
                        {MEMBER_STATUSES.map(v => (
                          <FormControlLabel
                            key={v.id}
                            value={v.value}
                            control={<Radio />}
                            label={v.label}
                          />
                        ))}
                      </RadioGroup>
                    );
                  }}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <FormLabel>Role</FormLabel>
              <FormControl fullWidth margin="dense">
                <Controller
                  control={control}
                  name="role"
                  rules={{ required: 'This is required.' }}
                  render={({ field }) => {
                    const { onBlur, onChange, value } = field;
                    return (
                      <Select
                        fullWidth
                        displayEmpty
                        size="small"
                        onBlur={onBlur}
                        onChange={onChange}
                        value={`${value}`}
                      >
                        {project_role_metadata?.map(it => (
                          <MenuItem key={it.name} value={`${it.id}`}>
                            {it.name}
                          </MenuItem>
                        ))}
                      </Select>
                    );
                  }}
                />
                <FormHelperText error>{errors.role?.message}</FormHelperText>
              </FormControl>
            </Grid>

            <Grid item xs={10}>
              <FormLabel>Email Address</FormLabel>
              <TextField
                value={getValues('email')}
                fullWidth
                size="small"
                margin="dense"
                disabled
              />
            </Grid>

            <Grid item xs={2} alignItems="flex-end" display="flex">
              <Box mb={1}>
                <CheckIcon style={{ marginRight: '5px' }} /> Verified
              </Box>
            </Grid>

            <Grid item xs={6}>
              <FormLabel>DOB</FormLabel>
              <FormControl fullWidth margin="dense">
                <Controller
                  control={control}
                  name="birthday"
                  defaultValue=""
                  render={({ field: { onChange, value } }) => (
                    <DatePicker
                      disableFuture
                      value={value}
                      onChange={onChange}
                      inputFormat="DD-MM-YYYY"
                      renderInput={params => (
                        <TextField
                          {...params}
                          fullWidth
                          hiddenLabel
                          size="small"
                        />
                      )}
                    />
                  )}
                />
              </FormControl>
            </Grid>

            <Grid item xs={6}>
              <FormLabel>Phone Number</FormLabel>
              <TextField
                variant="outlined"
                fullWidth
                margin="dense"
                size="small"
                error={errors.phone ? true : false}
                {...register<keyof IMemberSchema>('phone', {
                  // required: 'This is required.',
                  maxLength: 12,
                })}
                helperText={errors.phone?.message}
              />
            </Grid>

            <Grid item xs={12}>
              <FormLabel>Bank Info</FormLabel>
              <TextField
                variant="outlined"
                fullWidth
                margin="dense"
                size="small"
                multiline
                rows={1}
                error={errors.bankinfo ? true : false}
                {...register<keyof IMemberSchema>('bankinfo', {
                  maxLength: 255,
                })}
                helperText={errors.bankinfo?.message}
              />
            </Grid>

            <Grid item xs={12}>
              <FormLabel>Note</FormLabel>
              <TextField
                variant="outlined"
                fullWidth
                margin="dense"
                size="small"
                multiline
                rows={2}
                error={errors.bio ? true : false}
                {...register<keyof IMemberSchema>('bio', {})}
                helperText={errors.bio?.message}
              />
            </Grid>
          </Grid>

          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            pt={2}
          >
            <Typography variant="h5" component="span">
              Authentication
            </Typography>
          </Box>

          <Grid container spacing={2} pt={2}>
            <Grid item xs={6}>
              <FormLabel>Username</FormLabel>
              <Box mt={0.5}>
                <Typography variant="h6" component="div">
                  {getValues('full_name')}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <FormLabel>Last login</FormLabel>
              <Box mt={0.5}>
                <Typography variant="h6" component="div">
                  2021-09-20 18:31:20
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} textAlign="right" mt={4}>
              <Stack direction="row" spacing={2} justifyContent="flex-end">
                <Button
                  variant="contained"
                  style={{
                    backgroundColor: '#F5F5F5',
                    color: 'rgb(0 0 0 / 30%)',
                  }}
                  onClick={() => reset()}
                >
                  Reset
                </Button>
                <Button
                  variant="contained"
                  style={{ backgroundColor: '#F9973B' }}
                  onClick={sendLinkResetPassword}
                >
                  Send Reset Password
                </Button>
                <LoadingButton
                  variant="contained"
                  color="primary"
                  loadingPosition="start"
                  startIcon={<SaveIcon />}
                  loading={updateMemberInfoStatus === RequestStatus.REQUESTING}
                  onClick={() => handleSubmit(props.handleSaveData)()}
                >
                  Save change
                </LoadingButton>
              </Stack>
            </Grid>
          </Grid>
        </Stack>
      </Grid>
    </Grid>
  );
}

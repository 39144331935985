import React from 'react';

// UI
import {
  Stack,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TextField,
  FormControl,
  Select,
  MenuItem,
} from '@mui/material';

import DatePicker from '@mui/lab/DatePicker/DatePicker';

// Redux & Sagas
import { IMemberSchema } from 'app/pages/MemberPage/slice/types';

// Library
import { Controller, UseFormReturn } from 'react-hook-form';
import { COUNTRY_LIST } from 'constants/common';
import _ from 'lodash';

interface ISkillTableProps {
  memberForm: UseFormReturn;
}

export default function BasicCVProfile(props: ISkillTableProps) {
  const {
    register,
    control,
    formState: { errors },
  } = props.memberForm;

  return (
    <Stack spacing={2}>
      <TableContainer component={Paper} variant="outlined" square>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell width={120}></TableCell>
              <TableCell>名 / First Name </TableCell>
              <TableCell>性 / Last Name </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell component="th">氏名</TableCell>
              <TableCell>
                <TextField
                  size="small"
                  variant="outlined"
                  hiddenLabel
                  fullWidth
                  error={errors.first_name_kanji ? true : false}
                  {...register<keyof IMemberSchema>('first_name_kanji', {
                    maxLength: 20,
                  })}
                  helperText={errors.first_name_kanji?.message}
                />
              </TableCell>
              <TableCell>
                <TextField
                  size="small"
                  variant="outlined"
                  hiddenLabel
                  fullWidth
                  error={errors.last_name_kanji ? true : false}
                  {...register<keyof IMemberSchema>('last_name_kanji', {
                    maxLength: 20,
                  })}
                  helperText={errors.last_name_kanji?.message}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th">name</TableCell>
              <TableCell>
                <TextField
                  size="small"
                  variant="outlined"
                  hiddenLabel
                  fullWidth
                  error={errors.first_name ? true : false}
                  {...register<keyof IMemberSchema>('first_name', {
                    required: 'This is required.',
                    maxLength: 20,
                  })}
                  helperText={errors.first_name?.message}
                />
              </TableCell>
              <TableCell>
                <TextField
                  size="small"
                  variant="outlined"
                  hiddenLabel
                  fullWidth
                  error={errors.last_name ? true : false}
                  {...register<keyof IMemberSchema>('last_name', {
                    required: 'This is required.',
                    maxLength: 20,
                  })}
                  helperText={errors.last_name?.message}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th">カナ</TableCell>
              <TableCell>
                <TextField
                  size="small"
                  variant="outlined"
                  hiddenLabel
                  fullWidth
                  error={errors.first_name_kana ? true : false}
                  {...register<keyof IMemberSchema>('first_name_kana', {
                    maxLength: 20,
                  })}
                  helperText={errors.first_name_kana?.message}
                />
              </TableCell>
              <TableCell>
                <TextField
                  size="small"
                  variant="outlined"
                  hiddenLabel
                  fullWidth
                  error={errors.last_name_kana ? true : false}
                  {...register<keyof IMemberSchema>('last_name_kana', {
                    maxLength: 20,
                  })}
                  helperText={errors.last_name_kana?.message}
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      <TableContainer component={Paper} variant="outlined" square>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell width={120}>性別</TableCell>
              <TableCell width={180}>生年月日</TableCell>
              <TableCell width={160}>現在国</TableCell>
              <TableCell>最終学歴</TableCell>
              <TableCell>専攻</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>
                <FormControl fullWidth hiddenLabel size="small">
                  <Controller
                    control={control}
                    name="gender"
                    render={({ field }) => {
                      const { onBlur, onChange, value } = field;
                      return (
                        <Select
                          onBlur={onBlur}
                          onChange={onChange}
                          value={value}
                        >
                          <MenuItem value={'1'}>女</MenuItem>
                          <MenuItem value={'2'}>男</MenuItem>
                        </Select>
                      );
                    }}
                  />
                </FormControl>
              </TableCell>
              <TableCell>
                <FormControl>
                  <Controller
                    control={control}
                    name="birthday"
                    defaultValue=""
                    render={({ field: { onChange, value } }) => (
                      <DatePicker
                        disableFuture
                        value={value}
                        onChange={onChange}
                        inputFormat="DD-MM-YYYY"
                        renderInput={params => (
                          <TextField
                            {...params}
                            fullWidth
                            hiddenLabel
                            size="small"
                          />
                        )}
                      />
                    )}
                  />
                </FormControl>
              </TableCell>
              <TableCell>
                <FormControl fullWidth hiddenLabel size="small">
                  <Controller
                    control={control}
                    name="country"
                    render={({ field }) => {
                      const { onBlur, onChange, value } = field;
                      return (
                        <Select
                          onBlur={onBlur}
                          onChange={onChange}
                          value={value}
                        >
                          {_.map(COUNTRY_LIST, (label, code) => (
                            <MenuItem key={code} value={code}>
                              {label}
                            </MenuItem>
                          ))}
                        </Select>
                      );
                    }}
                  />
                </FormControl>
              </TableCell>
              <TableCell>
                <TextField
                  size="small"
                  variant="outlined"
                  hiddenLabel
                  fullWidth
                  error={errors.education ? true : false}
                  {...register<keyof IMemberSchema>('education', {
                    maxLength: 255,
                  })}
                  helperText={errors.education?.message}
                />
              </TableCell>
              <TableCell>
                <TextField
                  size="small"
                  variant="outlined"
                  hiddenLabel
                  fullWidth
                  error={errors.major_field ? true : false}
                  {...register<keyof IMemberSchema>('major_field', {
                    maxLength: 125,
                  })}
                  helperText={errors.major_field?.message}
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Stack>
  );
}

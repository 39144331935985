import React from 'react';

// UI
import {
  Stack,
  Box,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Pagination,
} from '@mui/material';

// Redux & Sagas
import Path from 'config/clientPath';
import { useSelector } from 'react-redux';
import { selectMember } from 'app/pages/MemberPage/slice/selectors';
import { useHistory } from 'react-router-dom';
import queryString from 'query-string';
import { RequestStatus } from 'constants/API';
// Library
import _ from 'lodash';
import AssigmentRow from './AssignmentRow';
import { IMemberSchema } from 'app/pages/MemberPage/slice/types';
interface IAssigmentProps {
  showProject: boolean;
  showMember: boolean;
  showHeader: boolean;
  isRenderByKeyName: boolean;
  isCurrentNotHistory: boolean;
  memberInfo: IMemberSchema | null;
  // keyName: string;
}

export default function Assignment(props: IAssigmentProps) {
  const [page, setPage] = React.useState(1);
  const [memberAssignment, setMemberAssignment] = React.useState({});
  const history = useHistory();
  const {
    getMemberCurrentAssignmentStatus,
    getMemberHistoryAssignmentStatus,
    memberCurrentAssignment,
    memberHistoryAssignment,
  } = useSelector(selectMember);
  const keyNameRender = props.isCurrentNotHistory
    ? 'current_members'
    : 'history_members';

  const handleChangePage = (
    event: React.ChangeEvent<unknown> | null,
    newPage: number,
  ) => {
    const params = queryString.parse(window.location.search);
    const qs = queryString.stringify({ ...params, page: +newPage });
    history.push(`${Path.MEMBERS}/edit/${params.id}?${qs}`);
    setPage(newPage);
  };
  React.useEffect(() => {
    if (
      getMemberCurrentAssignmentStatus === RequestStatus.SUCCESS ||
      getMemberHistoryAssignmentStatus === RequestStatus.SUCCESS
    ) {
      let initObject = {
        current_members: memberCurrentAssignment?.data,
        history_members: memberHistoryAssignment?.data,
      };
      setMemberAssignment(initObject);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getMemberCurrentAssignmentStatus, getMemberHistoryAssignmentStatus]);

  return (
    <Stack spacing={2}>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
      ></Box>
      <Paper>
        <TableContainer>
          <Table>
            {props.showHeader && (
              <TableHead>
                <TableRow>
                  <TableCell sx={{ minWidth: 60 }}>No.</TableCell>
                  {props.showProject && (
                    <TableCell sx={{ minWidth: 120 }} width={150}>
                      Project
                    </TableCell>
                  )}
                  {props.showMember && (
                    <TableCell sx={{ minWidth: 120 }} width={100}>
                      Member
                    </TableCell>
                  )}
                  <TableCell sx={{ minWidth: 90 }} width={100}>
                    Type
                  </TableCell>
                  <TableCell sx={{ minWidth: 90 }} width={100}>
                    Role
                  </TableCell>
                  <TableCell sx={{ minWidth: 70 }} width={100}>
                    Effort/Month
                  </TableCell>
                  <TableCell sx={{ minWidth: 120 }} width={80}>
                    Start
                  </TableCell>
                  <TableCell sx={{ minWidth: 120 }} width={80}>
                    End
                  </TableCell>
                  <TableCell sx={{ minWidth: 120 }} width={80}>
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>
            )}

            <TableBody>
              {_.size(_.get(memberAssignment, keyNameRender)) === 0 && (
                <TableRow>
                  <TableCell colSpan={8}>No data</TableCell>
                </TableRow>
              )}
              {_.get(memberAssignment, keyNameRender) &&
                _.get(memberAssignment, keyNameRender).map(
                  (assignment, prjIndex) => {
                    return (
                      <AssigmentRow
                        key={`assignment_row_${prjIndex}`}
                        prjIndex={prjIndex}
                        assignment={assignment}
                      />
                    );
                  },
                )}
            </TableBody>
          </Table>
        </TableContainer>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          p={2}
        >
          <Button
            variant="outlined"
            onClick={() => {
              let cloneMemberAssignment = _.cloneDeep(memberAssignment);
              cloneMemberAssignment[keyNameRender] = _.concat(
                _.get(memberAssignment, keyNameRender),
                {},
              );
              setMemberAssignment(cloneMemberAssignment);
            }}
          >
            Add new assignment
          </Button>
        </Box>
      </Paper>
      <Stack spacing={2} alignItems={'flex-end'}>
        <Pagination
          page={page}
          color="primary"
          count={
            props.isCurrentNotHistory
              ? memberCurrentAssignment?.total_page
              : memberHistoryAssignment?.total_page || 0
          }
          variant="outlined"
          shape="rounded"
          onChange={handleChangePage}
        />
      </Stack>
    </Stack>
  );
}

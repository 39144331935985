import React from 'react';

// UI
import {
  Box,
  Button,
  Stack,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import styled from 'styled-components/macro';
import { ITag } from 'app/pages/TagPage/slice/types';

interface IMemberTasksSchema {
  id: number;
  member: number;
  duration: number;
  date: string;
  description: string;
  project: {
    name: string;
  };
  tags: ITag[];
  color?: string;
}

interface ITasksModal {
  open: boolean;
  currentTasks: IMemberTasksSchema[];
  onClose: Function;
}

export function TasksModal(props: ITasksModal) {
  const { open, currentTasks, onClose } = props;
  return (
    <Dialog open={open} maxWidth="xs" fullWidth>
      <DialogTitle>
        <Typography variant="h5" component="div">
          Task Logs
        </Typography>
      </DialogTitle>
      <DialogContent dividers>
        <Stack spacing={1}>
          {currentTasks.map((task, k) => {
            return (
              <Stack key={k} direction="row" spacing={1} alignItems="center">
                <Box display="flex" alignItems="center">
                  <Event
                    style={{ backgroundColor: task.color }}
                  >{`${task.description}`}</Event>
                  {`${task.duration}h`}
                </Box>
              </Stack>
            );
          })}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Stack direction="row" justifyContent="flex-end" flexGrow={1}>
          <Button
            onClick={() => {
              onClose();
            }}
          >
            Close
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
}

const Event = styled(Box)`
  border-radius: 4px;
  background: #8c8c8c;
  height: 26px;
  line-height: 26px;
  margin-right: 3px !important;
  color: #fff;
  font-size: 14px;
  padding: 0 8px;
`;

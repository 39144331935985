import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import memberSaga from './saga';
import { InviteMemberState } from './types';
import { RequestStatus } from 'constants/API';

export const initialState: InviteMemberState = {
  createMemberStatus: RequestStatus.IDLE,
  getInviteMetaDataStatus: RequestStatus.IDLE,
  error_message: '',
  inviteMedataData: null,
};

const slice = createSlice({
  name: 'invite_member',
  initialState,
  reducers: {
    createMemberRequest: (state, action: PayloadAction<any>) => {
      if (action) state.createMemberStatus = RequestStatus.REQUESTING;
    },
    createMemberSuccess: (state, action: PayloadAction<any>) => {
      if (action) state.createMemberStatus = RequestStatus.SUCCESS;
    },
    createMemberFail: (state, action: PayloadAction<any>) => {
      state.createMemberStatus = RequestStatus.ERROR;
      state.error_message = action.payload;
    },
    resetMemberRequestStatus: state => {
      state.createMemberStatus = RequestStatus.IDLE;
      state.error_message = '';
    },

    getInviteMetaDataRequest: (state, action: PayloadAction<any>) => {
      if (action) state.getInviteMetaDataStatus = RequestStatus.REQUESTING;
    },
    getInviteMetaDataSuccess: (state, action: PayloadAction<any>) => {
      if (action) {
        state.inviteMedataData = action.payload;
        state.getInviteMetaDataStatus = RequestStatus.SUCCESS;
      }
    },
    getInviteMetaDataFail: (state, action: PayloadAction<any>) => {
      state.getInviteMetaDataStatus = RequestStatus.ERROR;
      state.error_message = action.payload;
    },
  },
});

export default slice.actions;

export const useMemberSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: memberSaga });
  return { actions: slice.actions };
};

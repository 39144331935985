import _ from 'lodash';
import { retrieveUserCachedDataFromStorage } from 'app/pages/GlobalContainer/slice';

export const useRole = () => {
  const hasRole = (role: string, useRegex: boolean = false): boolean => {
    const _roles = retrieveUserCachedDataFromStorage().roles;
    if (role) {
      if (useRegex) {
        const re = new RegExp(role.replace('.', '\\.'), 'i');
        return _.find(_roles, el => re.test(el)) !== undefined;
      }
      return _.includes(_roles, role);
    }
    return true;
  };

  const hasOneRole = (roles: string[], useRegex: boolean = false): boolean => {
    return _.some(roles, el => hasRole(el, useRegex));
  };

  const hasAllRole = (roles: string[], useRegex: boolean = false): boolean => {
    return _.every(roles, el => hasRole(el, useRegex));
  };

  return {
    hasRole,
    hasOneRole,
    hasAllRole,
  };
};

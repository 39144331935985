import { Typography } from '@mui/material';
import Img from 'assets/img/landing_bg.png';
import * as React from 'react';
import styled from 'styled-components';
import Header from './Header';
import { HomeSlider } from './Slider';
import { Colors, device } from './Variables';

export default function MainSection() {
  return (
    <MainSectionWrapper>
      <Header />
      <SliderHeading variant="h2" className="heading-text">
        we build
        <Span className="orange-text">creative team!</Span>
      </SliderHeading>
      <HomeSlider />
    </MainSectionWrapper>
  );
}
const SliderHeading = styled(Typography)`
  font-size: 80px;
  padding: 40px;
  text-align: center;
  text-transform: capitalize;
  @media ${device.mobileL} {
    &.MuiTypography-h2 {
      font-size: 64px;
      word-break: break-word;
      padding: 20px;
      text-align: left;
    }
  }
`;
const MainSectionWrapper = styled.section`
  background: url(${Img}) center center;
  background-size: cover;
`;

const Span = styled.span`
  color: ${Colors.orangeHeading};
  padding-left: 10px;
  text-transform: capitalize;
  font-weight: 600;
  @media ${device.mobileL} {
    display: block;
  }
`;

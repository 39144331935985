import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import landingSaga from './saga';
import { LandingState } from './types';
import { RequestStatus } from 'constants/API';

export const initialState: LandingState = {
  getCustomerListStatus: RequestStatus.IDLE,
  getProjectListStatus: RequestStatus.IDLE,
  getMemberListStatus: RequestStatus.IDLE,
  getTechnologyListStatus: RequestStatus.IDLE,
  listCustomer: null,
  listProject: null,
  project_meta_data: null,
  listMember: null,
  listTechnology: null,
};

const slice = createSlice({
  name: 'landing',
  initialState,
  reducers: {
    getCustomerListRequest: (state, action: PayloadAction<any>) => {
      if (action) state.getCustomerListStatus = RequestStatus.REQUESTING;
    },
    getCustomerListSuccess: (state, action: PayloadAction<any>) => {
      if (action) {
        state.getCustomerListStatus = RequestStatus.SUCCESS;
        state.listCustomer = action.payload;
      }
    },
    getCustomerListFail: (state, action: PayloadAction<any>) => {
      state.getCustomerListStatus = RequestStatus.ERROR;
    },
    getProjectListRequest: (state, action: PayloadAction<any>) => {
      if (action) state.getProjectListStatus = RequestStatus.REQUESTING;
    },
    getProjectListSuccess: (state, action: PayloadAction<any>) => {
      if (action) {
        state.getProjectListStatus = RequestStatus.SUCCESS;
        state.listProject = action.payload;
      }
    },
    getProjectListMetaDataSuccess: (state, action: PayloadAction<any>) => {
      if (action) {
        state.getProjectListStatus = RequestStatus.SUCCESS;
        state.project_meta_data = action.payload;
      }
    },
    getProjectListFail: (state, action: PayloadAction<any>) => {
      state.getProjectListStatus = RequestStatus.ERROR;
    },
    getMemberListRequest: (state, action: PayloadAction<any>) => {
      if (action) state.getMemberListStatus = RequestStatus.REQUESTING;
    },
    getMemberListSuccess: (state, action: PayloadAction<any>) => {
      if (action) {
        state.getMemberListStatus = RequestStatus.SUCCESS;
        state.listMember = action.payload;
      }
    },
    getMemberListFail: (state, action: PayloadAction<any>) => {
      state.getMemberListStatus = RequestStatus.ERROR;
    },
    getTechnologyListRequest: (state, action: PayloadAction<any>) => {
      if (action) state.getTechnologyListStatus = RequestStatus.REQUESTING;
    },
    getTechnologyListSuccess: (state, action: PayloadAction<any>) => {
      if (action) {
        state.getTechnologyListStatus = RequestStatus.SUCCESS;
        state.listTechnology = action.payload;
      }
    },
    getTechnologyListFail: (state, action: PayloadAction<any>) => {
      state.getTechnologyListStatus = RequestStatus.ERROR;
    },
    resetRequestStatus: state => {
      state.getCustomerListStatus = RequestStatus.IDLE;
      state.getProjectListStatus = RequestStatus.IDLE;
      state.getMemberListStatus = RequestStatus.IDLE;
      state.getTechnologyListStatus = RequestStatus.IDLE;
    },
  },
});

export default slice.actions;

export const useLandingSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: landingSaga });
  return { actions: slice.actions };
};

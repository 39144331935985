import React, { useEffect, useState } from 'react';

// UI
import { Helmet } from 'react-helmet-async';

import {
  Dialog,
  DialogTitle,
  Typography,
  DialogContent,
  DialogActions,
  Stack,
  Button,
  Box,
  Card,
  CardContent,
  ListItem,
  Paper,
  DialogContentText,
  List,
  Divider,
} from '@mui/material';

import { Container } from 'app/components/Container';
import { Wrapper, Content } from 'app/components/Wrapper';

import AddIcon from '@mui/icons-material/Add';

import TaskLogDialog from './components/TaskLogDialog';
import FilterBar from './components/FilterBar';

// Redux & Sagas
import { useDispatch, useSelector } from 'react-redux';
import { RequestStatus } from 'constants/API';

import { selectTimesheet } from './slice/selectors';
import { useTimesheetSlice } from './slice';
import { ITimesheet } from './slice/types';

import { useGlobalSlice } from 'app/pages/GlobalContainer/slice';
import { selectGlobal } from 'app/pages/GlobalContainer/slice/selectors';

import { useTagSlice } from 'app/pages/TagPage/slice';

// Library
import _ from 'lodash';
import styled from 'styled-components/macro';
import moment from 'moment';

// Utils
import {
  getRecordStartTime,
  removeRecordStartTime,
  setRecordDuration,
} from 'utils/localStorage';
import CollapseTable from './components/CollapseTable';
import { HourTag } from 'app/components/HourTag';
import { CalendarViewMode } from './components/CalendarViewMode';
import { Loader } from 'app/components/Loader';
import { selectMember } from '../MemberPage/slice/selectors';
import { selectProject } from '../ProjectPage/slice/selectors';
import { selectTag } from '../TagPage/slice/selectors';
import { useLocation } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import queryString from 'query-string';
import Path from 'config/clientPath';

export function TimesheetPage() {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const timesheetSlice = useSelector(selectTimesheet);
  const {
    dateRangeWorkReport,
    getTimesheetStatus,
    getDateRangeWorkReportStatus,
    updateTimesheetStatus,
    deleteTimesheetStatus,
    addTimesheetStatus,
    cancelRequestStatus,
    errorMess,
  } = timesheetSlice;

  const {
    userSessionData: { profile },
  } = useSelector(selectGlobal);
  const { getMemberListStatus, getMemberWorkReportStatus } =
    useSelector(selectMember);
  const { getProjectStatus } = useSelector(selectProject);
  const { getTagStatus } = useSelector(selectTag);

  const { setSuccessMessages, setErrorMessages, setBreadcumbs } =
    useGlobalSlice().actions;
  const {
    actions: { getTagRequest },
  } = useTagSlice();

  const {
    actions: {
      getTimesheetRequest,
      deleteTimesheetRequest,
      getDateRangeWorkReportRequest,
      resetTimesheetRequestStatus,
    },
  } = useTimesheetSlice();

  useEffect(
    () => () => {
      dispatch(resetTimesheetRequestStatus());
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    ['componentWillUnMount'],
  );

  const [currentDate, setCurrentDate] = useState<moment.Moment>(moment());
  const weekStart = currentDate.clone().startOf('week');
  const weekPickerRef = React.useRef<any>();

  const [activeDate, setActiveDate] = useState(
    currentDate.format('YYYY/MM/DD'),
  );

  const [viewMode, setViewMode] = useState<string>('list');

  const [
    isShowConfirmCreateNewTaskDialog,
    setIsShowConfirmCreateNewTaskDialog,
  ] = useState<boolean>(false);
  const [isShowUpdateTaskLogDialog, setIsShowUpdateTaskLogDialog] =
    useState<boolean>(false);

  const [updateTaskLogObject, setUpdateTaskLogObject] =
    useState<ITimesheet | null>();

  const [isShowDeleteTaskDialog, setIsShowDeleteTaskDialog] =
    useState<boolean>(false);

  const checkIsRequesting = (statuses: string[]) => {
    let isRequesting = false;
    _.forEach(statuses, status => {
      if (status === RequestStatus.REQUESTING) {
        isRequesting = true;
        return;
      }
    });
    return isRequesting;
  };

  const isLoading = checkIsRequesting([
    getTimesheetStatus,
    getDateRangeWorkReportStatus,
    getMemberListStatus,
    getProjectStatus,
    getTagStatus,
    getMemberWorkReportStatus,
  ]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const reloadData = () => {
    let params = queryString.parse(window.location.search);
    const newDate = params?.date ? moment(params?.date) : moment();
    const newViewMode = params?.viewMode ? params?.viewMode : 'list';
    const newWeekStart = newDate.clone().startOf('week');
    if (newViewMode === 'list') {
      const tags = params?.tags || null;
      let timesheetParams = {
        report_date: newDate.format('yyyy-MM-DD'),
        tags,
      };
      timesheetParams['member'] = profile.id;
      timesheetParams['paging'] = false;
      dispatch(getTimesheetRequest(timesheetParams));
      dispatch(
        getDateRangeWorkReportRequest({
          date_start: newWeekStart.format('yyyy-MM-DD'),
          date_end: newDate
            .clone()
            .endOf('week')
            .add(1, 'days')
            .format('yyyy-MM-DD'),
          tags,
        }),
      );
    }
  };

  useEffect(() => {
    setActiveDate(currentDate.format('YYYY/MM/DD'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(currentDate)]);

  const changeDataByQuery = () => {
    let params = queryString.parse(window.location.search);
    const newDate = params?.date ? moment(params?.date) : moment();
    setCurrentDate(newDate);
    setActiveDate(newDate.format('YYYY/MM/DD'));
    setViewMode(params?.viewMode ? params?.viewMode : 'list');
  };

  useEffect(() => {
    changeDataByQuery();
    reloadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  const timesheetLogs: { [key: string]: ITimesheet[] } = _.reduce(
    timesheetSlice.timesheetList,
    (result, value) => {
      (
        result[_.get(value, 'project.id')] ||
        (result[_.get(value, 'project.id')] = [])
      ).push(value);
      return result;
    },
    {},
  );

  const handleChangePage = (
    _viewMode = '',
    _currentDate: moment.Moment | null = null,
  ) => {
    let params = queryString.parse(window.location.search);
    if (_viewMode) {
      params = { ...params, viewMode: _viewMode };
    }
    if (_currentDate) {
      setCurrentDate(_currentDate);
      params = { ...params, date: _currentDate.format('YYYY-MM-DD') };
    }
    const qs = queryString.stringify({ ...params });
    history.push(`${Path.TIMESHEET}?${qs}`);
  };

  useEffect(() => {
    changeDataByQuery();
    dispatch(setBreadcumbs([{ title: 'Timesheets' }]));
    dispatch(getTagRequest({}));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSuccess = (messages: string[]) => {
    dispatch(setSuccessMessages(messages));
    reloadData();
  };

  useEffect(() => {
    if (cancelRequestStatus === RequestStatus.SUCCESS) {
      onSuccess(['Cancel Request success']);
    }
    if (cancelRequestStatus === RequestStatus.ERROR) {
      dispatch(setErrorMessages(['Oops.. Something went wrong!']));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cancelRequestStatus]);

  useEffect(() => {
    if (addTimesheetStatus === RequestStatus.SUCCESS) {
      onSuccess(['Create task log success']);
      setIsShowUpdateTaskLogDialog(false);
    }
    if (addTimesheetStatus === RequestStatus.ERROR) {
      dispatch(setErrorMessages(errorMess));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addTimesheetStatus]);

  useEffect(() => {
    if (updateTimesheetStatus === RequestStatus.SUCCESS) {
      onSuccess(['Update task log success']);
      setIsShowUpdateTaskLogDialog(false);
    }
    if (updateTimesheetStatus === RequestStatus.ERROR) {
      dispatch(setErrorMessages(['Oops.. Something went wrong!']));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateTimesheetStatus]);

  useEffect(() => {
    if (deleteTimesheetStatus === RequestStatus.SUCCESS) {
      onSuccess(['Delete task log success']);
      setIsShowDeleteTaskDialog(false);
    }
    if (deleteTimesheetStatus === RequestStatus.ERROR) {
      dispatch(setErrorMessages(['Oops.. Something went wrong!']));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteTimesheetStatus]);

  const handleDeleteTask = () => {
    if (updateTaskLogObject) {
      dispatch(deleteTimesheetRequest(updateTaskLogObject?.id));
      setIsShowDeleteTaskDialog(false);
    }
  };

  const onChangeFilterDate = newDate => {
    handleChangePage('', newDate);
  };

  const onChangeToday = () => {
    handleChangePage('', moment());
  };

  const showDialogUpdateTask = task => {
    setUpdateTaskLogObject(task);
    setIsShowUpdateTaskLogDialog(true);
  };

  const showDialogDeleteTask = task => {
    setUpdateTaskLogObject(task);
    setIsShowDeleteTaskDialog(true);
  };

  const showModalAddTaskCalendar = date => {
    showDialogUpdateTask({ report_date: date });
  };

  return (
    <>
      <Helmet>
        <title>Timesheet | Palette</title>
        <meta name="description" content="Palette" />
      </Helmet>
      <Container>
        <Wrapper>
          <Content spacing={2}>
            <Loader open={isLoading} />
            <Dialog open={isShowConfirmCreateNewTaskDialog}>
              <DialogTitle>
                <Typography variant="h5" component="div">
                  Create new task?
                </Typography>
              </DialogTitle>
              <DialogContent>
                Do you want to use this duration to make new task?
              </DialogContent>
              <DialogActions>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  flexGrow={1}
                >
                  <Button
                    onClick={() => setIsShowConfirmCreateNewTaskDialog(false)}
                  >
                    Cancel
                  </Button>
                  <Box>
                    <Button
                      onClick={() => {
                        removeRecordStartTime();
                        window.dispatchEvent(new Event('storage'));
                        setIsShowConfirmCreateNewTaskDialog(false);
                      }}
                    >
                      No
                    </Button>
                    <Button
                      onClick={() => {
                        const duration = moment.duration(
                          moment().diff(getRecordStartTime()),
                        );
                        setRecordDuration(duration.asHours().toString());
                        removeRecordStartTime();
                        setIsShowConfirmCreateNewTaskDialog(false);

                        window.dispatchEvent(new Event('storage'));
                      }}
                    >
                      Yes
                    </Button>
                  </Box>
                </Stack>
              </DialogActions>
            </Dialog>

            <Box display="flex" flexDirection="row" alignItems="center">
              <Typography variant="h6" component="h6">
                Task logs
              </Typography>
              <Stack style={{ marginLeft: 'auto' }} direction="row" spacing={2}>
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  onClick={() => {
                    showDialogUpdateTask({});
                  }}
                >
                  <AddIcon />
                  <Box ml={0.5}>Add new task</Box>
                </Button>
              </Stack>
            </Box>

            <FilterBar
              viewMode={viewMode}
              setViewMode={setViewMode}
              currentDate={currentDate}
              onChangeFilterDate={onChangeFilterDate}
              onChangeToday={onChangeToday}
              weekPickerRef={weekPickerRef}
            />

            {isShowUpdateTaskLogDialog && (
              <TaskLogDialog
                currentDate={currentDate}
                open={isShowUpdateTaskLogDialog}
                data={updateTaskLogObject}
                onClose={() => setIsShowUpdateTaskLogDialog(false)}
              />
            )}

            <Dialog open={isShowDeleteTaskDialog} maxWidth="xs" fullWidth>
              <DialogTitle>
                <Typography variant="h5" component="div">
                  Delete Task log
                </Typography>
              </DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Do you want to delete this task log ?
                </DialogContentText>
                <Typography variant="subtitle1">
                  {updateTaskLogObject?.description}
                </Typography>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setIsShowDeleteTaskDialog(false)}>
                  Cancel
                </Button>
                <Button color="error" onClick={handleDeleteTask}>
                  delete
                </Button>
              </DialogActions>
            </Dialog>

            {viewMode === 'calendar' ? (
              <CalendarViewMode
                showDialogUpdateTask={showDialogUpdateTask}
                showModalAddTaskCalendar={showModalAddTaskCalendar}
                viewMode={viewMode}
                currentDate={currentDate.format('YYYY-MM-DD')}
              />
            ) : (
              <>
                <Paper>
                  <TimesheetPanel className="align-self-center">
                    <Box>
                      <TimeSeries>
                        {_.times(7).map((i, idx) => {
                          const d = moment(weekStart).add(i, 'days');
                          const d_text = d.format('YYYY/MM/DD');
                          const _class = [
                            'd-flex',
                            'flex-column',
                            'align-items-center',
                            'justify-content-center',
                          ];
                          if (activeDate === d_text) {
                            _class.push('active');
                          }
                          return (
                            <ListItem
                              key={`item_${idx}`}
                              className={_class.join(' ')}
                              onClick={() => {
                                handleChangePage('', d);
                              }}
                            >
                              <p>{d_text}</p>
                              <HourTag
                                data={{
                                  backgroundColor:
                                    activeDate === d_text ? '#fff' : '#BDD8FF',
                                  label: `${
                                    dateRangeWorkReport
                                      ? dateRangeWorkReport[i]?.duration || 0
                                      : 0
                                  }h`,
                                }}
                                size="small"
                              ></HourTag>
                            </ListItem>
                          );
                        })}
                      </TimeSeries>
                    </Box>
                  </TimesheetPanel>
                </Paper>
                <Stack direction="column" spacing={1}>
                  {_.isEmpty(timesheetLogs) ? (
                    <Card>
                      <CardContent>
                        <Typography>Nothing here!</Typography>
                      </CardContent>
                    </Card>
                  ) : (
                    <HeaderTaskLog>
                      <CardContent style={{ paddingRight: '0px' }}>
                        <StackTaskLog direction="row">
                          <WrapTaskLog pl={0}>
                            Task description <Divider orientation="vertical" />
                          </WrapTaskLog>
                          <WrapTaskLog>
                            Tag
                            <Divider orientation="vertical" />
                          </WrapTaskLog>
                          <WrapTaskLog>
                            Duration
                            <Divider orientation="vertical" />
                          </WrapTaskLog>
                          <WrapTaskLog>Action</WrapTaskLog>
                        </StackTaskLog>
                      </CardContent>
                    </HeaderTaskLog>
                  )}

                  {_.map(timesheetLogs, (project, key) => {
                    const totalHours = _.sumBy(project, o =>
                      _.toNumber(o.hours),
                    );
                    return (
                      <CollapseTable
                        showDialogUpdateTask={showDialogUpdateTask}
                        showDialogDeleteTask={showDialogDeleteTask}
                        totalHours={totalHours}
                        project={project}
                        key={`prj_${key}`}
                      ></CollapseTable>
                    );
                  })}
                </Stack>{' '}
              </>
            )}
          </Content>
        </Wrapper>
      </Container>
    </>
  );
}

const TimesheetPanel = styled.div``;

const TimeSeries = styled(List)`
  position: relative;
  text-align: center;
  padding: 0 !important;
  .MuiListItem-root {
    &:not(:last-child) {
      border-right: 1px solid #e2e3e4;
    }
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: calc(100% / 7);
    height: 68px;
    margin-left: -1px;
    p {
      margin: 0;
    }
    .MuiChip-root {
      margin-top: 10px;
      color: #181c32;
    }
    &:hover {
      cursor: pointer;
      background: rgba(0, 0, 0, 0.02);
    }
    &.active {
      -webkit-border-radius: 5px;
      -moz-border-radius: 5px;
      border-radius: 1px;
      box-shadow: 0px 0px 0px 10px #437dff;
      background: #437dff;
      font-size: 1.15rem;
      font-weight: 600;
      z-index: 96;
      border: 0;
      p {
        color: #fff;
      }
      .MuiChip-root {
        background-color: #fff;
        border-color: #fff;
      }
    }
  }
`;

const HeaderTaskLog = styled(Card)`
  background-color: #f0f7ff !important;
  & .MuiCardContent-root:last-child {
    padding-bottom: 16px;
  }
`;

const StackTaskLog = styled(Stack)`
  & div {
    font-size: 14px;
    font-weight: 500;
    &:first-child {
      flex: 1;
      padding-left: 23.5px;
      min-width: 130px;
    }
    &:nth-child(2) {
      width: 180px;
      min-width: 70px;
    }
    &:nth-child(3) {
      width: 180px;
      min-width: 70px;
    }
    &:nth-child(4) {
      width: 150px;
      min-width: 70px;
    }
  }
`;

const WrapTaskLog = styled(Box)`
  display: flex;
  justify-content: space-between;
  padding-left: 16px;
`;

import React from 'react';

// UI
import {
  Box,
  Stack,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  TextField,
  InputAdornment,
  IconButton,
} from '@mui/material';

import {
  Search as SearchIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,
} from '@mui/icons-material';

// Redux & Sagas
import { useSelector } from 'react-redux';

// Library
import { useForm, Controller } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import Path from 'config/clientPath';
import _ from 'lodash';
import { ASSIGN_GET_MONTH_COUNT } from 'constants/common';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import { selectTeam } from 'app/pages/TeamPage/slice/selectors';
import { selectGlobal } from 'app/pages/GlobalContainer/slice/selectors';
import roles from 'constants/roles';

export function FilterBar(props: {
  isCustomer: boolean;
  months: any[];
  changeMonth: (diff_month) => void;
}) {
  const { months, changeMonth, isCustomer } = props;
  const { handleSubmit, register, reset, control, watch } = useForm();

  const {
    userSessionData: { userRole },
  } = useSelector(selectGlobal);

  const { listTeam } = useSelector(selectTeam);

  const searchForm = React.useRef<HTMLFormElement>();
  const history = useHistory();

  const handSearch = formData => {
    let qs = Object.keys(formData)
      .map(key => (formData[key] ? key + '=' + formData[key] : ''))
      .filter(o => o)
      .join('&');
    history.push(
      `${isCustomer ? Path.ASSIGNABLE : Path.ASSIGNMENT_SUMMARY}?${qs}`,
    );
  };

  React.useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    reset({
      team: params.get('team') || '',
      search: params.get('search') || '',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (name === 'team' && userRole !== roles.IS_LEADER) {
        requestSubmit();
      }
    });
    return () => subscription.unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch]);

  const requestSubmit = () => {
    setTimeout(() => searchForm.current?.requestSubmit(), 10);
  };

  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      component="form"
      method="GET"
      ref={searchForm}
      onSubmit={handleSubmit(handSearch)}
    >
      <Stack direction="row" alignItems="center" spacing={1}>
        {userRole !== roles.IS_LEADER && (
          <>
            <InputLabel>Team: </InputLabel>

            <FormControl>
              <Controller
                control={control}
                name="team"
                render={({ field }) => {
                  const { onBlur, onChange, value } = field;
                  return (
                    <Select
                      fullWidth
                      displayEmpty
                      size="small"
                      variant="standard"
                      onBlur={onBlur}
                      onChange={onChange}
                      value={`${value}`}
                      IconComponent={KeyboardArrowDownIcon}
                    >
                      <MenuItem value="">All</MenuItem>
                      {listTeam?.data?.map(it => (
                        <MenuItem key={`m_f_${it.id}`} value={it.id}>
                          {it.name}
                        </MenuItem>
                      ))}
                    </Select>
                  );
                }}
              />
            </FormControl>
          </>
        )}

        <IconButton>
          <ChevronLeft
            onClick={() => {
              changeMonth(-ASSIGN_GET_MONTH_COUNT);
            }}
          />
        </IconButton>
        {months.length && (
          <Box>{`${_.head(months).slashMonthString} ~ ${
            _.last(months).slashMonthString
          }`}</Box>
        )}

        <IconButton>
          <ChevronRight
            onClick={() => {
              changeMonth(ASSIGN_GET_MONTH_COUNT);
            }}
          />
        </IconButton>
      </Stack>

      <Stack direction="row" spacing={2} alignItems="center">
        <TextField
          type="search"
          placeholder="Name"
          aria-label="Search"
          size="small"
          sx={{
            background: '#fff',
          }}
          onKeyUp={event => {
            if (event.key === 'Enter') {
              requestSubmit();
            }
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          {...register('search')}
        />
      </Stack>
    </Box>
  );
}

export default FilterBar;

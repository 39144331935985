import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
  Paper,
  Stack,
  Button,
  Card,
  CardContent,
  Pagination,
} from '@mui/material';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';
import { ProjectLink } from 'app/components/ProjectLink';
import moment from 'moment';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';

// Redux & Sagas
import { useDispatch, useSelector } from 'react-redux';
import { useGlobalSlice } from 'app/pages/GlobalContainer/slice';
import Path from 'config/clientPath';
import { APPROVED_TASK_STATUS, REQUESTING_TASK_STATUS } from 'constants/common';
import { RequestStatus } from 'constants/API';
import { useLocation } from 'react-router';
import queryString from 'query-string';
import { STATUS_DISPLAY_TYPE, TaskStatus } from 'app/components/TaskStatus';
import { BillableTag } from 'app/components/BillableTag';
import { selectTimesheet } from 'app/pages/TimesheetPage/slice/selectors';
import { useTimesheetSlice } from 'app/pages/TimesheetPage/slice';
import { selectMember } from 'app/pages/MemberPage/slice/selectors';
import { selectGlobal } from 'app/pages/GlobalContainer/slice/selectors';

interface IProps {
  month: moment.Moment | null;
}

export function RequestApproveTask(props: IProps) {
  const { month } = props;
  const dispatch = useDispatch();
  const [currentStatus, setCurrentStatus] = useState(REQUESTING_TASK_STATUS);
  const [page, setPage] = useState(1);

  const location = useLocation();
  const history = useHistory();

  const { setBreadcumbs } = useGlobalSlice().actions;

  const {
    approvalList,
    approveRequestStatus,
    requestApprovalStatus,
    cancelRequestStatus,
    getApprovalListStatus,
  } = useSelector(selectTimesheet);

  const {
    actions: {
      resetTimesheetRequestStatus,
      getApprovalListRequest,
      requestApprovalRequest,
      cancelRequestRequest,
    },
  } = useTimesheetSlice();

  const { project_role_metadata } = useSelector(selectMember);

  const { setSuccessMessages, setErrorMessages } = useGlobalSlice().actions;

  const {
    userSessionData: { profile },
  } = useSelector(selectGlobal);

  const onSuccess = (messages: string[]) => {
    dispatch(setSuccessMessages(messages));
    reloadData();
  };

  useEffect(() => {
    if (requestApprovalStatus === RequestStatus.SUCCESS) {
      onSuccess(['Request Approval success']);
    }
    if (requestApprovalStatus === RequestStatus.ERROR) {
      dispatch(setErrorMessages(['Oops.. Something went wrong!']));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestApprovalStatus]);

  useEffect(() => {
    if (cancelRequestStatus === RequestStatus.SUCCESS) {
      onSuccess(['Cancel Request success']);
    }
    if (cancelRequestStatus === RequestStatus.ERROR) {
      dispatch(setErrorMessages(['Oops.. Something went wrong!']));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cancelRequestStatus]);

  useEffect(() => {
    if (getApprovalListStatus === RequestStatus.SUCCESS) {
      const firstTask = _.head(approvalList?.data || []);
      setCurrentStatus(firstTask?.status || REQUESTING_TASK_STATUS);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getApprovalListStatus]);

  useEffect(() => {
    if (approveRequestStatus === RequestStatus.SUCCESS) {
      dispatch(setSuccessMessages(['Approve task success']));
      reloadData();
    }
    if (approveRequestStatus === RequestStatus.ERROR) {
      dispatch(setErrorMessages(['Approve task errors']));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [approveRequestStatus]);

  useEffect(() => {
    dispatch(
      setBreadcumbs([
        { title: 'Timesheets', path: Path.TIMESHEET },
        { title: 'Approval' },
      ]),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(
    () => () => {
      dispatch(resetTimesheetRequestStatus());
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const reloadData = () => {
    const params = new URLSearchParams(window.location.search);
    const newMonth = params.get('month')
      ? moment(params.get('month'))
      : moment();
    dispatch(
      getApprovalListRequest({
        month: newMonth?.clone().format('yyyy-MM'),
        member: profile?.id,
        page: Math.abs(_.toInteger(params.get('page')) || 1),
      }),
    );
  };

  useEffect(() => {
    reloadData();
    const params = new URLSearchParams(window.location.search);
    const newPage = Math.abs(_.toInteger(params.get('page')));
    setPage(newPage ? newPage : 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  const handleChangePage = (
    event: React.ChangeEvent<unknown> | null,
    newPage: number,
  ) => {
    let params = queryString.parse(window.location.search);
    const qs = queryString.stringify({ ...params, page: +newPage });
    history.push(`${Path.TIMESHEET_REPORT}?${qs}`);
    setPage(newPage);
  };

  return (
    <Card>
      <CardContent>
        <Box>
          <Stack
            direction="row"
            display="flex"
            spacing={1}
            alignItems="center"
            justifyContent="space-between"
            mb={2}
          >
            <Typography variant="h6" component="h6">
              Projects
            </Typography>
            {currentStatus !== APPROVED_TASK_STATUS &&
              !_.isEmpty(approvalList?.data) && (
                <Stack direction="row" spacing={1}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      dispatch(
                        requestApprovalRequest({
                          month: month?.format('YYYY-MM'),
                        }),
                      );
                    }}
                  >
                    {'Request approve all'}
                  </Button>
                  <Button
                    variant="contained"
                    style={{ backgroundColor: '#dedede', color: '#000' }}
                    onClick={() => {
                      dispatch(
                        cancelRequestRequest({
                          month: month?.format('YYYY-MM'),
                        }),
                      );
                    }}
                  >
                    {'Cancel request all'}
                  </Button>
                </Stack>
              )}
          </Stack>

          <WrapTable>
            <TableContainer component={Paper}>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell>No</TableCell>
                    <TableCell>Project</TableCell>
                    <TableCell>Role</TableCell>
                    <TableCell>Billable</TableCell>
                    <TableCell>Efforts</TableCell>
                    <TableCell>Detail</TableCell>
                    <TableCell>Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {approvalList?.data.map((task, key) => {
                    return (
                      <TableRow key={`approval_row_${key}`}>
                        <TableCell width={60}>
                          {(page - 1) * (approvalList?.page_size || 0) +
                            key +
                            1}
                        </TableCell>
                        <TableCell>
                          <ProjectLink
                            project_id={task?.project?.id}
                            project_name={task?.project?.name}
                          ></ProjectLink>
                        </TableCell>

                        <TableCell>
                          {project_role_metadata?.[task.role]?.name}
                        </TableCell>

                        <TableCell>
                          <BillableTag billable={task?.type}></BillableTag>
                        </TableCell>
                        <TableCell>{task?.effort + 'h'}</TableCell>
                        <TableCell>
                          <Link
                            to={`${
                              Path.TIMESHEET_REPORT_REVIEW
                            }?month=${month?.format('YYYY-MM')}&project=${
                              task?.project?.id
                            }`}
                            style={{ textDecoration: 'none', color: '#0000ee' }}
                          >
                            View Detail
                          </Link>
                        </TableCell>
                        <TableCell width={180}>
                          {task?.status && (
                            <TaskStatus
                              status={task?.status}
                              display_type={STATUS_DISPLAY_TYPE.ADMIN}
                            ></TaskStatus>
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
                {_.isEmpty(approvalList?.data) && (
                  <TableBody>
                    <TableRow>
                      <TableCell colSpan={9}>No data</TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </WrapTable>
          <Stack spacing={2} alignItems={'flex-end'}>
            <Pagination
              page={page}
              color="primary"
              count={approvalList?.total_page || 0}
              variant="outlined"
              shape="rounded"
              onChange={handleChangePage}
            />
          </Stack>
        </Box>
      </CardContent>
    </Card>
  );
}

const WrapTable = styled(Box)`
  margin-bottom: 16px;
`;

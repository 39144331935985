import { StatusCode } from 'constants/API';
import { call, put, takeLatest } from 'redux-saga/effects';
import APIs from 'APIs';
import Slice from '.';

function* getTeamListFlow({ payload }) {
  try {
    const response = yield call(APIs.getTeamList, payload);
    if (response.status === StatusCode.SUCCESS_COMMON) {
      yield put({
        type: Slice.getTeamListSuccess.type,
        payload: response.data,
      });
    } else {
      yield put({ type: Slice.getTeamListFail.type });
    }
  } catch (error) {
    yield put({ type: Slice.getTeamListFail.type });
  }
}

function* createTeamInfoFlow({ payload }) {
  try {
    // const formData = new FormData();
    // _.forEach(payload, (v, k) => {
    //   if (k !== 'logo') {
    //     formData.append(k, v);
    //   }
    // });
    // if (_.get(payload, 'logo') && _.get(payload, 'logo.name')) {
    //   formData.append('logo', payload.logo, payload.logo.name);
    // }
    const response = yield call(APIs.createTeamInfo, payload);
    if (response.status === StatusCode.SUCCESS_CREATED) {
      yield put({
        type: Slice.createTeamInfoSuccess.type,
        payload: response.data,
      });
    } else {
      yield put({
        type: Slice.createTeamInfoFail.type,
        payload: response.data,
      });
    }
  } catch (error) {
    yield put({ type: Slice.createTeamInfoFail.type });
  }
}

function* updateTeamInfoFlow({ payload }) {
  try {
    const { objectId, formData } = payload;

    // const _formData = new FormData();
    //
    // _.forEach(formData, (v, k) => {
    //   if (k !== 'logo') {
    //     _formData.append(k, v);
    //   }
    // });

    // if (_.get(formData, 'logo') && _.get(formData, 'logo[0].name')) {
    //   _formData.append('logo', formData.logo[0], formData.logo[0].name);
    // }
    const response = yield call(APIs.updateTeamInfo, objectId, formData);
    if (response.status === StatusCode.SUCCESS_COMMON) {
      yield put({
        type: Slice.updateTeamInfoSuccess.type,
        payload: response.data,
      });
    } else {
      yield put({
        type: Slice.updateTeamInfoFail.type,
        payload: response.data,
      });
    }
  } catch (error) {
    yield put({ type: Slice.updateTeamInfoFail.type });
  }
}

function* getTeamRoleListFlow({ payload }) {
  try {
    const response = yield call(APIs.getTeamRoleList, payload);
    if (response.status === StatusCode.SUCCESS_COMMON) {
      yield put({
        type: Slice.getTeamRoleListSuccess.type,
        payload: response.data,
      });
    } else {
      yield put({ type: Slice.getTeamRoleListFail.type });
    }
  } catch (error) {
    yield put({ type: Slice.getTeamRoleListFail.type });
  }
}

function* deleteTeamInfoFlow({ payload }) {
  try {
    const response = yield call(APIs.deleteTeamInfo, payload);
    if (response.status === StatusCode.NO_CONTENT) {
      yield put({
        type: Slice.deleteTeamInfoSuccess.type,
      });
    } else {
      yield put({ type: Slice.deleteTeamInfoFail.type });
    }
  } catch (error) {
    yield put({ type: Slice.deleteTeamInfoFail.type });
  }
}

function* teamWatcher() {
  yield takeLatest(Slice.getTeamListRequest, getTeamListFlow);
  yield takeLatest(Slice.getTeamRoleListRequest, getTeamRoleListFlow);
  yield takeLatest(Slice.createTeamInfoRequest, createTeamInfoFlow);
  yield takeLatest(Slice.updateTeamInfoRequest, updateTeamInfoFlow);
  yield takeLatest(Slice.deleteTeamInfoRequest, deleteTeamInfoFlow);
}

export default teamWatcher;

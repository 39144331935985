import React from 'react';

// UI
import { Box } from '@mui/material';

// Library
import styled from 'styled-components/macro';
import { BILLABLE_STATUS_LIST } from 'constants/common';

interface IBillableTag {
  billable: number | string;
}

export function BillableTag(props: IBillableTag) {
  const { billable } = props;
  const currentBillable = BILLABLE_STATUS_LIST?.[billable];
  return (
    <Wrapper
      style={{
        color: currentBillable?.color,
        backgroundColor: currentBillable?.backgroundColor,
      }}
    >
      {currentBillable?.name}
    </Wrapper>
  );
}

const Wrapper = styled(Box)`
  border-radius: 6px;
  padding: 4px 12px;
  width: fit-content;
  border: none;
`;

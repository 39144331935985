import { StatusCode } from 'constants/API';
import { call, put, takeLatest } from 'redux-saga/effects';
import APIs from 'APIs';
import _ from 'lodash';

import Slice from '.';

function* getCustomerListFlow({ payload }) {
  try {
    const response = yield call(APIs.getCustomerList, payload);
    if (response.status === StatusCode.SUCCESS_COMMON) {
      yield put({
        type: Slice.getCustomerListSuccess.type,
        payload: response.data,
      });
    } else {
      yield put({ type: Slice.getCustomerListFail.type });
    }
  } catch (error) {
    yield put({ type: Slice.getCustomerListFail.type });
  }
}

function* createCustomerInfoFlow({ payload }) {
  try {
    const response = yield call(APIs.createCustomerInfo, payload);
    if (response.status === StatusCode.SUCCESS_CREATED) {
      payload.id = response.data.id;
      yield uploadCustomerLogoFlow(response.data.id, payload.logo);
      yield put({
        type: Slice.createCustomerInfoSuccess.type,
        payload: response.data,
      });
    } else {
      yield put({
        type: Slice.createCustomerInfoFail.type,
        payload: response.data,
      });
    }
  } catch (error) {
    yield put({ type: Slice.createCustomerInfoFail.type });
  }
}

function* updateCustomerInfoFlow({ payload }) {
  try {
    const response = yield call(APIs.updateCustomerInfo, payload);
    if (response.status === StatusCode.SUCCESS_COMMON) {
      yield uploadCustomerLogoFlow(payload.id, payload.logo);
      yield put({
        type: Slice.updateCustomerInfoSuccess.type,
        payload: response.data,
      });
    } else {
      yield put({
        type: Slice.updateCustomerInfoFail.type,
        payload: response.data,
      });
    }
  } catch (error) {
    yield put({ type: Slice.updateCustomerInfoFail.type });
  }
}

function* deleteCustomerInfoFlow({ payload }) {
  try {
    const response = yield call(APIs.deleteCustomerInfo, payload);
    if (response.status === StatusCode.NO_CONTENT) {
      yield put({
        type: Slice.deleteCustomerInfoSuccess.type,
      });
    } else {
      yield put({ type: Slice.deleteCustomerInfoFail.type });
    }
  } catch (error) {
    yield put({ type: Slice.deleteCustomerInfoFail.type });
  }
}

function* uploadCustomerLogoFlow(id: number, logo: []) {
  try {
    const formData = new FormData();
    if (logo && logo.length > 0) {
      const d = _.head(logo);
      const fn = _.get(_.head(logo), 'name', '');
      if (d && fn) {
        formData.append('logo_url', d, fn);
        yield call(APIs.uploadCustomerLogo, id, formData);
      }
    }
  } catch (error) {
    console.error(error);
  }
}

function* customerWatcher() {
  yield takeLatest(Slice.getCustomerListRequest, getCustomerListFlow);
  yield takeLatest(Slice.createCustomerInfoRequest, createCustomerInfoFlow);
  yield takeLatest(Slice.updateCustomerInfoRequest, updateCustomerInfoFlow);
  yield takeLatest(Slice.deleteCustomerInfoRequest, deleteCustomerInfoFlow);
}

export default customerWatcher;

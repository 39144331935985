import { StatusCode } from 'constants/API';
import { call, put, takeLatest } from 'redux-saga/effects';
import APIs from 'APIs';
import Slice from '.';

function* forgotPasswordFlow({ payload }) {
  try {
    const response = yield call(APIs.forgotPassword, payload);
    if (response.status === StatusCode.SUCCESS_COMMON) {
      yield put({
        type: Slice.forgotPasswordSuccess.type,
      });
    } else {
      yield put({ type: Slice.forgotPasswordFail.type });
    }
  } catch (error) {
    yield put({ type: Slice.forgotPasswordFail.type });
  }
}
function* checkExpiredLinkForgotPasswordFlow({ payload }) {
  try {
    const response = yield call(APIs.checkExpiredLinkForgotPassword, payload);
    if (response.status === StatusCode.SUCCESS_COMMON) {
      yield put({
        type: Slice.checkExpiredLinkForgotPasswordSuccess.type,
      });
    } else {
      yield put({ type: Slice.checkExpiredLinkForgotPasswordFail.type });
    }
  } catch (error) {
    yield put({ type: Slice.checkExpiredLinkForgotPasswordFail.type });
  }
}
function* resetPasswordFlow({ payload }) {
  try {
    const response = yield call(APIs.resetPassword, payload);
    if (response.status === StatusCode.SUCCESS_COMMON) {
      yield put({
        type: Slice.resetPasswordSuccess.type,
      });
    } else {
      yield put({ type: Slice.resetPasswordFail.type });
    }
  } catch (error) {
    yield put({ type: Slice.resetPasswordFail.type });
  }
}
function* changePasswordFlow({ payload }) {
  try {
    const response = yield call(APIs.changePassword, payload);
    if (response.status === StatusCode.SUCCESS_COMMON) {
      yield put({
        type: Slice.changePasswordSuccess.type,
      });
    } else {
      yield put({ type: Slice.changePasswordFail.type });
    }
  } catch (error) {
    yield put({ type: Slice.changePasswordFail.type });
  }
}

function* forgotPasswordWatcher() {
  yield takeLatest(Slice.forgotPasswordRequest, forgotPasswordFlow);
  yield takeLatest(
    Slice.checkExpiredLinkForgotPasswordRequest,
    checkExpiredLinkForgotPasswordFlow,
  );
  yield takeLatest(Slice.resetPasswordRequest, resetPasswordFlow);
  yield takeLatest(Slice.changePasswordRequest, changePasswordFlow);
}

export default forgotPasswordWatcher;

import React from 'react';

// UI
import { Link } from 'react-router-dom';
import Path from 'config/clientPath';
// Redux & Sagas
import { useSelector } from 'react-redux';
import { selectMember } from 'app/pages/MemberPage/slice/selectors';
import { selectGlobal } from 'app/pages/GlobalContainer/slice/selectors';
import Roles from 'constants/roles';
// Library
import _ from 'lodash';
import styled from 'styled-components/macro';

export function MemberLink(props) {
  const { userSessionData } = useSelector(selectGlobal);
  const haveMemberEditRole = _.includes(
    userSessionData.roles,
    Roles.MEMBER_VIEW,
  );
  const { member_meta_data, listMember } = useSelector(selectMember);
  return (
    <Wrapper>
      <Link
        to={
          haveMemberEditRole
            ? `${Path.MEMBERS}/edit/${props.member_id}`
            : location => ({ ...location })
        }
      >
        {props.member_name
          ? props.member_name
          : _.get(
              _.find(member_meta_data ? member_meta_data : listMember?.data, {
                id: _.toInteger(props.member_id),
              }),
              'full_name',
            )}
      </Link>
    </Wrapper>
  );
}
const Wrapper = styled('div')`
  a {
    color: inherit;
    text-decoration: inherit;
    :hover {
      text-decoration: underline;
    }
  }
`;

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Box, Button, Menu, MenuItem } from '@mui/material';
import * as React from 'react';
import styled from 'styled-components';
import { Colors, device } from './Variables';

export default function LanguageMenu() {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <ButtonWrapper>
      <Button
        id="demo-positioned-button"
        aria-controls="demo-positioned-menu"
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        Eng
        <ArrowDropDownIcon />
      </Button>
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <MenuItem onClick={handleClose}>Japan</MenuItem>
        <MenuItem onClick={handleClose}>Espanol</MenuItem>
      </Menu>
    </ButtonWrapper>
  );
}

const ButtonWrapper = styled(Box)`
  visibility: hidden;
  button {
    color: ${Colors.darkBlue};
  }
  @media ${device.laptop} {
    padding: 20px;
  }
  @media ${device.tablet} {
    padding: 0;
    display: none;
  }
`;

import React, { useState, useEffect } from 'react';

// UI
import { Helmet } from 'react-helmet-async';

import {
  Box,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Stack,
  Paper,
} from '@mui/material';

import { Container } from 'app/components/Container';
import { Wrapper, Content } from 'app/components/Wrapper';

// import { DialogForm } from './components/Form';

// Redux & Sagas
import { useDispatch, useSelector } from 'react-redux';
import { RequestStatus } from 'constants/API';
// import { selectCustomer } from './slice/selectors';
// import { useCustomerSlice } from './slice';
// import { ICustomerSchema } from './slice/types';
import { useGlobalSlice } from 'app/pages/GlobalContainer/slice';
import queryString from 'query-string';

// Library
import { useLocation } from 'react-router';
import _ from 'lodash';
import { Loader } from 'app/components/Loader';
import { checkIsRequesting, getHalfYearMonths, hexToRgba } from 'utils/helper';
import styled from 'styled-components';
import FilterBar from './components/FilterBar';
import moment from 'moment';
import { useAssignmentSummarySlice } from './slice';
import { AssignmentDetail } from './components/AssignmentDetail';
import { selectGlobal } from '../GlobalContainer/slice/selectors';
import { selectAssignmentSummary } from './slice/selectors';
import { useWindowDimensions } from 'utils/useWindowDimensions';
import { selectTeam } from '../TeamPage/slice/selectors';
import { useTeamSlice } from '../TeamPage/slice';
import roles from 'constants/roles';
import { AvatarMember } from 'app/components/Avatar';
import { MemberLink } from 'app/components/MemberLink';

export function AssignmentSummary() {
  const dispatch = useDispatch();

  const { width: pageWidth } = useWindowDimensions(0);
  const { setBreadcumbs } = useGlobalSlice().actions;
  const {
    userSessionData: { userRole },
  } = useSelector(selectGlobal);

  const isCustomer = userRole === roles.IS_CUSTOMER;
  // const isCustomer = true;

  const {
    actions: { getTeamListRequest },
  } = useTeamSlice();
  const { getTeamListStatus } = useSelector(selectTeam);

  const location = useLocation();

  const [currentMonth, setCurrentMonth] = useState(moment().startOf('month'));

  const [months, setMonths] = useState<any>([]);
  const [activeMonth, setActiveMonth] = useState('');
  const [showAssignDetailModal, setShowAssignDetailModal] = useState(false);

  const {
    getAssignmentSummaryStatus,
    assignmentSummary,
    getAssignmentDetailStatus,
  } = useSelector(selectAssignmentSummary);

  const {
    actions: { getAssignmentSummaryRequest, getAssignmentDetailRequest },
  } = useAssignmentSummarySlice();

  const isLoading = checkIsRequesting([
    getTeamListStatus,
    getAssignmentSummaryStatus,
    getAssignmentDetailStatus,
  ]);

  useEffect(() => {
    dispatch(
      setBreadcumbs([{ title: isCustomer ? 'Assignable' : 'Assignment' }]),
    );
    dispatch(
      getTeamListRequest({
        page: 1,
      }),
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (getAssignmentDetailStatus === RequestStatus.SUCCESS) {
      setShowAssignDetailModal(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAssignmentDetailStatus]);

  useEffect(() => {
    const _months = getHalfYearMonths(currentMonth);
    setMonths(_months);

    // let newData = {};
    // setAssignmentSummaryFake(assignmentSummaryFake);
    // assignmentSummaryFake.map((v, k) => {
    //   newData = { ...newData, [k]: v };
    //   return '';
    // });
    // reloadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(currentMonth)]);

  const reloadData = () => {
    const params = queryString.parse(window.location.search);
    dispatch(
      getAssignmentSummaryRequest({
        from: _.head(months).dashMonthString,
        to: _.last(months).dashMonthString,
        team: params?.team || '',
        search: params?.search || '',
      }),
    );
  };

  useEffect(() => {
    if (!_.isEmpty(months)) reloadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(months)]);

  useEffect(() => {
    // const params = new URLSearchParams(window.location.search);
    if (!_.isEmpty(months)) reloadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  return (
    <>
      <Helmet>
        <title>{isCustomer ? 'Assignable' : 'Summary'}</title>
        <meta
          name="description"
          content={isCustomer ? 'Assignable' : 'Assignment Summary'}
        />
      </Helmet>
      <Container>
        <AssignmentDetail
          activeMonth={activeMonth}
          open={showAssignDetailModal}
          onClose={() => setShowAssignDetailModal(false)}
        ></AssignmentDetail>
        <Wrapper>
          <Loader open={isLoading} />
          <Content spacing={2}>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
            >
              <Typography variant="h6" component="h6">
                {isCustomer ? 'Assignable' : 'Summary'}
              </Typography>
            </Box>

            <FilterBar
              isCustomer={isCustomer}
              months={months}
              changeMonth={diff_month => {
                setCurrentMonth(currentMonth.clone().add(diff_month, 'month'));
              }}
            />
            <WrapTable>
              <TableContainer component={Paper}>
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ minWidth: 60 }} width={60}>
                        No
                      </TableCell>
                      <TableCell sx={{ minWidth: 90 }} width={100}>
                        Team
                      </TableCell>
                      <TableCell sx={{ minWidth: 160 }}>Name</TableCell>
                      {months.map(v => {
                        return (
                          <TableCell
                            sx={{ minWidth: 90 }}
                            align="center"
                            key={v.slashMonthString}
                            className={
                              v.slashMonthString === moment().format('YYYY/MM')
                                ? 'current_month'
                                : 'other_month'
                            }
                            width={pageWidth < 1600 ? 100 : 150}
                          >
                            {v.slashMonthString}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {assignmentSummary?.map((item, idx) => {
                      return (
                        <TableRow key={item.id}>
                          <TableCell>{idx + 1}</TableCell>
                          <TableCell>{_.head(item.teams)?.name}</TableCell>
                          <TableCell>
                            <Stack
                              direction="row"
                              spacing={'6px'}
                              alignItems="center"
                            >
                              <AvatarMember
                                avatar={`${item?.avatar || ''}`}
                                title={`${item?.full_name || ''}`}
                                sx={{ width: 24, height: 24 }}
                              ></AvatarMember>
                              <MemberLink
                                member_id={item?.id}
                                member_name={item?.full_name}
                              ></MemberLink>
                            </Stack>
                          </TableCell>
                          {months.map(v => {
                            const assign_effort =
                              item?.data?.[v.dashMonthString]?.assign_effort;
                            const commit_effort =
                              item?.data?.[v.dashMonthString]?.commit_effort;

                            let backgroundColor = '';
                            let assignable_hours =
                              commit_effort - assign_effort;
                            assignable_hours =
                              assignable_hours > 0 ? assignable_hours : 0;
                            let display_info = '-';
                            if (isCustomer) {
                              display_info = assignable_hours
                                ? assignable_hours + 'h'
                                : '-';
                              backgroundColor = hexToRgba(
                                '#f0ad4e',
                                +assignable_hours / 160,
                              );
                            } else {
                              display_info = assign_effort
                                ? `${assign_effort}h / ${commit_effort}h`
                                : '-';
                              backgroundColor = hexToRgba(
                                '#437dff',
                                +assign_effort / 160,
                              );
                            }
                            return (
                              <TableCell
                                align="center"
                                key={v.slashMonthString}
                                style={{
                                  backgroundColor,
                                  cursor: 'pointer',
                                  // cursor: assign_effort ? 'pointer' : 'unset',
                                }}
                                onClick={e => {
                                  e.preventDefault();
                                  // if (assign_effort) {
                                  setActiveMonth(v.month.format('MM/YYYY'));
                                  dispatch(
                                    getAssignmentDetailRequest({
                                      month: v.dashMonthString,
                                      id: item.id,
                                    }),
                                  );
                                  // }
                                }}
                              >
                                {display_info}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      );
                    })}
                    {_.isEmpty(assignmentSummary) && (
                      <TableRow>
                        <TableCell colSpan={9}>No data</TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </WrapTable>
          </Content>
        </Wrapper>
      </Container>
    </>
  );
}

const WrapTable = styled(Box)`
  & .MuiTableContainer-root {
    max-height: calc(100vh - 190px);
  }
  table {
    tr th {
      color: rgb(0 0 0 / 85%);
      &.current_month {
        color: #437dff;
        font-weight: bold !important;
      }
      &.other_month {
        color: rgb(0 0 0 / 45%);
        background: #d9d9d9;
      }
    }
    tr th,
    tr td {
    }
    tr td {
      color: rgb(0 0 0 / 85%);
    }
  }
`;

import React from 'react';

// UI
import {
  Grid,
  Stack,
  Typography,
  FormLabel,
  TextField,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { selectForgotPassword } from 'app/pages/ForgotPasswordPage/slice/selectors';
import DatePicker from '@mui/lab/DatePicker';
import InputImage from 'app/components/InputImage';
import { MEMBER_STATUSES } from 'constants/common';
import SaveIcon from '@mui/icons-material/Save';
// Redux & Sagas
import { RequestStatus } from 'constants/API';
import { useDispatch, useSelector } from 'react-redux';
import {
  Controller,
  UseFormReturn,
  SubmitHandler,
  FieldValues,
} from 'react-hook-form';
import { IMemberSchema } from 'app/pages/MemberPage/slice/types';
import { useMemberSlice } from 'app/pages/MemberPage/slice';
import { selectMember } from 'app/pages/MemberPage/slice/selectors';
import { selectGlobal } from 'app/pages/GlobalContainer/slice/selectors';
import _ from 'lodash';
import { useGlobalSlice } from 'app/pages/GlobalContainer/slice';
interface IBasicInfoProps {
  memberForm: UseFormReturn;
  changePasswordForm: UseFormReturn;
  handleSaveData: SubmitHandler<FieldValues>;
  handleChangePassword: SubmitHandler<FieldValues>;
  callAPIUploadImage: any;
}
interface IResetPasswordForm {
  old_password: string;
  new_password: string;
  confirm_password: string;
}

export default function BasicInfo(props: IBasicInfoProps) {
  const dispatch = useDispatch();

  const {
    actions: { getProjectRoleListRequest },
  } = useMemberSlice();

  const { setUserSessionData } = useGlobalSlice().actions;
  const { changePasswordStatus } = useSelector(selectForgotPassword);
  const { updateMemberInfoStatus, memberInfo, uploadMemberAvatarStatus } =
    useSelector(selectMember);
  const { userSessionData } = useSelector(selectGlobal);

  const {
    register,
    getValues,
    control,
    handleSubmit,
    formState: { errors },
  } = props.memberForm;
  const changePasswordForm = props.changePasswordForm;
  React.useEffect(() => {
    dispatch(getProjectRoleListRequest({}));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateNewMemberInfo = () => {
    const newMemberInfo = {
      ...userSessionData,
      profile: {
        ...userSessionData.profile,
        avatar: memberInfo?.avatar || '',
        email: memberInfo?.email || '',
        full_name: memberInfo?.full_name || '',
        gender: _.toNumber(memberInfo?.gender) || 0,
      },
    };
    dispatch(setUserSessionData(newMemberInfo));
  };

  React.useEffect(() => {
    if (updateMemberInfoStatus === RequestStatus.SUCCESS) {
      updateNewMemberInfo();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateMemberInfoStatus]);

  React.useEffect(() => {
    if (uploadMemberAvatarStatus === RequestStatus.SUCCESS) {
      updateNewMemberInfo();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadMemberAvatarStatus]);

  return (
    <Grid container spacing={6}>
      <Grid item xs={12} lg={8}>
        <Stack>
          <Typography variant="h5" component="h5">
            Persona
          </Typography>

          <Grid container spacing={2} pb={4} pt={2}>
            <Grid item xs={10}>
              <FormLabel>Full Name</FormLabel>
              <TextField
                variant="outlined"
                fullWidth
                margin="dense"
                size="small"
                error={errors.full_name ? true : false}
                {...register<keyof IMemberSchema>('full_name', {
                  required: 'This is required.',
                  maxLength: 60,
                })}
                helperText={errors.full_name?.message}
              />
            </Grid>

            <Grid item xs={10}>
              <FormLabel>Status</FormLabel>
              <FormControl fullWidth margin="dense">
                <Controller
                  control={control}
                  name="status"
                  render={({ field }) => {
                    const { onBlur, onChange, value } = field;
                    return (
                      <RadioGroup
                        row
                        value={value ? value.toString() : null}
                        onBlur={onBlur}
                        onChange={onChange}
                      >
                        {MEMBER_STATUSES.map(v => (
                          <FormControlLabel
                            value={v.value}
                            control={<Radio />}
                            label={v.label}
                          />
                        ))}
                      </RadioGroup>
                    );
                  }}
                />
              </FormControl>
            </Grid>

            <Grid item xs={10}>
              <FormLabel>Email Address</FormLabel>
              <TextField
                value={getValues('email')}
                fullWidth
                size="small"
                margin="dense"
                disabled
              />
            </Grid>
            <Grid item xs={10}>
              <FormLabel>Phone Number</FormLabel>
              <TextField
                variant="outlined"
                fullWidth
                margin="dense"
                size="small"
                error={errors.phone ? true : false}
                {...register<keyof IMemberSchema>('phone', {
                  // required: 'This is required.',
                  maxLength: 12,
                })}
                helperText={errors.phone?.message}
              />
            </Grid>
            <Grid item xs={10}>
              <FormLabel>DOB</FormLabel>
              <FormControl fullWidth margin="dense">
                <Controller
                  control={control}
                  name="birthday"
                  render={({ field: { onChange, value } }) => (
                    <DatePicker
                      disableFuture
                      value={value}
                      onChange={onChange}
                      inputFormat="DD-MM-YYYY"
                      renderInput={params => (
                        <TextField
                          {...params}
                          fullWidth
                          hiddenLabel
                          size="small"
                        />
                      )}
                    />
                  )}
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid xs={4}>
            <LoadingButton
              variant="contained"
              color="primary"
              loadingPosition="start"
              startIcon={<SaveIcon />}
              loading={updateMemberInfoStatus === RequestStatus.REQUESTING}
              onClick={() => handleSubmit(props.handleSaveData)()}
            >
              Save change
            </LoadingButton>
          </Grid>
          <Typography variant="h5" pt={5} component="h5">
            Change password
          </Typography>

          <TextField
            id="outlined-basic"
            label="Outlined"
            variant="outlined"
            className="disable_autofill"
          />
          <Grid container spacing={2} pb={4} pt={2}>
            <Grid item xs={10}>
              <FormLabel>Current Password</FormLabel>
              <TextField
                variant="outlined"
                fullWidth
                margin="dense"
                size="small"
                {...changePasswordForm.register<keyof IResetPasswordForm>(
                  'old_password',
                  {
                    required: 'This is required.',
                  },
                )}
                type="password"
                placeholder="Enter password"
                error={
                  changePasswordForm.formState.errors.old_password
                    ? true
                    : false
                }
                helperText={
                  changePasswordForm.formState.errors.old_password?.message
                }
              />
            </Grid>

            <Grid item xs={10}>
              <FormLabel>New Password</FormLabel>
              <TextField
                variant="outlined"
                fullWidth
                margin="dense"
                size="small"
                {...changePasswordForm.register<keyof IResetPasswordForm>(
                  'new_password',
                  {
                    required: 'This is required.',
                  },
                )}
                type="password"
                placeholder="Enter password"
                error={
                  changePasswordForm.formState.errors.new_password
                    ? true
                    : false
                }
                helperText={
                  changePasswordForm.formState.errors.new_password?.message
                }
              />
            </Grid>
            <Grid item xs={10}>
              <FormLabel>Confirm Password</FormLabel>
              <TextField
                variant="outlined"
                fullWidth
                margin="dense"
                size="small"
                {...changePasswordForm.register<keyof IResetPasswordForm>(
                  'confirm_password',
                  {
                    required: 'This is required.',
                    validate: value =>
                      value === changePasswordForm.watch('new_password'),
                  },
                )}
                type="password"
                placeholder="Enter password"
                error={
                  changePasswordForm.formState.errors.confirm_password
                    ? true
                    : false
                }
                helperText={
                  changePasswordForm.formState.errors.confirm_password?.message
                }
              />
            </Grid>
          </Grid>
          <Grid xs={4}>
            <LoadingButton
              variant="contained"
              color="primary"
              loadingPosition="start"
              startIcon={<SaveIcon />}
              loading={changePasswordStatus === RequestStatus.REQUESTING}
              onClick={() =>
                changePasswordForm.handleSubmit(props.handleChangePassword)()
              }
            >
              Update
            </LoadingButton>
          </Grid>
        </Stack>
      </Grid>
      <Grid item xs={12} lg={4} display="flex" alignItems="center">
        <InputImage
          form={props.memberForm}
          inputName="avatar"
          size={300}
          callAPIUploadImage={props.callAPIUploadImage}
        />
      </Grid>
    </Grid>
  );
}

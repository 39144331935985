import { StatusCode } from 'constants/API';
import { call, put, takeLatest } from 'redux-saga/effects';
import APIs from 'APIs';
import Slice from '.';

function* getTimesheetFlow({ payload }) {
  try {
    const response = yield call(APIs.getTimesheet, payload);
    if (response.status === StatusCode.SUCCESS_COMMON) {
      yield put({
        type: Slice.getTimesheetSuccess,
        payload: response.data,
      });
    } else {
      yield put({ type: Slice.getTimesheetFail.type });
    }
  } catch (error) {
    yield put({ type: Slice.getTimesheetFail.type });
  }
}

function* addTimesheetFlow({ payload }) {
  let errorMess = ['Opps..Member update failed!'];
  try {
    const response = yield call(APIs.addTimesheet, payload);
    if (response.status === StatusCode.SUCCESS_CREATED) {
      yield put({
        type: Slice.addTimesheetSuccess.type,
        payload: response.data,
      });
    } else {
      const { data, status } = response.response;
      if (status === StatusCode.BAD_REQUEST) {
        errorMess = Object.keys(data).map(k => {
          return data[k].join(', ');
        });
      }
      yield put({ type: Slice.addTimesheetFail.type, payload: { errorMess } });
    }
  } catch (error) {
    yield put({ type: Slice.addTimesheetFail.type, payload: { errorMess } });
  }
}

function* updateTimesheetFlow({ payload }) {
  try {
    const { objectId, formData } = payload;
    const response = yield call(APIs.updateTimesheet, objectId, formData);
    if (response.status === StatusCode.SUCCESS_COMMON) {
      yield put({
        type: Slice.updateTimesheetSuccess.type,
        payload: response.data,
      });
    } else {
      yield put({ type: Slice.updateTimesheetFail.type });
    }
  } catch (error) {
    yield put({ type: Slice.updateTimesheetFail.type });
  }
}

function* deleteTimesheetFlow({ payload }) {
  try {
    const response = yield call(APIs.deleteTimesheet, payload);
    if (response.status === StatusCode.NO_CONTENT) {
      yield put({
        type: Slice.deleteTimesheetSuccess.type,
        payload: response.data,
      });
    } else {
      yield put({ type: Slice.deleteTimesheetFail.type });
    }
  } catch (error) {
    yield put({ type: Slice.deleteTimesheetFail.type });
  }
}

function* getDateRangeWorkReportFlow({ payload }) {
  try {
    const response = yield call(APIs.getDateRangeWorkReport, payload);
    if (response.status === StatusCode.SUCCESS_COMMON) {
      yield put({
        type: Slice.getDateRangeWorkReportSuccess,
        payload: response.data,
      });
    } else {
      yield put({ type: Slice.getDateRangeWorkReportFail.type });
    }
  } catch (error) {
    yield put({ type: Slice.getDateRangeWorkReportFail.type });
  }
}

function* requestApprovalFlow({ payload }) {
  try {
    const response = yield call(APIs.requestApproval, payload);
    if (response.status === StatusCode.NO_CONTENT) {
      yield put({
        type: Slice.requestApprovalSuccess.type,
        payload: response.data,
      });
    } else {
      yield put({ type: Slice.requestApprovalFail.type });
    }
  } catch (error) {
    yield put({ type: Slice.requestApprovalFail.type });
  }
}

function* cancelRequestFlow({ payload }) {
  try {
    const response = yield call(APIs.cancelRequest, payload);
    if (response.status === StatusCode.NO_CONTENT) {
      yield put({
        type: Slice.cancelRequestSuccess.type,
        payload: response.data,
      });
    } else {
      yield put({ type: Slice.cancelRequestFail.type });
    }
  } catch (error) {
    yield put({ type: Slice.cancelRequestFail.type });
  }
}

function* approveRequestFlow({ payload }) {
  try {
    const response = yield call(APIs.approveRequest, payload);
    if (response.status === StatusCode.NO_CONTENT) {
      yield put({
        type: Slice.approveRequestSuccess.type,
        payload: response.data,
      });
    } else {
      yield put({ type: Slice.approveRequestFail.type });
    }
  } catch (error) {
    yield put({ type: Slice.approveRequestFail.type });
  }
}

function* getApprovalListFlow({ payload }) {
  try {
    const response = yield call(APIs.getApprovalList, payload);
    if (response.status === StatusCode.SUCCESS_COMMON) {
      yield put({
        type: Slice.getApprovalListSuccess,
        payload: response.data,
      });
    } else {
      yield put({ type: Slice.getApprovalListFail.type });
    }
  } catch (error) {
    yield put({ type: Slice.getApprovalListFail.type });
  }
}

function* getAssignProjectsFlow({ payload }) {
  try {
    const response = yield call(APIs.getAssignProjects, payload);
    if (response.status === StatusCode.SUCCESS_COMMON) {
      yield put({
        type: Slice.getAssignProjectsSuccess,
        payload: response.data,
      });
    } else {
      yield put({ type: Slice.getAssignProjectsFail.type });
    }
  } catch (error) {
    yield put({ type: Slice.getAssignProjectsFail.type });
  }
}

function* getAllProjectsFlow({ payload }) {
  try {
    const response = yield call(APIs.getAllProjects, payload);
    if (response.status === StatusCode.SUCCESS_COMMON) {
      yield put({
        type: Slice.getAllProjectsSuccess,
        payload: response.data,
      });
    } else {
      yield put({ type: Slice.getAllProjectsFail.type });
    }
  } catch (error) {
    yield put({ type: Slice.getAllProjectsFail.type });
  }
}

function* getAllMembersFlow({ payload }) {
  try {
    const response = yield call(APIs.getAllMembers, payload);
    if (response.status === StatusCode.SUCCESS_COMMON) {
      yield put({
        type: Slice.getAllMembersSuccess,
        payload: response.data,
      });
    } else {
      yield put({ type: Slice.getAllMembersFail.type });
    }
  } catch (error) {
    yield put({ type: Slice.getAllMembersFail.type });
  }
}

function* timesheetWatcher() {
  yield takeLatest(Slice.getAllProjectsRequest, getAllProjectsFlow);
  yield takeLatest(Slice.getAllMembersRequest, getAllMembersFlow);
  yield takeLatest(Slice.getAssignProjectsRequest, getAssignProjectsFlow);
  yield takeLatest(Slice.requestApprovalRequest, requestApprovalFlow);
  yield takeLatest(Slice.cancelRequestRequest, cancelRequestFlow);
  yield takeLatest(Slice.getTimesheetRequest, getTimesheetFlow);
  yield takeLatest(Slice.addTimesheetRequest, addTimesheetFlow);
  yield takeLatest(Slice.updateTimesheetRequest, updateTimesheetFlow);
  yield takeLatest(Slice.deleteTimesheetRequest, deleteTimesheetFlow);
  yield takeLatest(Slice.approveRequestRequest, approveRequestFlow);
  yield takeLatest(Slice.getApprovalListRequest, getApprovalListFlow);
  yield takeLatest(
    Slice.getDateRangeWorkReportRequest,
    getDateRangeWorkReportFlow,
  );
}

export default timesheetWatcher;

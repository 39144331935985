import React from 'react';

// UI
import {
  Stack,
  Grid,
  Box,
  Typography,
  TableContainer,
  Paper,
  Table,
  Button,
} from '@mui/material';

import SkillTable from './SkillTable';
import LanguageTable from './LanguageTable';
import ProjectTable from './ProjectTable';
import BasicCVProfile from './BasicCVProfile';
import InputImage from 'app/components/InputImage';

// Redux & Sagas
import { useSelector } from 'react-redux';
import { selectMember } from 'app/pages/MemberPage/slice/selectors';

// Library
import _ from 'lodash';
import { UseFormReturn } from 'react-hook-form';

interface ICVFormProps {
  memberForm: UseFormReturn;
}

export default function CVForm(props: ICVFormProps) {
  const { setValue, getValues } = props.memberForm;

  const { skill_metadata, language_metadata } = useSelector(selectMember);

  const [projectNum, setProjectNum] = React.useState<number>(
    _.size(props.memberForm.getValues('project_histories')) || 1,
  );

  let startIndex = 0;

  React.useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Stack spacing={2}>
      <Grid container>
        <Grid item xs>
          <BasicCVProfile memberForm={props.memberForm} />
        </Grid>
        <Grid item xs={3} display="flex" alignItems="center" direction="column">
          <InputImage
            form={props.memberForm}
            inputName="avatar"
            size={256}
            inline
          />
        </Grid>
      </Grid>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="h6">スキルセット</Typography>
        <Typography variant="subtitle2">
          ◎：ネイティブレベル ／ ◯：ビジネスレベル ／ △：日常会話レベル
        </Typography>
      </Box>
      <TableContainer component={Paper} variant="outlined" square>
        <Table size="small">
          {skill_metadata?.map((it, idx) => {
            if (idx > 0) {
              // customize number row for display per skills
              // startIndex += SKILLS[idx - 1].rows * 4;
              startIndex += 2 * 4;
            }
            return (
              <SkillTable
                key={`st_${idx}`}
                title={it.name}
                // rows={it.rows}
                rows={2}
                memberForm={props.memberForm}
                dataKey="skills"
                startIndex={startIndex}
                data={it.skills}
              />
            );
          })}
        </Table>
      </TableContainer>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="h6">対応可能言語</Typography>
        <Typography variant="subtitle2">
          ◎：ネイティブレベル ／ ◯：ビジネスレベル ／ △：日常会話レベル
        </Typography>
      </Box>
      <TableContainer component={Paper} variant="outlined" square>
        <Table size="small">
          {language_metadata?.map((it, idx) => (
            <LanguageTable
              key={`lang_${idx}`}
              title={it.name}
              memberForm={props.memberForm}
              startIndex={idx}
              dataId={it.id}
            />
          ))}
        </Table>
      </TableContainer>
      <Box>
        <Typography variant="h6">最近の開発実績</Typography>
      </Box>
      {_.times(projectNum, prjIndex => {
        return (
          <ProjectTable
            key={`prj_${prjIndex}`}
            memberForm={props.memberForm}
            itemIndex={prjIndex}
          />
        );
      })}
      <Box display="flex" flexDirection="row" justifyContent="space-between">
        <Button
          variant="outlined"
          onClick={() => {
            setProjectNum(projectNum => projectNum + 1);
          }}
        >
          Add Project
        </Button>
        <Button
          color="error"
          disabled={projectNum <= 0}
          onClick={() => {
            if (projectNum > 0) {
              setValue(
                `project_histories`,
                _.take(getValues('project_histories'), projectNum - 1),
              );
            }
            setProjectNum(projectNum => projectNum - 1);
          }}
        >
          Remove last project
        </Button>
      </Box>
    </Stack>
  );
}

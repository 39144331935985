export const Colors = {
  blueHeading: '#0B6DC7',
  orangeHeading: '#FD6618',
  lightBlue: '#89DEF4',
  darkBlue: '#37517E',
  lightGrey: '#ededed',
  white: '#fff',
  skyBlue: '#E3F8FE',
};

export const FontSizes = {
  root: '16px',
  text14: '14px',
  text20: '20px',
  text24: '24px',
  text36: '36px',
  text48: '48px',
};

const size = {
  mobileS: '320px',
  mobileM: '375px',
  mobileL: '425px',
  mobileXL: '625px',
  tablet: '768px',
  laptop: '1024px',
  laptopL: '1439px',
  desktop: '1920px',
};

export const device = {
  mobileS: `(max-width: ${size.mobileS})`,
  mobileM: `(max-width: ${size.mobileM})`,
  mobileL: `(max-width: ${size.mobileL})`,
  mobileXL: `(max-width: ${size.mobileXL})`,
  tablet: `(max-width: ${size.tablet})`,
  laptop: `(max-width: ${size.laptop})`,
  laptopL: `(max-width: ${size.laptopL})`,
  desktop: `(max-width: ${size.desktop})`,
  desktopL: `(max-width: ${size.desktop})`,
};

import { Box, Stack, Typography } from '@mui/material';
import { ReactComponent as CustomerIcon } from 'assets/icons/landing/customer_info_icon.svg';
import { ReactComponent as HRIcon } from 'assets/icons/landing/hr_solution_icon.svg';
import { ReactComponent as MeetingIcon } from 'assets/icons/landing/pro_mgmt_icon.svg';
import AboutUs from 'assets/img/landing_team.png';
import React from 'react';
import styled from 'styled-components';
import { Heading } from './Heading';
import { Colors, device, FontSizes } from './Variables';

export const About = () => {
  return (
    <>
      <MainSectionWrapper id="About">
        <Heading>About Palette System</Heading>
        <Paragraph className="center blue-text justify">
          Let employees collaborate across departments, locations, and Odoo
          modules by interacting in real-time with live chat, share knowledge
          and best practices, follow specific people or documents to stay
          informed, as well as joining interest groups to share expertise and
          encourage teamwork.
        </Paragraph>
        <AboutUsStack direction="row" spacing={2} mb={3} mt={3}>
          <IconBox>
            <ImgBox>
              <HRIcon />
            </ImgBox>
            <Heading4>HR Solutions</Heading4>
            <Paragraph>
              Successfully manage your employees. Track time &ampersand;
              attendance. Employee evaluation made easy
            </Paragraph>
          </IconBox>
          <IconBox>
            <ImgBox>
              <CustomerIcon />
            </ImgBox>
            <Heading4>Customers Infomation</Heading4>
            <Paragraph>
              Successfully manage your employees. Track time &ampersand;
              attendance. Employee evaluation made easy
            </Paragraph>
          </IconBox>
          <IconBox>
            <ImgBox>
              <MeetingIcon />
            </ImgBox>
            <Heading4>Project Management</Heading4>
            <Paragraph>
              Successfully manage your employees. Track time &ampersand;
              attendance. Employee evaluation made easy
            </Paragraph>
          </IconBox>
        </AboutUsStack>
      </MainSectionWrapper>
      <Image src={AboutUs} alt="About Us" />
    </>
  );
};

export const MainSectionWrapper = styled.section`
  margin: 40px auto;
  max-width: 1040px;
  padding: 20px;
  @media ${device.tablet} {
    margin: 20px auto 0;
  }
`;
const AboutUsStack = styled(Stack)`
  @media ${device.mobileXL} {
    &.css-1vkfs68-MuiStack-root {
      flex-direction: column;
    }
    .MuiBox-root {
      margin-left: 0;
    }
  }
`;
const Paragraph = styled(Typography)`
  &.css-ahj2mt-MuiTypography-root {
    max-width: 842px;
    margin: 0 auto;
    padding: 0 15px 15px;
    @media ${device.mobileXL} {
      &.justify {
        text-align: justify;
      }
    }
  }
`;
const Heading4 = styled.h4`
  color: ${Colors.blueHeading};
  font-size: ${FontSizes.root};
  padding: 15px;
  margin: 0;
  font-weight: normal;
`;
const IconBox = styled(Box)`
  text-align: center;
  @media ${device.mobileXL} {
    width: 100%;
  }
`;
const Image = styled.img`
  max-width: 100%;
`;
const ImgBox = styled.div`
  display: inline-block;
  padding: 32px;
  margin: 0px auto;
  border-radius: 10px;
  box-shadow: 0px 8px 4px 0 rgb(47 95 225 / 15%);
`;

import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import forgotPasswordSaga from './saga';
import { ForgotPasswordState } from './types';
import { RequestStatus } from 'constants/API';

export const initialState: ForgotPasswordState = {
  forgotPasswordStatus: RequestStatus.IDLE,
  resetPasswordStatus: RequestStatus.IDLE,
  checkExpiredLinkForgotPasswordStatus: RequestStatus.IDLE,
  changePasswordStatus: RequestStatus.IDLE,
  accessToken: '',
};

const slice = createSlice({
  name: 'forgot_password',
  initialState,
  reducers: {
    forgotPasswordRequest: (state, action: PayloadAction<any>) => {
      if (action) state.forgotPasswordStatus = RequestStatus.REQUESTING;
    },
    forgotPasswordSuccess: (state, action: PayloadAction<any>) => {
      if (action) {
        state.forgotPasswordStatus = RequestStatus.SUCCESS;
      }
    },
    forgotPasswordFail: (state, action: PayloadAction<any>) => {
      if (action) state.forgotPasswordStatus = RequestStatus.ERROR;
    },
    checkExpiredLinkForgotPasswordRequest: (
      state,
      action: PayloadAction<any>,
    ) => {
      if (action)
        state.checkExpiredLinkForgotPasswordStatus = RequestStatus.REQUESTING;
    },
    checkExpiredLinkForgotPasswordSuccess: (
      state,
      action: PayloadAction<any>,
    ) => {
      if (action) {
        state.checkExpiredLinkForgotPasswordStatus = RequestStatus.SUCCESS;
      }
    },
    checkExpiredLinkForgotPasswordFail: (state, action: PayloadAction<any>) => {
      if (action)
        state.checkExpiredLinkForgotPasswordStatus = RequestStatus.ERROR;
    },
    resetPasswordRequest: (state, action: PayloadAction<any>) => {
      if (action) state.resetPasswordStatus = RequestStatus.REQUESTING;
    },
    resetPasswordSuccess: (state, action: PayloadAction<any>) => {
      if (action) {
        state.resetPasswordStatus = RequestStatus.SUCCESS;
      }
    },
    resetPasswordFail: (state, action: PayloadAction<any>) => {
      state.resetPasswordStatus = RequestStatus.ERROR;
    },
    changePasswordRequest: (state, action: PayloadAction<any>) => {
      if (action) state.changePasswordStatus = RequestStatus.REQUESTING;
    },
    changePasswordSuccess: (state, action: PayloadAction<any>) => {
      if (action) {
        state.changePasswordStatus = RequestStatus.SUCCESS;
      }
    },
    changePasswordFail: (state, action: PayloadAction<any>) => {
      state.changePasswordStatus = RequestStatus.ERROR;
    },
    resetForgotPasswordStatus: state => {
      state.forgotPasswordStatus = RequestStatus.IDLE;
      state.resetPasswordStatus = RequestStatus.IDLE;
    },
    resetChangePasswordRequestStatus: state => {
      state.changePasswordStatus = RequestStatus.IDLE;
    },
  },
});

export default slice.actions;

export const useForgotPasswordSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: forgotPasswordSaga });
  return { actions: slice.actions };
};

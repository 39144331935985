import React, { useEffect } from 'react';

// UI
import {
  Dialog,
  DialogTitle,
  Typography,
  DialogContent,
  TextField,
  DialogActions,
  Button,
  Stack,
  Box,
  IconButton,
  Grid,
  Autocomplete,
  FormLabel,
} from '@mui/material';

import { LoadingIndicator } from 'app/components/LoadingIndicator';
import { Close as CloseIcon } from '@mui/icons-material';
import { TagComponent } from 'app/components/TagComponent';

// Redux & Sagas
import { useDispatch, useSelector } from 'react-redux';
import { RequestStatus } from 'constants/API';
import { useTimesheetSlice } from '../slice';
import { selectTimesheet } from '../slice/selectors';

import { ITimesheet } from '../slice/types';

// Library
import { useForm } from 'react-hook-form';
import { selectTag } from 'app/pages/TagPage/slice/selectors';
import _ from 'lodash';
import { selectGlobal } from 'app/pages/GlobalContainer/slice/selectors';
import moment from 'moment';
import { ICommonDataSchema } from 'types/common';
import { APPROVED_TASK_STATUS, REQUESTING_TASK_STATUS } from 'constants/common';
import { PartialLoader } from 'app/components/PartialLoader';

interface iTaskLogDialog {
  open: boolean;
  onClose: Function;
  currentDate: moment.Moment;
  data: any;
}

export default function TaskLogDialog(props: iTaskLogDialog) {
  const dispatch = useDispatch();
  const [isEditMode, setIsEditMode] = React.useState(false);
  const { data, onClose, currentDate } = props;
  const [selectedProject, setSelectedProject] = React.useState<any>();
  // projects for select
  const [projects, setProjects] = React.useState<any>([]);
  const [selectedTags, setSelectedTags] = React.useState<ICommonDataSchema[]>();

  const {
    userSessionData: { profile },
  } = useSelector(selectGlobal);
  const {
    actions: {
      updateTimesheetRequest,
      addTimesheetRequest,
      getAssignProjectsRequest,
      resetGetAssignProjectStatus,
    },
  } = useTimesheetSlice();
  const tagSlice = useSelector(selectTag);

  const {
    reset,
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  const timesheetSlice = useSelector(selectTimesheet);
  const { assignProjects, getAssignProjectsStatus } = timesheetSlice;

  const isLoading = getAssignProjectsStatus === RequestStatus.REQUESTING;

  useEffect(
    () => () => {
      dispatch(resetGetAssignProjectStatus());
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    ['componentWillUnMount'],
  );

  useEffect(() => {
    setIsEditMode(data?.id ? true : false);
  }, [data]);

  useEffect(() => {
    if (getAssignProjectsStatus === RequestStatus.SUCCESS) {
      let newProjects = assignProjects ? [...assignProjects] : [];
      if (data?.id || data?.project?.id) {
        // check if project is not active => add old project of task to list project
        const foundProject = assignProjects?.find(
          v => v.id === data?.project?.id,
        );
        if (foundProject) {
          setSelectedProject({ ...foundProject });
        } else {
          const newSelectedProject = {
            ...data?.project,
            // add this status to disable button update
            status: APPROVED_TASK_STATUS,
          };
          newProjects = [...newProjects, { ...newSelectedProject }];
          // set new selected project to disable button update
          setSelectedProject({ ...newSelectedProject });
        }
      }
      setProjects(newProjects);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAssignProjectsStatus]);

  useEffect(() => {
    dispatch(
      // param date is report_date in calendar mode, currentDate in view mode
      getAssignProjectsRequest({
        date: data?.report_date
          ? data?.report_date
          : currentDate.format('YYYY-MM-DD'),
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    reset({
      description: data?.description,
      hours: data?.hours || data?.duration,
      project: data?.project?.name,
    });
    setSelectedProject(data?.project);
    setSelectedTags(data?.tags || []);
  }, [isEditMode, data, reset]);

  const onSave = _formData => {
    let formData = {
      ..._formData,
      report_date: data?.report_date
        ? data?.report_date
        : currentDate.format('YYYY-MM-DD'),
      member: { id: profile.id },
      project: _.pick(selectedProject, 'id'),
      tags: selectedTags,
    };
    if (data?.id) {
      dispatch(
        updateTimesheetRequest({
          objectId: data?.id,
          formData,
        }),
      );
    } else {
      dispatch(addTimesheetRequest(formData));
    }
  };

  return (
    <Dialog open={props.open} maxWidth="md" fullWidth scroll="paper">
      <PartialLoader open={isLoading} />
      <DialogTitle style={{ borderBottom: '1px solid #F0F0F0' }}>
        <Stack direction="row" justifyContent="center" alignItems="center">
          <Typography
            variant="h5"
            component="div"
            style={{ textTransform: 'uppercase' }}
          >
            {(isEditMode ? 'Update' : 'Create New') + ' Task'}
          </Typography>
          <IconButton
            onClick={() => onClose()}
            style={{ position: 'absolute', right: '24px' }}
          >
            <CloseIcon />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent dividers>
        <Grid
          container
          mb={2}
          pt={2}
          spacing={2}
          component="form"
          id="task_form"
          onSubmit={handleSubmit(onSave)}
        >
          <Grid item xs={7}>
            <Grid container>
              <Grid item xs={12}>
                <FormLabel>Task Description</FormLabel>
                <TextField
                  multiline
                  rows={9}
                  size="small"
                  margin="dense"
                  type="text"
                  fullWidth
                  variant="outlined"
                  error={errors.description ? true : false}
                  {...register<keyof ITimesheet>('description', {
                    required: 'This is required.',
                    maxLength: 255,
                  })}
                  helperText={errors.description?.message}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={5}>
            <Grid container spacing={'10px'}>
              <Grid item xs={12}>
                <FormLabel>Project</FormLabel>
                <Autocomplete
                  style={{ marginTop: '8px' }}
                  value={selectedProject}
                  isOptionEqualToValue={(option, value) => {
                    return value?.id && value?.id === option?.id ? true : false;
                  }}
                  renderOption={(props, option) => (
                    <Box component="li" {...props} key={option.id}>
                      {option?.name}
                    </Box>
                  )}
                  options={projects || []}
                  autoSelect
                  getOptionLabel={it => it?.name || ''}
                  onChange={(e, newValue) => {
                    setSelectedProject(newValue);
                    setValue('project', newValue?.name);
                  }}
                  renderInput={params => {
                    return (
                      <TextField
                        {...params}
                        size="small"
                        variant="outlined"
                        error={errors.project ? true : false}
                        {...register<keyof ITimesheet>('project', {
                          required: 'This is required.',
                        })}
                        helperText={errors.project?.message}
                      />
                    );
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <FormLabel>Tag</FormLabel>
                <Autocomplete
                  value={selectedTags}
                  isOptionEqualToValue={(option, value) => {
                    return value?.id && value?.id === option?.id ? true : false;
                  }}
                  multiple
                  limitTags={4}
                  size="small"
                  options={tagSlice?.tagList?.data || []}
                  filterSelectedOptions
                  getOptionLabel={it => it.name}
                  onChange={(e, newValue, reason) => {
                    setSelectedTags(newValue);
                  }}
                  renderTags={(value, getTagProps) => {
                    return value.map((option: any, index: number) => (
                      <TagComponent
                        data={{ name: option.name, color: option.color }}
                        {...getTagProps({ index })}
                      />
                    ));
                  }}
                  renderInput={params => (
                    <TextField
                      {...params}
                      size="small"
                      margin="dense"
                      fullWidth
                      variant="outlined"
                      error={errors.tags ? true : false}
                      {...register<keyof ITimesheet>('tags')}
                      helperText={errors.tags?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <FormLabel>Duration</FormLabel>
                <TextField
                  size="small"
                  margin="dense"
                  fullWidth
                  variant="outlined"
                  inputProps={{
                    type: 'number',
                    inputMode: 'numeric',
                    step: 0.1,
                    min: 0.1,
                    max: 20,
                  }}
                  error={errors.hours ? true : false}
                  {...register<keyof ITimesheet>('hours', {
                    required: 'This is required.',
                    min: 0,
                  })}
                  helperText={errors.hours?.message}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose()} variant="outlined">
          Cancel
        </Button>
        <Button
          color="primary"
          type="submit"
          form="task_form"
          variant="contained"
          disabled={
            timesheetSlice.updateTimesheetStatus === RequestStatus.REQUESTING ||
            timesheetSlice.addTimesheetStatus === RequestStatus.REQUESTING ||
            // disabled_update only occur when edit task in calendar mode
            data?.disabled_update ||
            // in case update task
            (data?.id && !selectedProject?.status) ||
            // in case update or add task
            selectedProject?.status === APPROVED_TASK_STATUS ||
            selectedProject?.status === REQUESTING_TASK_STATUS
          }
        >
          {(timesheetSlice.updateTimesheetStatus === RequestStatus.REQUESTING ||
            timesheetSlice.addTimesheetStatus === RequestStatus.REQUESTING) && (
            <Typography component="span" mr={1}>
              <LoadingIndicator small />
            </Typography>
          )}
          {isEditMode ? 'Update' : 'Create'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

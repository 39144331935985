import { Box, Stack } from '@mui/material';
import { selectMember } from 'app/pages/MemberPage/slice/selectors';
import { ReactComponent as HoursThisMonthIcon } from 'assets/icons/report/hoursThisMonth.svg';
import { ReactComponent as ProjectsIcon } from 'assets/icons/report/projects.svg';
import { ReactComponent as TotalHoursIcon } from 'assets/icons/report/totalHours.svg';
import React from 'react';
// Redux & Sagas
import { useSelector } from 'react-redux';
// UI
import styled from 'styled-components/macro';
import { SummaryInfo } from '../SummaryInfo';

export default function AssignInfo(props: { totalProjects: number }) {
  const { memberEfforts } = useSelector(selectMember);

  return (
    <Container>
      <Stack mt={3} direction="row" spacing={4.5}>
        <SummaryInfo
          icon={<ProjectsIcon />}
          title={'Total Projects'}
          data={`${props.totalProjects} projects`}
        ></SummaryInfo>

        <SummaryInfo
          icon={<TotalHoursIcon />}
          title={'Total Hours'}
          hour={memberEfforts?.total_hours || 0}
        ></SummaryInfo>

        <SummaryInfo
          icon={<HoursThisMonthIcon />}
          title={'Hours This Month'}
          hour={memberEfforts?.total_hours_this_month || 0}
        ></SummaryInfo>

        <SummaryInfo
          icon={<HoursThisMonthIcon />}
          title={'Tasks this month'}
          data={`${memberEfforts?.total_tasks_this_month || 0} tasks`}
        ></SummaryInfo>
      </Stack>
    </Container>
  );
}

const Container = styled(Box)``;

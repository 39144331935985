import {
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { AvatarMember } from 'app/components/Avatar';
import * as React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';

export default function MemberListTable({ ...props }) {
  const { memberList, fromSharePage, uuid } = props;

  return (
    <TableContainer component={Paper} sx={{ boxShadow: 'none' }}>
      <StyledTable stickyHeader className={fromSharePage ? 'font12' : 'font14'}>
        <TableHead>
          <TableRow>
            <TableCell width={60}>No</TableCell>
            <TableCell width={200}>Team</TableCell>
            <TableCell width={300}>Name</TableCell>
            <TableCell width={200}>Role</TableCell>
            <TableCell width={120}>Efforts</TableCell>
            <TableCell width={120}></TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {memberList?.map((item, idx) => (
            <TableRow key={idx}>
              <TableCell>{idx + 1}</TableCell>
              <TableCell>{item.teams[0]?.name}</TableCell>
              <TableCell>
                <Stack direction="row" alignItems="center">
                  <AvatarMember
                    title={item?.member_name}
                    avatar={item?.avatar}
                    member_id={item?.id}
                    size={40}
                  />
                  <Typography component="span" pl={1}>
                    {item?.member_name}
                  </Typography>
                </Stack>
              </TableCell>
              <TableCell>{item?.role_name}</TableCell>
              <TableCell>
                {item?.effort_per_month ? `${item?.effort_per_month}h` : ''}
              </TableCell>
              <TableCell>
                <Link
                  to={
                    fromSharePage
                      ? `/cv/${item.member}/${uuid}/`
                      : `/cv/${item.member}/`
                  }
                >
                  View skills
                </Link>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </StyledTable>
    </TableContainer>
  );
}

const StyledTable = styled(Table)`
  .MuiTableCell-head {
    background-color: #fafafa;
    text-transform: capitalize;
  }
  .MuiTableCell-root {
    font-weight: 400;
    .MuiTypography-root {
      font-size: 0.875rem;
    }
  }
  .MuiTableCell-root.MuiTableCell-body {
    padding: 8px;
  }
  &.font12 {
    .MuiTableCell-root,
    .MuiTypography-root {
      font-size: 12px;
    }
  }

  .MuiTableCell-head {
    border-top: 1px solid #d9d9d9;
    border-bottom: 1px solid #d9d9d9;
    font-weight: 500;
    &:first-child {
      border-left: 1px solid #d9d9d9;
    }
    &:last-child {
      border-right: 1px solid #d9d9d9;
    }
  }
  .MuiTableBody-root .MuiTableRow-root {
    .MuiTableCell-root {
      &:first-child {
        text-align: center;
        border-left: 1px solid #d9d9d9;
      }
      &:last-child {
        border-right: 1px solid #d9d9d9;
      }
    }
  }

  * {
    color: black;
  }
  a {
    color: #437dff;
    white-space: nowrap;
  }
`;

import React, { useEffect } from 'react';
// UI
import { Helmet } from 'react-helmet-async';

import { Box, IconButton, Stack, TextField, Typography } from '@mui/material';

import { Wrapper, Content } from 'app/components/Wrapper';
import { Container } from 'app/components/Container';

// Redux & Sagas
import { useDispatch, useSelector } from 'react-redux';
import { useGlobalSlice } from 'app/pages/GlobalContainer/slice';
import Path from 'config/clientPath';
import { useMemberSlice } from '../MemberPage/slice';
import { selectMember } from '../MemberPage/slice/selectors';
import { selectProject } from '../ProjectPage/slice/selectors';
import { useLocation } from 'react-router';
import { Loader } from 'app/components/Loader';
import { checkIsRequesting } from 'utils/helper';
import { selectGlobal } from '../GlobalContainer/slice/selectors';
import MemberReport from 'app/components/MemberReport';
import { RequestApproveTask } from './components/RequestApproveTask';
import { ReactComponent as CalendarIcon } from 'assets/icons/calendar.svg';
import { DatePicker } from '@mui/lab';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import styled from 'styled-components/macro';
import moment from 'moment';
import { selectTimesheet } from '../TimesheetPage/slice/selectors';
import { useTimesheetSlice } from '../TimesheetPage/slice';
import queryString from 'query-string';
import { useHistory } from 'react-router-dom';

export function TimesheetReportPage() {
  const dispatch = useDispatch();

  const location = useLocation();
  const { setBreadcumbs } = useGlobalSlice().actions;

  const [month, setMonth] = React.useState<moment.Moment | null>(null);
  const history = useHistory();

  const { getProjectListStatus } = useSelector(selectProject);
  const { getMemberEffortStatus } = useSelector(selectMember);
  const {
    actions: { getMemberInfoRequest },
  } = useMemberSlice();

  const { getMemberWorkReportStatus } = useSelector(selectMember);
  const { requestApprovalStatus, cancelRequestStatus, getApprovalListStatus } =
    useSelector(selectTimesheet);

  const {
    actions: { resetTimesheetRequestStatus },
  } = useTimesheetSlice();

  const {
    userSessionData: { profile },
  } = useSelector(selectGlobal);

  const isLoading = checkIsRequesting([
    getMemberWorkReportStatus,
    getProjectListStatus,
    getMemberEffortStatus,
    requestApprovalStatus,
    cancelRequestStatus,
    getApprovalListStatus,
  ]);

  const reloadData = () => {
    dispatch(getMemberInfoRequest(profile?.id));
  };

  useEffect(
    () => () => {
      dispatch(resetTimesheetRequestStatus());
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    ['componentWillUnMount'],
  );

  const changeMonthByUrl = () => {
    const params = queryString.parse(window.location.search);
    const newMonth = params?.month ? moment(params?.month) : moment();
    setMonth(newMonth);
  };

  useEffect(() => {
    changeMonthByUrl();
    dispatch(
      setBreadcumbs([
        { title: 'Timesheets', path: Path.TIMESHEET },
        { title: 'Report' },
      ]),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    changeMonthByUrl();
    reloadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  const changeRoute = tempMonth => {
    let params = queryString.parse(window.location.search);
    let newParams = { ...params, month: tempMonth.format('yyyy-MM') };
    newParams = params?.page ? { ...newParams, page: 1 } : { ...newParams };
    const qs = queryString.stringify(newParams);
    history.push(`${Path.TIMESHEET_REPORT}?${qs}`);
  };

  const onChangeMonth = diff_month => {
    let tempMonth = month || moment();
    tempMonth = tempMonth.clone().add(diff_month, 'month');
    changeRoute(tempMonth);
  };

  return (
    <>
      <Helmet>
        <title>Timesheet Report</title>
        <meta name="description" content="Palette" />
      </Helmet>
      <Container>
        <Wrapper>
          <Loader open={isLoading} />
          <Content spacing={2}>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
            >
              <Typography variant="h6" component="h6">
                Report
              </Typography>
            </Box>
            <Stack direction="row" alignItems="center" spacing={'5px'}>
              <CalendarIcon />
              <Box>Month:</Box>
              <WrapDateRange>
                <IconButton>
                  <ChevronLeft
                    onClick={() => {
                      onChangeMonth(-1);
                    }}
                  />
                </IconButton>
                <DatePicker
                  inputFormat="YYYY/MM"
                  views={['month', 'year']}
                  value={month}
                  onChange={newValue => {
                    changeRoute(newValue);
                  }}
                  renderInput={params => <TextField {...params} size="small" />}
                />
                <IconButton>
                  <ChevronRight
                    onClick={() => {
                      onChangeMonth(+1);
                    }}
                  />
                </IconButton>
              </WrapDateRange>
            </Stack>
            <MemberReport
              month={month}
              type="owner"
              memberId={profile?.id}
            ></MemberReport>
            <RequestApproveTask month={month}></RequestApproveTask>
          </Content>
        </Wrapper>
      </Container>
    </>
  );
}

const WrapDateRange = styled(Box)`
  & .MuiOutlinedInput-root {
    width: 130px;
  }
`;

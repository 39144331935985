import React from 'react';

// UI
import {
  Box,
  Stack,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  TextField,
  InputAdornment,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';

import {
  Search as SearchIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,
} from '@mui/icons-material';

// Redux & Sagas
import { IProject } from '../slice/types';

// Library
import { useForm, Controller } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import Path from 'config/clientPath';
import { ReactComponent as ViewModeListIcon } from 'assets/icons/project/table.svg';
import { ReactComponent as ViewModeUserIcon } from 'assets/icons/project/account.svg';

interface IFilterBarProps {
  currentProjectTab: string | null;
  handleChangeProjectTab: any;
}

export function FilterBar(props: IFilterBarProps) {
  const { handleSubmit, register, reset, control, watch } = useForm();
  const { currentProjectTab, handleChangeProjectTab } = props;
  const searchForm = React.useRef<HTMLFormElement>();
  const history = useHistory();

  const handSearch = _formData => {
    const params = new URLSearchParams(window.location.search);
    const formData = params.get('page') ? { ..._formData, page: 1 } : _formData;
    formData.tab = params.get('tab');
    const qs = Object.keys(formData)
      .map(key => (formData[key] ? key + '=' + formData[key] : ''))
      .filter(o => o)
      .join('&');
    history.push(`${Path.PROJECTS}?${qs}`);
  };

  React.useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    reset({
      project_type: params.get('project_type') || '',
      status: params.get('status') || '',
      name: params.get('name') || '',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (name === 'project_type' || name === 'status') {
        requestSubmit();
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  const requestSubmit = () => {
    setTimeout(() => searchForm.current?.requestSubmit(), 10);
  };

  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      component="form"
      method="GET"
      ref={searchForm}
      onSubmit={handleSubmit(handSearch)}
    >
      <Stack direction="row" alignItems="center" spacing={1}>
        <InputLabel>Type: </InputLabel>

        <FormControl>
          <Controller
            control={control}
            name="project_type"
            render={({ field }) => {
              const { onBlur, onChange, value } = field;
              return (
                <Select
                  fullWidth
                  displayEmpty
                  size="small"
                  variant="standard"
                  onBlur={onBlur}
                  onChange={onChange}
                  value={`${value}`}
                  IconComponent={KeyboardArrowDownIcon}
                >
                  <MenuItem value={''}>All</MenuItem>
                  <MenuItem value="1">LABO</MenuItem>
                  <MenuItem value="2">Project Base</MenuItem>
                </Select>
              );
            }}
          />
        </FormControl>

        <InputLabel>Status: </InputLabel>
        <FormControl>
          <Controller
            control={control}
            name="status"
            render={({ field }) => {
              const { onBlur, onChange, value } = field;
              return (
                <Select
                  fullWidth
                  displayEmpty
                  size="small"
                  variant="standard"
                  onBlur={onBlur}
                  onChange={onChange}
                  value={`${value}`}
                  IconComponent={KeyboardArrowDownIcon}
                >
                  <MenuItem value={''}>All</MenuItem>
                  <MenuItem value="1">Active</MenuItem>
                  <MenuItem value="2">Deactive</MenuItem>
                </Select>
              );
            }}
          />
        </FormControl>
      </Stack>

      <Stack direction="row" spacing={2} alignItems="center">
        <InputLabel>View mode:</InputLabel>
        <ToggleButtonGroup
          value={currentProjectTab}
          exclusive
          onChange={handleChangeProjectTab}
        >
          <ToggleButton value="LIST_TAB" aria-label="table">
            <ViewModeListIcon />
          </ToggleButton>
          <ToggleButton value="USER_TAB" aria-label="account">
            <ViewModeUserIcon />
          </ToggleButton>
        </ToggleButtonGroup>
        <TextField
          type="search"
          placeholder="Search keyword"
          aria-label="Search"
          size="small"
          sx={{
            background: '#fff',
          }}
          onKeyUp={event => {
            if (event.key === 'Enter') {
              requestSubmit();
            }
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          {...register<keyof IProject>('name')}
        />
      </Stack>
    </Box>
  );
}

export default FilterBar;

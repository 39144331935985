import {
  Box,
  Button,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { AvatarMember } from 'app/components/Avatar';
import { ConfirmDialog } from 'app/components/ConfirmDialog';
import { Container } from 'app/components/Container';
import { Loader } from 'app/components/Loader';
import { MemberLink } from 'app/components/MemberLink';
import { Content, Wrapper } from 'app/components/Wrapper';
import { useGlobalSlice } from 'app/pages/GlobalContainer/slice';
import { RequestStatus } from 'constants/API';
import _ from 'lodash';
import moment from 'moment';
import queryString from 'query-string';
import React, { useEffect, useState } from 'react';
// UI
import { Helmet } from 'react-helmet-async';
import { FormProvider, useForm } from 'react-hook-form';
// Redux & Sagas
import { useDispatch, useSelector } from 'react-redux';
// Library
import { useLocation } from 'react-router';
import styled from 'styled-components';
import { checkIsRequesting, getHalfYearMonths } from 'utils/helper';
import { useWindowDimensions } from 'utils/useWindowDimensions';
import { useAssignmentSummarySlice } from '../AssignmentSummary/slice';
import { selectAssignmentSummary } from '../AssignmentSummary/slice/selectors';
import { useTeamSlice } from '../TeamPage/slice';
import { selectTeam } from '../TeamPage/slice/selectors';
import FilterBar from './components/FilterBar';

export function CommitEffort() {
  const dispatch = useDispatch();
  const commitEffortForm = useForm();
  const { width: pageWidth } = useWindowDimensions(0);

  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = commitEffortForm;

  const { setSuccessMessages, setErrorMessages, setBreadcumbs } =
    useGlobalSlice().actions;

  const {
    actions: { getTeamListRequest },
  } = useTeamSlice();
  const { getTeamListStatus } = useSelector(selectTeam);

  const location = useLocation();

  const [currentMonth, setCurrentMonth] = useState(moment().startOf('month'));

  const [months, setMonths] = useState<any>([]);
  const [showSaveConfirmDialog, setSaveShowConfirmDialog] = useState(false);

  const {
    actions: {
      getAssignmentSummaryRequest,
      updateCommitEffortRequest,
      resetAssignmentSummaryStatus,
    },
  } = useAssignmentSummarySlice();

  const {
    getAssignmentSummaryStatus,
    assignmentSummary,
    updateCommitEffortStatus,
  } = useSelector(selectAssignmentSummary);

  useEffect(() => {
    dispatch(setBreadcumbs([{ title: 'Assignment' }]));
    dispatch(
      getTeamListRequest({
        page: 1,
      }),
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const _months = getHalfYearMonths(currentMonth);
    setMonths(_months);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(currentMonth)]);

  useEffect(() => {
    if (getAssignmentSummaryStatus === RequestStatus.SUCCESS) {
      let newData = {};
      assignmentSummary.map((v, k) => {
        newData = { ...newData, [k]: v };
        return '';
      });
      commitEffortForm.reset(newData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAssignmentSummaryStatus]);

  useEffect(
    () => () => {
      dispatch(resetAssignmentSummaryStatus());
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    ['componentWillUnMount'],
  );

  useEffect(() => {
    if (updateCommitEffortStatus === RequestStatus.SUCCESS) {
      setSaveShowConfirmDialog(false);
      dispatch(setSuccessMessages(['Update commit effort success']));
    }
    if (updateCommitEffortStatus === RequestStatus.ERROR) {
      dispatch(setErrorMessages(['Oops.. Something went wrong!']));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateCommitEffortStatus]);

  const reloadData = () => {
    const params = queryString.parse(window.location.search);
    dispatch(
      getAssignmentSummaryRequest({
        from: _.head(months).dashMonthString,
        to: _.last(months).dashMonthString,
        team: params?.team || '',
        search: params?.search || '',
      }),
    );
  };

  useEffect(() => {
    if (!_.isEmpty(months)) reloadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(months)]);

  useEffect(() => {
    if (!_.isEmpty(months)) reloadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  const onSubmit = () => {
    if (_.isEmpty(errors)) {
      setSaveShowConfirmDialog(true);
    }
  };
  const handleSaveData = () => {
    let data = getValues();
    data = _.reduce(
      data,
      (result, v) => {
        let commitData = { ...v };
        if (!_.isEmpty(v.teams)) {
          const commitHours = _.reduce(
            commitData.data,
            (accumulate, value, k) => {
              return {
                ...accumulate,
                [k]: _.toInteger(value.commit_effort),
              };
            },
            {},
          );
          commitData = {
            ...commitData,
            team_id: _.head(v.teams).id,
            data: commitHours,
          };

          result.push(commitData);
        }
        return result;
      },
      [],
    );

    dispatch(updateCommitEffortRequest(data));
  };

  const isLoading = checkIsRequesting([
    getTeamListStatus,
    getAssignmentSummaryStatus,
  ]);

  return (
    <>
      <Helmet>
        <title>Commit Effort</title>
        <meta name="description" content="Assignment Summary" />
      </Helmet>
      <Container>
        <Wrapper>
          <Loader open={isLoading} />
          <ConfirmDialog
            title={'Save Commit Effort Data'}
            content={'Do you want save this commit effort data ?'}
            open={showSaveConfirmDialog}
            onCancel={() => {
              setSaveShowConfirmDialog(false);
            }}
            onAgree={() => {
              handleSaveData();
              // setSaveShowConfirmDialog(false);
              // handleSubmit(handleSaveData)();
            }}
          ></ConfirmDialog>
          <Content spacing={2}>
            <Box display="flex" flexDirection="row" alignItems="center">
              <Typography variant="h6" component="h6">
                Commit Effort
              </Typography>
              <Stack style={{ marginLeft: 'auto' }} direction="row" spacing={2}>
                <Button
                  // type="submit"
                  // form="commit_effort_form"
                  variant="contained"
                  color="primary"
                  size="large"
                  onClick={() => {
                    // setSaveShowConfirmDialog(true);
                    handleSubmit(onSubmit)();
                    // commitEffortForm.handleSubmit(handleSaveData);
                  }}
                >
                  <Box ml={0.5}>Save</Box>
                </Button>
              </Stack>
            </Box>

            <FilterBar
              months={months}
              changeMonth={diff_month => {
                setCurrentMonth(currentMonth.clone().add(diff_month, 'month'));
              }}
            />
            <FormProvider {...commitEffortForm}>
              <WrapTable
                // onSubmit={commitEffortForm.handleSubmit(handleSaveData)}
                component="form"
                id="commit_effort_form"
              >
                <TableContainer component={Paper}>
                  <Table stickyHeader>
                    <TableHead>
                      <TableRow>
                        <TableCell sx={{ minWidth: 60 }} width={60}>
                          No
                        </TableCell>
                        <TableCell sx={{ minWidth: 90 }} width={100}>
                          Team
                        </TableCell>
                        <TableCell sx={{ minWidth: 160 }}>Name</TableCell>
                        {months.map(v => {
                          return (
                            <TableCell
                              sx={{ minWidth: 90 }}
                              align="center"
                              key={v.slashMonthString}
                              className={
                                v.slashMonthString ===
                                moment().format('YYYY/MM')
                                  ? 'current_month'
                                  : 'other_month'
                              }
                              width={pageWidth < 1600 ? 100 : 150}
                            >
                              {v.slashMonthString}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {assignmentSummary?.map((item, idx) => {
                        const isDisplayedInput = !_.isEmpty(item.teams);
                        return (
                          <TableRow key={item.id}>
                            <TableCell>{idx + 1}</TableCell>
                            <TableCell>{_.head(item.teams)?.name}</TableCell>
                            <TableCell>
                              <Stack
                                direction="row"
                                spacing={'6px'}
                                alignItems="center"
                              >
                                <AvatarMember
                                  avatar={`${item?.avatar || ''}`}
                                  title={`${item?.full_name || ''}`}
                                  sx={{ width: 24, height: 24 }}
                                ></AvatarMember>
                                <MemberLink
                                  member_id={item?.id}
                                  member_name={item?.full_name}
                                ></MemberLink>
                              </Stack>
                            </TableCell>
                            {months.map(v => {
                              const fieldName = `${idx}.data.${v.dashMonthString}.commit_effort`;
                              return (
                                <TableCell width={120} align="center">
                                  <TextField
                                    disabled={isDisplayedInput ? false : true}
                                    size="small"
                                    margin="dense"
                                    fullWidth
                                    variant="outlined"
                                    inputProps={{
                                      type: 'number',
                                      min: 0,
                                    }}
                                    error={
                                      _.get(errors, fieldName) ? true : false
                                    }
                                    {...register(fieldName, {
                                      required: 'This is required.',
                                      min: 0,
                                    })}
                                    helperText={
                                      _.get(errors, fieldName)?.message
                                    }
                                  />
                                </TableCell>
                              );
                            })}
                          </TableRow>
                        );
                      })}
                      {_.isEmpty(assignmentSummary) && (
                        <TableRow>
                          <TableCell colSpan={9}>No data</TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </WrapTable>
            </FormProvider>
          </Content>
        </Wrapper>
      </Container>
    </>
  );
}

const WrapTable = styled(Box)`
  table {
    tr th {
      color: rgb(0 0 0 / 85%);
      &.current_month {
        color: #437dff;
        font-weight: bold !important;
      }
      &.other_month {
        color: rgb(0 0 0 / 45%);
        background: #d9d9d9;
      }
    }
    tr th,
    tr td {
    }
    tr td {
      color: rgb(0 0 0 / 85%);
    }
    input[type='number']::-webkit-inner-spin-button,
    input[type='number']::-webkit-outer-spin-button {
      -webkit-appearance: none;
    }
  }
`;

/* eslint-disable react-hooks/exhaustive-deps */
import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import React from 'react';
import { useDispatch } from 'react-redux';
import { ToastContent, ToastOptions } from 'react-toastify';

type Messages = Array<string | undefined>;
type FuncDisplay = (
  content: ToastContent,
  options?: ToastOptions<{}> | undefined,
) => void;

const useDisplayMessages = (
  messages: Messages,
  displayFunc: FuncDisplay,
  setMessagesFunc: ActionCreatorWithPayload<(string | undefined)[], string>,
) => {
  const dispatch = useDispatch();
  React.useEffect(() => {
    if (messages.length) {
      messages.map(message => displayFunc(message));
      // reset the messages back to empty after displaying all
      dispatch(setMessagesFunc([]));
    }
  }, [messages.length]);
};

export default useDisplayMessages;

import CloseIcon from '@mui/icons-material/Close';
import MenuIcon from '@mui/icons-material/Menu';
import { ReactComponent as PaletteLogo } from 'assets/palette_logo.svg';
import * as React from 'react';
import { MouseEventHandler, useState } from 'react';
import styled from 'styled-components';
import Navigation from './Navigation';
import { Colors, device } from './Variables';

interface MobileIconProps {
  onClick: MouseEventHandler<HTMLDivElement> & (() => void);
  open: boolean;
}

export default function Header() {
  const [open, setOpen] = useState<boolean>(false);

  const toggleMenu = () => {
    setOpen(!open);
  };
  return (
    <HeaderContainer>
      <a href="/">
        <PaletteLogo />
      </a>
      <Navigation open={open} isMobile={false} update={toggleMenu} />
      <HamburgerIconContainer onClick={toggleMenu} open={open}>
        <MenuIcon />
      </HamburgerIconContainer>
      <CloseWrapper open={open} onClick={toggleMenu}>
        <CloseIcon />
      </CloseWrapper>
    </HeaderContainer>
  );
}

const HeaderContainer = styled.div`
  display: flex;
  padding: 48px 80px;
  justify-content: space-between;
  align-items: center;
  img {
    max-width: 280px;
  }

  .MuiSvgIcon-root {
    width: 0;
    opacity: 0;
    color: var(--white);
    cursor: pointer;
    transition: width 0.3s ease;
    :hover {
      opacity: 0.8;
    }

    @media ${device.tablet} {
      width: 33px;
      height: 33px;
      opacity: 1;
    }
  }
  @media ${device.tablet} {
    padding: 24px;
    svg {
      max-width: 154px;
    }
  }
`;

const HamburgerIconContainer = styled.div<MobileIconProps>`
  position: fixed;
  top: 40px;
  right: 24px;
  width: 33px;
  height: 33px;
  z-index: 8;
  display: none;
  svg.MuiSvgIcon-root {
    color: ${Colors.blueHeading};
  }
  @media only screen and ${device.tablet} {
    display: ${props => (!props.open ? 'block' : 'none')};
  }
`;
const CloseWrapper = styled(HamburgerIconContainer)`
  svg.MuiSvgIcon-root {
    color: ${Colors.darkBlue};
  }
  @media ${device.tablet} {
    display: ${props => (props.open ? 'block' : 'none')};
  }
`;

/**
 *
 * GlobalContainer
 *
 */
import React, { memo } from 'react';
import { LoadingBackgound } from './styles';
import Toast from 'app/components/ToastMessage';
import { ToastContainer } from 'react-toastify';
import useDisplayMessagesFactory from './useDispayMessages';
import { LoadingIndicator } from 'app/components/LoadingIndicator';

// Redux & Sagas
import { useSelector } from 'react-redux';
import { useGlobalSlice } from './slice';
import { selectGlobal } from './slice/selectors';
interface Props {
  children: React.ReactNode;
}

export const GlobalContainer = memo(({ children }: Props) => {
  const {
    actions: {
      setErrorMessages,
      setInfoMessages,
      setSuccessMessages,
      setWarningMessages,
    },
  } = useGlobalSlice();

  const {
    errorMessages,
    infoMessages,
    warningMessages,
    successMessages,
    isGlobalLoading,
  } = useSelector(selectGlobal);

  useDisplayMessagesFactory(errorMessages, Toast.error, setErrorMessages);
  useDisplayMessagesFactory(warningMessages, Toast.warn, setWarningMessages);
  useDisplayMessagesFactory(infoMessages, Toast.inform, setInfoMessages);
  useDisplayMessagesFactory(successMessages, Toast.success, setSuccessMessages);

  return (
    <>
      {isGlobalLoading && (
        <LoadingBackgound>
          <LoadingIndicator />
        </LoadingBackgound>
      )}
      <ToastContainer />
      {children}
    </>
  );
});

import React from 'react';

// UI
import {
  Autocomplete,
  FormControl,
  FormLabel,
  IconButton,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Select,
  TableCell,
  TableRow,
  TextField,
} from '@mui/material';
import { Box } from '@mui/system';
import { ICommonDataSchema } from 'types/common';
import APIs from 'APIs';
import { DatePicker } from '@mui/lab';
import { IMemberProjectSchema } from 'app/pages/MemberPage/slice/types';
import { ReactComponent as SaveIcon } from 'assets/icons/button/save.svg';
import { ReactComponent as EditIcon } from 'assets/icons/button/edit.svg';
import { useGlobalSlice } from 'app/pages/GlobalContainer/slice';
import { ReactComponent as DeleteIcon } from 'assets/icons/button/delete.svg';
import { RequestStatus } from 'constants/API';
// Redux & Sagas
import { useSelector, useDispatch } from 'react-redux';
import { selectMember } from 'app/pages/MemberPage/slice/selectors';
import { selectProject } from 'app/pages/ProjectPage/slice/selectors';
import { useMemberSlice } from 'app/pages/MemberPage/slice';
// Library
import _ from 'lodash';
import { Controller, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { ASSIGNMENT_TYPE } from 'constants/common';
import UISettings from 'styles/setting';
import moment from 'moment';
import { convertDate } from 'utils/moment';
import { ProjectLink } from 'app/components/ProjectLink';

interface RouteParams {
  id: string;
}
interface IAssigmentRowProps {
  prjIndex: number;
  assignment: IMemberProjectSchema;
}

export default function AssigmentRow(props: IAssigmentRowProps) {
  const {
    register,
    control,
    setValue,
    getValues,
    watch,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const dispatch = useDispatch();
  const params = useParams<RouteParams>();
  const { project_meta_data } = useSelector(selectProject);
  const defaultProject = { id: -1, name: '' };
  const commonMapping = (arr: any): ICommonDataSchema[] => {
    return arr.map(it => _.pick(it, ['id', 'name']));
  };
  const [selectedProject, setSelectedProject] =
    React.useState<any>(defaultProject);
  const {
    project_role_metadata,
    updateAssignmentInfoStatus,
    createAssignmentInfoStatus,
    deleteAssignmentInfoStatus,
  } = useSelector(selectMember);
  const [isEditMode, setIsEditMode] = React.useState<boolean>(false);
  const {
    actions: {
      updateAssignmentInfoRequest,
      createAssignmentInfoSuccess,
      deleteAssignmentInfoRequest,
      resetAssignmentInfoRequestStatus,
    },
  } = useMemberSlice();
  const { setSuccessMessages } = useGlobalSlice().actions;
  React.useEffect(() => {
    reset(props.assignment);
    if (!getValues(`project`)) {
      setIsEditMode(true);
    }
    setSelectedProject({
      id: getValues('project'),
      name: getValues('project_name'),
    });
    setValue('project_id', getValues('project'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.assignment]);

  const handSaveAssignment = async () => {
    await handleSubmit(() => {})();
    if (_.isEmpty(errors)) {
      setIsEditMode(false);
      let objectId = getValues(`id`);
      setValue(`project_id`, _.get(selectedProject, 'id'));
      const dataUpdate = {
        ...getValues(),
        member: params.id,
        project: _.get(selectedProject, 'id'),
        start_date: convertDate(getValues(`start_date`)),
        end_date: getValues(`end_date`)
          ? convertDate(getValues(`end_date`))
          : null,
      };
      if (objectId) {
        dispatch(
          updateAssignmentInfoRequest({
            objectId: objectId,
            formData: dataUpdate,
          }),
        );
      } else {
        //this will break the saga rule, but there no other way to get the assignment id
        APIs.createAssignmentInfo(dataUpdate).then(res => {
          setValue(`id`, res.data.id);
        });
        dispatch(createAssignmentInfoSuccess({}));
      }
    }
  };

  React.useEffect(() => {
    if (updateAssignmentInfoStatus === RequestStatus.SUCCESS) {
      dispatch(setSuccessMessages(['Assignment update successful!']));
      dispatch(resetAssignmentInfoRequestStatus());
    }
    if (createAssignmentInfoStatus === RequestStatus.SUCCESS) {
      dispatch(setSuccessMessages(['Assignment create successful!']));
      dispatch(resetAssignmentInfoRequestStatus());
    }
    if (deleteAssignmentInfoStatus === RequestStatus.SUCCESS) {
      dispatch(setSuccessMessages(['Assignment delete successful!']));
      dispatch(resetAssignmentInfoRequestStatus());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    updateAssignmentInfoStatus,
    createAssignmentInfoStatus,
    deleteAssignmentInfoStatus,
  ]);

  const handRemoveAssignment = async () => {
    //handle submit to show the validate eror
    // await handleSubmit(() => {})();
    let objectId = getValues(`id`);
    if (objectId) {
      dispatch(deleteAssignmentInfoRequest(objectId));
    }
  };

  const endDate = getValues([`end_date`]).toString();
  return (
    <TableRow>
      <TableCell sx={{ minWidth: 50 }} width={50}>
        {props.prjIndex + 1}
      </TableCell>
      <TableCell sx={{ minWidth: 150 }}>
        {isEditMode ? (
          <FormControl fullWidth>
            <Autocomplete
              value={selectedProject}
              defaultValue={defaultProject}
              isOptionEqualToValue={(option, value) => {
                return value?.id && value?.id === option?.id ? true : false;
              }}
              renderOption={(props, option) => (
                <Box component="li" {...props} key={`prj_${option.id}`}>
                  {option?.name}
                </Box>
              )}
              options={[
                { ...defaultProject },
                ...commonMapping(project_meta_data ?? []),
              ]}
              getOptionLabel={it => it?.name || ''}
              onChange={(e, newValue) => {
                setSelectedProject(newValue);
                setValue(`project`, newValue?.name);
              }}
              renderInput={params => {
                return (
                  <TextField
                    {...params}
                    size="small"
                    variant="outlined"
                    error={errors.project ? true : false}
                    helperText={errors.project?.message}
                  />
                );
              }}
            />
          </FormControl>
        ) : (
          <FormLabel>
            <ProjectLink project_id={getValues([`project_id`])}></ProjectLink>
          </FormLabel>
        )}
      </TableCell>

      <TableCell width={160} sx={{ minWidth: 90 }}>
        {isEditMode ? (
          <FormControl fullWidth error={'type' in (errors || {})}>
            <Controller
              control={control}
              name={`type`}
              rules={{ required: true }}
              render={({ field }) => {
                const { onBlur, onChange, value } = field;
                return (
                  <Select
                    fullWidth
                    displayEmpty
                    size="small"
                    defaultValue=""
                    onBlur={onBlur}
                    onChange={onChange}
                    value={`${value}`}
                  >
                    {_.map(ASSIGNMENT_TYPE, (v, k) => (
                      <MenuItem key={v} value={`${k}`}>
                        {v}
                      </MenuItem>
                    ))}
                  </Select>
                );
              }}
            />
          </FormControl>
        ) : (
          <FormLabel>
            {ASSIGNMENT_TYPE[_.toInteger(getValues([`type`])).toString()]}
          </FormLabel>
        )}
      </TableCell>

      <TableCell width={180} sx={{ minWidth: 110 }}>
        {isEditMode ? (
          <FormControl fullWidth error={'role' in (errors || {})}>
            <Controller
              control={control}
              name={`role`}
              rules={{ required: true }}
              render={({ field }) => {
                const { onBlur, onChange, value } = field;
                return (
                  <Select
                    fullWidth
                    displayEmpty
                    size="small"
                    defaultValue=""
                    onBlur={onBlur}
                    onChange={onChange}
                    value={`${value}`}
                  >
                    {project_role_metadata?.map(it => (
                      <MenuItem key={it.name} value={`${it.id}`}>
                        {it.name}
                      </MenuItem>
                    ))}
                  </Select>
                );
              }}
            />
          </FormControl>
        ) : (
          <FormLabel>
            {_.get(
              _.find(project_role_metadata, {
                id: _.toInteger(getValues([`role`])),
              }),
              'name',
            )}
          </FormLabel>
        )}
      </TableCell>

      <TableCell width={140} sx={{ minWidth: 80 }}>
        {isEditMode ? (
          <OutlinedInput
            size="small"
            fullWidth
            endAdornment={<InputAdornment position="end">hours</InputAdornment>}
            error={_.get(errors, `effort_per_month`) ? true : false}
            {...register(`effort_per_month`, {
              required: true,
              validate: value => !isNaN(value),
              maxLength: 10,
            })}
          />
        ) : (
          <FormLabel>{getValues([`effort_per_month`]) + 'h'}</FormLabel>
        )}
      </TableCell>

      <TableCell width={180} sx={{ minWidth: 140 }}>
        {isEditMode ? (
          <FormControl fullWidth>
            <Controller
              control={control}
              name={`start_date`}
              defaultValue={null}
              rules={{
                required: true,
              }}
              render={({ field: { onChange, value } }) => (
                <DatePicker
                  value={value}
                  onChange={onChange}
                  inputFormat={UISettings.dateFormat}
                  renderInput={params => (
                    <TextField
                      {...params}
                      fullWidth
                      hiddenLabel
                      size="small"
                      error={'start_date' in (errors || {})}
                    />
                  )}
                />
              )}
            />
          </FormControl>
        ) : (
          <FormLabel>
            {moment(getValues([`start_date`]).toString()).format(
              UISettings.dateFormat,
            )}
          </FormLabel>
        )}
      </TableCell>
      <TableCell width={180} sx={{ minWidth: 140 }}>
        {isEditMode ? (
          <FormControl fullWidth error={'end_date' in (errors || {})}>
            <Controller
              control={control}
              name={`end_date`}
              defaultValue={null}
              rules={{
                validate: value => {
                  return !value || moment(value) > moment(watch(`start_date`));
                },
              }}
              render={({ field: { onChange, value } }) => {
                return (
                  <DatePicker
                    value={value}
                    onChange={onChange}
                    inputFormat={UISettings.dateFormat}
                    renderInput={params => (
                      <TextField
                        {...params}
                        fullWidth
                        hiddenLabel
                        size="small"
                        error={'end_date' in (errors || {})}
                      />
                    )}
                  />
                );
              }}
            />
          </FormControl>
        ) : (
          <FormLabel>
            {endDate ? moment(endDate).format(UISettings.dateFormat) : '-'}
          </FormLabel>
        )}
      </TableCell>
      <TableCell width={120} sx={{ minWidth: 120 }}>
        {isEditMode ? (
          <IconButton onClick={handSaveAssignment}>
            <SaveIcon />
          </IconButton>
        ) : (
          <IconButton onClick={() => setIsEditMode(true)}>
            <EditIcon />
          </IconButton>
        )}
        {!isEditMode && (
          <IconButton onClick={handRemoveAssignment}>
            <DeleteIcon />
          </IconButton>
        )}
      </TableCell>
    </TableRow>
  );
}

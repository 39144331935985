import {
  KeyboardArrowDown as KeyboardArrowDownIcon,
  Search as SearchIcon,
} from '@mui/icons-material';
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { AvatarMember } from 'app/components/Avatar';
import _ from 'lodash';
import * as React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';

export default function MemberDialogForm({ ...props }) {
  const { onClose } = props as {
    onClose: () => React.Dispatch<React.SetStateAction<boolean>>;
  };

  const {
    onSaveData,
    isCreateProposal,
    selectedMembers,
    projectRole,
    allMembers,
    teams,
  } = props;

  const form = useForm();
  const {
    handleSubmit,
    control,
    reset,
    getValues,
    formState: { errors },
  } = form;

  React.useEffect(() => {
    setSelected(selectedMembers?.map(it => it.member));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMembers]);

  /*
   ===== Start of search handling =====
  */
  const [filteredMember, setFilteredMember] = React.useState<any>(allMembers);
  const [selectedRole, setSelectedRole] = React.useState<string>('');
  const [selectedName, setSelectedName] = React.useState<string>('');
  const [selectedTeam, setSelectedTeam] = React.useState<string>('');

  React.useEffect(() => {
    let filteredMembers = allMembers;
    if (selectedName) {
      filteredMembers = allMembers.filter(
        it =>
          selectedName &&
          (it.full_name?.toLowerCase().includes(selectedName.toLowerCase()) ||
            it.email?.toLowerCase().includes(selectedName.toLowerCase())),
      );
    }
    if (selectedRole) {
      const idxs = getValues('member_role')
        .map((it, idx) =>
          _.toInteger(it) === _.toInteger(selectedRole) ? idx : -1,
        )
        .filter(it => it > -1);
      filteredMembers = idxs.map(it =>
        _.find(allMembers, {
          id: _.toInteger(it),
        }),
      );
    }

    if (selectedTeam) {
      filteredMembers = filteredMembers.filter(
        it => _.toInteger(it.teams[0]?.id) === _.toInteger(selectedTeam),
      );
    }

    setFilteredMember(filteredMembers);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedName, selectedRole, selectedTeam]);

  const onChangeRole = e => {
    setSelectedRole(e.target.value);
  };

  const onChangeTeam = e => {
    setSelectedTeam(e.target.value);
  };

  const changeNameOrEmail = e => {
    setSelectedName(e.target.value);
  };
  const clearInternalState = () => {
    setSelectedRole('');
    setSelectedTeam('');
    setSelectedName('');
    reset();
  };

  /*
    ===== End of search handling =====
  */

  /*
    ===== Start of selected member form handling ============
  */
  const onSave = formData => {
    const data = handleData(selected, formData);
    onSaveData(data);
    onClose();
    setTimeout(() => {
      clearInternalState();
    }, 3);
  };

  const closeDialog = () => {
    onClose();
    setTimeout(() => {
      clearInternalState();
      setSelected(selectedMembers ? selectedMembers.map(it => it.member) : []);
    }, 3);
  };

  const handleData = (arr, obj) =>
    arr.map(it => {
      const idx = _.findIndex(
        allMembers,
        a => _.toInteger(a.id) === _.toInteger(it),
      );
      return {
        id: it,
        member: it,
        member_name: allMembers[idx].full_name,
        role_name: _.get(
          _.find(projectRole, {
            id: _.toInteger(obj.member_role[it]),
          }),
          'name',
        ),
        role: obj.member_role[it] ? _.toInteger(obj.member_role[it]) : null,
        effort_per_month: obj.effort_per_month[it]
          ? _.toInteger(obj.effort_per_month[it])
          : 0,
        teams: allMembers[idx].teams,
      };
    });

  /** inner state - selected rows */
  const [selected, setSelected] = React.useState<any>(
    selectedMembers?.map(it => it.member) ?? [],
  );

  const handleSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = e.target;
    if (checked) {
      setSelected(_.uniq([...selected, _.toInteger(value)].filter(o => o)));
    } else {
      const remaining = _.uniq(
        selected.filter((item: number) => item !== _.toInteger(value)),
      );
      setSelected(remaining);
    }
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = allMembers.map(n => n.id);
      setSelected(newSelecteds);
    } else {
      setSelected([]);
    }
  };

  const rowCount = allMembers?.length ?? 0;
  /*
    ===== End of selected member form handling ============
  */

  return (
    <MemberProposalFormContainer
      open={props.open}
      maxWidth="md"
      fullWidth
      scroll="paper"
    >
      <DialogTitle>
        <Typography align="center" fontSize="24px">
          {isCreateProposal ? 'Add Members' : 'Update Members'}
        </Typography>
      </DialogTitle>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        padding={2}
      >
        <Stack
          direction="row"
          alignItems="center"
          spacing={1}
          className="search-input"
        >
          <InputLabel>Role: </InputLabel>
          <Select
            fullWidth
            displayEmpty
            size="small"
            variant="standard"
            onChange={onChangeRole}
            IconComponent={KeyboardArrowDownIcon}
          >
            <MenuItem value="">All</MenuItem>
            {projectRole?.map(it => (
              <MenuItem key={`m_f_${it.id}`} value={it.id}>
                {it.name}
              </MenuItem>
            ))}
          </Select>
          <InputLabel>Team: </InputLabel>
          <Select
            fullWidth
            displayEmpty
            size="small"
            variant="standard"
            onChange={onChangeTeam}
            IconComponent={KeyboardArrowDownIcon}
          >
            <MenuItem value="">All</MenuItem>
            {_.map(teams, (it, idx) => (
              <MenuItem key={idx} value={it.id}>
                {it.name}
              </MenuItem>
            ))}
          </Select>
        </Stack>

        <Stack direction="row" spacing={2} alignItems="center">
          <TextField
            type="search"
            placeholder="Name, Email"
            aria-label="Search"
            size="small"
            sx={{
              background: '#fff',
            }}
            onKeyUp={changeNameOrEmail}
            onChange={changeNameOrEmail}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </Stack>
      </Box>
      <DialogContent>
        <Grid
          container
          mb={2}
          pt={2}
          spacing={2}
          component="form"
          id="member-proposal-form"
          onSubmit={handleSubmit(onSave)}
        >
          <Grid item xs={12}>
            <TableContainer component={Paper} sx={{ boxShadow: 'none' }}>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell width={60}>
                      <Checkbox
                        color="primary"
                        indeterminate={
                          selected?.length > 0 && selected?.length < rowCount
                        }
                        checked={rowCount > 0 && selected?.length === rowCount}
                        onChange={handleSelectAllClick}
                        inputProps={{
                          'aria-label': 'select all member',
                        }}
                      />
                    </TableCell>
                    <TableCell width={100}>Team</TableCell>
                    <TableCell width={300}>Name</TableCell>
                    <TableCell width={150}>Role</TableCell>
                    <TableCell width={120}>View skills</TableCell>
                    <TableCell width={120}>Efforts</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {filteredMember?.map((item, idx) => {
                    return (
                      <TableRow key={item.id}>
                        <TableCell>
                          <FormControl fullWidth>
                            <Controller
                              name={`member.${item.id}`}
                              render={({ field }) => {
                                return (
                                  <Checkbox
                                    {...field}
                                    value={item.id}
                                    checked={_.includes(selected, item.id)}
                                    onChange={handleSelect}
                                  />
                                );
                              }}
                              control={control}
                            />
                          </FormControl>
                        </TableCell>
                        <TableCell>{item?.teams[0]?.name}</TableCell>
                        <TableCell>
                          <Stack direction="row" alignItems="center">
                            <AvatarMember
                              title={item?.full_name}
                              avatar={item?.avatar}
                              member_id={item?.id}
                              size={30}
                            />
                            <Typography component="span" pl={1}>
                              {item?.full_name}
                            </Typography>
                          </Stack>
                        </TableCell>
                        <TableCell>
                          <FormControl fullWidth>
                            <Controller
                              control={control}
                              name={`member_role.${item.id}`}
                              rules={{
                                required: _.includes(selected, item.id),
                              }}
                              defaultValue={
                                _.includes(selected, item.id)
                                  ? _.get(
                                      _.find(selectedMembers, {
                                        member: _.toInteger(item.id),
                                      }),
                                      'role',
                                    )
                                  : item.role
                              }
                              render={({ field }) => {
                                const { onBlur, onChange, value } = field;
                                return (
                                  <Select
                                    fullWidth
                                    displayEmpty
                                    size="small"
                                    onBlur={onBlur}
                                    onChange={onChange}
                                    value={`${value}`}
                                    error={
                                      errors?.member_role &&
                                      errors.member_role[item.id]
                                    }
                                  >
                                    {projectRole?.map(it => (
                                      <MenuItem
                                        key={it.name}
                                        value={`${it.id}`}
                                      >
                                        {it.name}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                );
                              }}
                            />
                          </FormControl>
                        </TableCell>
                        <TableCell>
                          <Link to={`/cv/${item.id}/`}>View skills</Link>
                        </TableCell>
                        <TableCell>
                          {_.includes(selected, item.id) ? (
                            <FormControl fullWidth>
                              <Controller
                                control={control}
                                name={`effort_per_month.${item.id}`}
                                rules={{
                                  required: _.includes(selected, item.id),
                                }}
                                defaultValue={
                                  _.get(
                                    _.find(selectedMembers, {
                                      member: _.toInteger(item.id),
                                    }),
                                    'effort_per_month',
                                  ) ?? ''
                                }
                                render={({ field }) => {
                                  const { onBlur, onChange, value } = field;
                                  return (
                                    <OutlinedInput
                                      size="small"
                                      fullWidth
                                      onBlur={onBlur}
                                      onChange={onChange}
                                      value={`${value}`}
                                      error={
                                        errors?.effort_per_month &&
                                        errors.effort_per_month[item.id]
                                      }
                                      endAdornment={
                                        <InputAdornment position="end">
                                          h
                                        </InputAdornment>
                                      }
                                    />
                                  );
                                }}
                              />
                            </FormControl>
                          ) : (
                            ''
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog} variant="outlined">
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          type="submit"
          form="member-proposal-form"
        >
          Save
        </Button>
      </DialogActions>
    </MemberProposalFormContainer>
  );
}
const MemberProposalFormContainer = styled(Dialog)`
  .MuiDialogContent-root {
    padding: 0px 24px !important;
  }
  .MuiAvatar-root {
    width: 30px;
    height: 30px;
  }
  .MuiTypography-h4 {
    font-size: 20px;
  }
  .MuiTableCell-root * {
    font-size: 0.875rem;
  }
  *,
  .search-input .MuiInputLabel-root {
    text-overflow: inherit;
    overflow: visible;
  }
  a {
    color: #437dff;
  }
  svg {
    width: 18px;
    height: 18px;
  }
`;

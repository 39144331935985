import { Helmet } from 'react-helmet-async';

import { Box, Container, Stack, Typography } from '@mui/material';
import * as React from 'react';
import { Link } from 'react-router-dom';

import { ReactComponent as Logo } from 'assets/logo.svg';
import LoginPageBackground from 'assets/page-login-bg.svg';

import styled from 'styled-components';
import MemberListTable from '../TeamProposalEdit/components/MemberListTable';
import { Loader } from 'app/components/Loader';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTeamProposalSlice } from 'app/pages/TeamProposal/slice';
import { useEffect } from 'react';
import { selectTeamProposal } from '../TeamProposal/slice/selectors';
import { checkIsRequesting } from 'utils/helper';

interface RouteParams {
  id: string;
}
export default function ProposalSharingPage() {
  const dispatch = useDispatch();
  const params = useParams<RouteParams>();

  const { getTeamProposalRequest, resetTeamProposalRequestStatus } =
    useTeamProposalSlice().actions;

  const { teamProposal, getTeamProposalStatus } =
    useSelector(selectTeamProposal);

  useEffect(() => {
    dispatch(getTeamProposalRequest(params.id));
    return () => {
      dispatch(resetTeamProposalRequestStatus());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isLoading = checkIsRequesting([getTeamProposalStatus]);
  return (
    <>
      <Helmet>
        <title>Palette</title>
        <meta name="description" content="Palette" />
        <style>{`
          body, #root{
            background-image: url(${LoginPageBackground});
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
          }
        `}</style>
      </Helmet>
      <ContainerWrapper className="sharing">
        <Loader open={isLoading} />
        {!isLoading && (
          <Box className="sharing-container" padding={5} pb={4} mt={20} mb={50}>
            <Stack>
              <Box>
                <Link to={`/`} className="right-pos">
                  <Logo />
                </Link>
                <Typography variant="h2" component="h3" fontWeight={500} mb={2}>
                  Team Proposal Detail
                </Typography>

                <Stack direction="row" alignItems="center" spacing={5} pt={2}>
                  <Typography variant="h3" fontWeight={500} width="200px">
                    Customer:
                  </Typography>

                  <Typography variant="h3" fontWeight={400}>
                    {teamProposal && teamProposal?.customer_name}
                  </Typography>
                </Stack>
                {teamProposal?.project && (
                  <Stack direction="row" alignItems="center" spacing={5} pt={2}>
                    <Typography variant="h3" fontWeight={500} width="200px">
                      Project
                    </Typography>
                    <Typography variant="h3" fontWeight={400}>
                      {teamProposal?.project}
                    </Typography>
                  </Stack>
                )}

                <Stack direction="row" alignItems="center" spacing={5} pt={2}>
                  <Typography variant="h3" fontWeight={500} width="200px">
                    Title
                  </Typography>
                  <Typography variant="h3" fontWeight={400}>
                    {teamProposal && teamProposal.title}
                  </Typography>
                </Stack>
                <Stack direction="row" alignItems="center" spacing={5} pt={2}>
                  <Typography variant="h3" fontWeight={500} width="200px">
                    Period
                  </Typography>
                  {teamProposal?.start_date && teamProposal?.start_date && (
                    <Typography variant="h3" fontWeight={400} width="200px">
                      {`${teamProposal?.start_date}  ~  ${teamProposal?.end_date}`}
                    </Typography>
                  )}
                </Stack>

                {teamProposal?.members && teamProposal?.members.length > 0 && (
                  <>
                    <Typography variant="h2" fontWeight={500} mt={4} mb={4}>
                      Member
                    </Typography>
                    <MemberListTable
                      mt={5}
                      mb={5}
                      memberList={teamProposal?.members}
                      uuid={params.id}
                      fromSharePage={true}
                    />
                  </>
                )}
              </Box>
            </Stack>
          </Box>
        )}
      </ContainerWrapper>
    </>
  );
}

const ContainerWrapper = styled(Container)`
  position: relative;
  max-width: 800px !important;
  .right-pos {
    position: absolute;
    right: 40px;
    top: 20px;
    display: block;
    svg {
      display: block;
    }
  }
  .sharing-container {
    background: white;
  }
  .MuiTypography-root {
    font-size: 16px;
  }
  .MuiTypography-h3 {
    font-size: 14px;
  }
  .MuiTableContainer-root {
    margin-bottom: 50px;
  }

  * {
    color: black;
  }
`;

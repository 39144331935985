import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import profileSaga from './saga';
import { ProfileState } from './types';
import { RequestStatus } from 'constants/API';

export const initialState: ProfileState = {
  getProfileStatus: RequestStatus.IDLE,
  info: null,
};

const slice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    getProfileRequest: (state, action: PayloadAction<any>) => {
      if (action) state.getProfileStatus = RequestStatus.REQUESTING;
    },
    getProfileSuccess: (state, action: PayloadAction<any>) => {
      if (action) {
        state.getProfileStatus = RequestStatus.SUCCESS;
        state.info = action.payload;
      }
    },
    getProfileFail: (state, action: PayloadAction<any>) => {
      if (action) state.getProfileStatus = RequestStatus.ERROR;
    },
  },
});

export const { getProfileRequest, getProfileSuccess, getProfileFail } =
  slice.actions;

export const useProfileSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: profileSaga });
  return { actions: slice.actions };
};

import * as React from 'react';

// UI
import { IconButton, TextField, Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import styledComponent from 'styled-components/macro';

import { ChevronLeft, ChevronRight } from '@mui/icons-material';

import DateAdapter from '@mui/lab/AdapterMoment';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';

import PickersDay, { PickersDayProps } from '@mui/lab/PickersDay';

// Library
import moment from 'moment';
import _ from 'lodash';
import { useEffect } from 'react';

type CustomPickerDayProps = PickersDayProps<Date> & {
  dayIsBetween: boolean;
  isFirstDay: boolean;
  isLastDay: boolean;
};

interface IProps {
  onChange: Function;
  value: moment.Moment;
  ref: any;
  viewMode: string;
}

const CustomPickersDay = styled(PickersDay, {
  shouldForwardProp: prop =>
    prop !== 'dayIsBetween' && prop !== 'isFirstDay' && prop !== 'isLastDay',
})<CustomPickerDayProps>(({ theme, dayIsBetween, isFirstDay, isLastDay }) => ({
  ...(dayIsBetween && {
    borderRadius: 0,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    '&:hover, &:focus': {
      backgroundColor: theme.palette.primary.dark,
    },
  }),
  ...(isFirstDay && {
    borderRadius: 0,
    borderTopLeftRadius: '50%',
    borderBottomLeftRadius: '50%',
  }),
  ...(isLastDay && {
    borderRadius: 0,
    borderTopRightRadius: '50%',
    borderBottomRightRadius: '50%',
  }),
})) as React.ComponentType<CustomPickerDayProps>;

const WeekPicker = React.forwardRef((props: IProps, ref) => {
  const [value, setValue] = React.useState<Date | null>(new Date());
  const { viewMode } = props;

  const renderWeekPickerDay = (
    date: Date,
    selectedDates: Array<Date | null>,
    pickersDayProps: PickersDayProps<Date>,
  ) => {
    if (!value) {
      return <PickersDay {...pickersDayProps} />;
    }

    const start = moment(value).startOf('week');
    const end = moment(value).endOf('week');

    const dayIsBetween = moment(date).isBetween(start, end, null, '[]');
    const isFirstDay = moment(date).isSame(start, 'day');
    const isLastDay = moment(date).isSame(end, 'day');

    return (
      <CustomPickersDay
        {...pickersDayProps}
        disableMargin
        dayIsBetween={dayIsBetween}
        isFirstDay={isFirstDay}
        isLastDay={isLastDay}
      />
    );
  };

  const getDayOnNextOrPrev = () => {
    if (viewMode === 'list') {
      return 'week';
    } else if (viewMode === 'calendar') {
      return 'month';
    }
  };

  const onNext = () => {
    const dayOnNextOrPrev = getDayOnNextOrPrev();
    const dayOnNext = moment(value).add(1, dayOnNextOrPrev);
    setValue(dayOnNext.toDate());
    _.invoke(props, 'onChange', dayOnNext);
  };

  const onPrev = () => {
    const dayOnNextOrPrev = getDayOnNextOrPrev();
    const dayOnPrev = moment(value).subtract(1, dayOnNextOrPrev);
    setValue(dayOnPrev.toDate());
    _.invoke(props, 'onChange', dayOnPrev);
  };

  React.useImperativeHandle(ref, () => {
    return {
      handleNextWeek: onNext,
      handlePrevWeek: onPrev,
    };
  });

  useEffect(() => {
    setValue(props.value.toDate());
  }, [props.value]);

  return (
    <WeekPickerContainer
      display="flex"
      alignItems="center"
      justifyContent="center"
      ml={4}
    >
      <LocalizationProvider dateAdapter={DateAdapter}>
        <IconButton onClick={onPrev}>
          <ChevronLeft />
        </IconButton>
        <IconButton onClick={onNext}>
          <ChevronRight />
        </IconButton>
        <DatePicker
          value={value}
          onChange={newValue => {
            setValue(newValue);
            _.invoke(props, 'onChange', newValue);
          }}
          renderDay={renderWeekPickerDay}
          renderInput={params => <TextField {...params} size="small" />}
          inputFormat="YYYY/MM/DD"
          disableMaskedInput
        />
      </LocalizationProvider>
    </WeekPickerContainer>
  );
});

const WeekPickerContainer = styledComponent(Box)`
  border: 1px solid #ccc;
  border-radius: 30px;
  padding: 2px;
  .MuiButtonBase-root{
    background: rgba(0, 0, 0, 0.04);
  }
  fieldset{
    border-width: 0px;
  }
`;

export default WeekPicker;

import React from 'react';

// UI
import {
  Stack,
  Box,
  Typography,
  Paper,
  InputLabel,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Pagination,
  TableBody,
} from '@mui/material';
import { convertDate } from 'utils/moment';
import Path from 'config/clientPath';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import queryString from 'query-string';
import _ from 'lodash';
import { TagComponent } from 'app/components/TagComponent';
import { TimeRangeSelectFilter } from 'app/components/TimeRangeSelectFilter';
import { MemberLink } from '../MemberLink';

// Redux & Sagas
import { useDispatch, useSelector } from 'react-redux';
// Library
import { useTimesheetSlice } from 'app/pages/TimesheetPage/slice';
import { selectTimesheet } from 'app/pages/TimesheetPage/slice/selectors';
import { RangeInput } from '@mui/lab/DateRangePicker/RangeTypes';
interface ITaskListProps {
  project: number;
}
interface RouteParams {
  id: string;
}
export default function TaskList(props: ITaskListProps) {
  const dispatch = useDispatch();
  const { getTimesheetRequest } = useTimesheetSlice().actions;
  const { timesheetList } = useSelector(selectTimesheet);
  const [dateFilter, setDateFilter] = React.useState<RangeInput<any>>([
    null,
    null,
  ]);
  const paramsURL = useParams<RouteParams>();

  const location = useLocation();
  const history = useHistory();
  const [page, setPage] = React.useState(1);
  React.useEffect(() => {
    dispatch(getTimesheetRequest({ project: props.project, paging: true }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleChangeDateFilter = newDateFilter => {
    dispatch(
      getTimesheetRequest({
        project: props.project,
        date_start: convertDate(newDateFilter[0]),
        date_end: convertDate(newDateFilter[1]),
        paging: true,
      }),
    );
    setDateFilter(newDateFilter);
  };

  const handleChangePage = (
    event: React.ChangeEvent<unknown> | null,
    newPage: number,
  ) => {
    const params = queryString.parse(window.location.search);
    const qs = queryString.stringify({ ...params, page: +newPage });
    history.push(`${Path.PROJECTS}/edit/${paramsURL.id}?${qs}`);
    setPage(newPage);
  };
  const reloadData = () => {
    const params = new URLSearchParams(window.location.search);
    dispatch(
      getTimesheetRequest({
        project: props.project,
        paging: true,
        page: Math.abs(_.toInteger(params.get('page')) || 1),
      }),
    );
  };
  React.useEffect(() => {
    reloadData();
    const params = new URLSearchParams(window.location.search);
    const newPage = Math.abs(_.toInteger(params.get('page')));
    setPage(newPage ? newPage : 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  return (
    <Stack spacing={2}>
      <Box display="flex" flexDirection="row" justifyContent="space-between">
        <Typography variant="h6" component="h6">
          Task list
        </Typography>
        {/* <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          component="form"
          method="GET"
          // onSubmit={handleSubmit(handSearch)}
        >
          <Stack direction="row" alignItems="center" spacing={1}>
            <InputLabel>Type: </InputLabel>
            <FormControl>
              <Select
                size="small"
                variant="standard"
                displayEmpty
                IconComponent={KeyboardArrowDownIcon}
                // {...register<keyof IProject>('project_type')}
              >
                <MenuItem value={''}>All</MenuItem>
                <MenuItem value={'1'}>LABO</MenuItem>
                <MenuItem value={'2'}>Project Base</MenuItem>
              </Select>
            </FormControl>
            <InputLabel>Status: </InputLabel>
            <FormControl>
              <Select
                size="small"
                variant="standard"
                displayEmpty
                IconComponent={KeyboardArrowDownIcon}
                // {...register<keyof IProject>('status')}
              >
                <MenuItem value={1}>Active</MenuItem>
                <MenuItem value={2}>Deactive</MenuItem>
                <MenuItem value="">All</MenuItem>
              </Select>
            </FormControl>
            <InputLabel>Time: </InputLabel>
            <FormControl>
              <Select
                size="small"
                variant="standard"
                displayEmpty
                IconComponent={KeyboardArrowDownIcon}
                // {...register<keyof IProject>('status')}
              >
                <MenuItem value={1}>Active</MenuItem>
                <MenuItem value={2}>Deactive</MenuItem>
                <MenuItem value="">All</MenuItem>
              </Select>
            </FormControl>
          </Stack>
        </Box> */}
      </Box>
      <Box>
        <Stack direction="row" alignItems="center" spacing={1}>
          <InputLabel
            sx={{
              minWidth: 40,
            }}
          >
            Time:{' '}
          </InputLabel>
          <TimeRangeSelectFilter
            value={dateFilter}
            setValue={newValue => handleChangeDateFilter(newValue)}
          ></TimeRangeSelectFilter>
        </Stack>
      </Box>
      <Paper>
        <TableContainer>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell sx={{ minWidth: 60 }}>No.</TableCell>
                <TableCell sx={{ minWidth: 140 }}>Person</TableCell>
                <TableCell sx={{ minWidth: 120 }}>Task Description</TableCell>
                <TableCell sx={{ minWidth: 90 }}>Tags</TableCell>
                <TableCell sx={{ minWidth: 70 }}>Duration</TableCell>
                <TableCell sx={{ minWidth: 110 }}> Date</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {_.isEmpty(timesheetList?.data) && (
                <TableRow>
                  <TableCell colSpan={6}>No data</TableCell>
                </TableRow>
              )}
              {timesheetList?.data?.map((it, idx) => (
                <TableRow key={idx}>
                  <TableCell>{idx + 1}</TableCell>
                  <TableCell>
                    <MemberLink member_id={it.member.id}></MemberLink>
                  </TableCell>
                  <TableCell>{it.description}</TableCell>
                  <TableCell>
                    <Stack spacing={1}>
                      {it?.tags.map((v, k) => (
                        <TagComponent data={v} key={k} />
                      ))}
                    </Stack>
                  </TableCell>
                  <TableCell>{it.hours}h</TableCell>
                  <TableCell>{it.report_date}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Box padding={2}>
          <Stack spacing={2} alignItems={'flex-end'}>
            <Pagination
              page={page}
              color="primary"
              count={timesheetList?.total_page || 0}
              variant="outlined"
              shape="rounded"
              onChange={handleChangePage}
            />
          </Stack>
        </Box>
      </Paper>
    </Stack>
  );
}

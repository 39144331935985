import { StatusCode } from 'constants/API';
import { call, put, takeLatest } from 'redux-saga/effects';
import APIs from 'APIs';
import _ from 'lodash';

import Slice from '.';
import globalSlice from 'app/pages/GlobalContainer/slice';

function* getMemberListFlow({ payload }) {
  try {
    const response = yield call(APIs.getMemberList, payload);
    if (response.status === StatusCode.SUCCESS_COMMON) {
      if (!payload.paging) {
        yield put({
          type: Slice.getMemberListMetaDataSuccess.type,
          payload: response.data,
        });
      } else {
        yield put({
          type: Slice.getMemberListSuccess.type,
          payload: response.data,
        });
      }
    } else {
      yield put({ type: Slice.getMemberListFail.type });
    }
  } catch (error) {
    yield put({ type: Slice.getMemberListFail.type });
  }
}

function* getMemberInfoFlow({ payload }) {
  try {
    let response;
    if (payload.uuid && payload.id) {
      response = yield call(APIs.getCVInfo, payload);
    } else {
      response = yield call(APIs.getMemberInfo, payload);
    }

    if (response.status === StatusCode.SUCCESS_COMMON) {
      yield put({
        type: Slice.getMemberInfoSuccess.type,
        payload: response.data,
      });
    } else {
      yield put({ type: Slice.getMemberInfoFail.type });
    }
  } catch (error) {
    yield put({ type: Slice.getMemberInfoFail.type });
  }
}

function* getMemberHistoryAssignmentFlow({ payload }) {
  try {
    const response = yield call(APIs.getMemberHistoryAssignment, payload);
    if (response.status === StatusCode.SUCCESS_COMMON) {
      yield put({
        type: Slice.getMemberHistoryAssignmentSuccess.type,
        payload: response.data,
      });
    } else {
      yield put({ type: Slice.getMemberHistoryAssignmentFail.type });
    }
  } catch (error) {
    yield put({ type: Slice.getMemberHistoryAssignmentFail.type });
  }
}
function* getMemberCurrentAssignmentFlow({ payload }) {
  try {
    const response = yield call(APIs.getMemberCurrentAssignment, payload);
    if (response.status === StatusCode.SUCCESS_COMMON) {
      yield put({
        type: Slice.getMemberCurrentAssignmentSuccess.type,
        payload: response.data,
      });
    } else {
      yield put({ type: Slice.getMemberCurrentAssignmentFail.type });
    }
  } catch (error) {
    yield put({ type: Slice.getMemberCurrentAssignmentFail.type });
  }
}

function* createMemberInfoFlow({ payload }) {
  try {
    const formData = new FormData();
    _.forEach(payload, (v, k) => {
      if (k !== 'logo') {
        formData.append(k, v);
      }
    });
    if (_.get(payload, 'logo') && _.get(payload, 'logo.name')) {
      formData.append('logo', payload.logo, payload.logo.name);
    }

    const response = yield call(APIs.createMemberInfo, formData);
    if (response.status === StatusCode.SUCCESS_CREATED) {
      yield put({
        type: Slice.createMemberInfoSuccess.type,
        payload: response.data,
      });
    } else {
      yield put({ type: Slice.createMemberInfoFail.type });
    }
  } catch (error) {
    yield put({ type: Slice.createMemberInfoFail.type });
  }
}

function* updateMemberInfoFlow({ payload }) {
  try {
    const { objectId, formData } = payload;

    const response = yield call(APIs.updateMemberInfo, objectId, formData);
    if (response.status === StatusCode.SUCCESS_COMMON) {
      yield put({
        type: Slice.updateMemberInfoSuccess.type,
        payload: response.data,
      });
    } else {
      const { data, status } = response.response;
      let errorMess = ['Opps..Member update failed!'];
      if (status === StatusCode.BAD_REQUEST) {
        errorMess = Object.keys(data).map(k => {
          return k + ': ' + data[k].join(' ');
        });
      }
      yield put({
        type: globalSlice.setErrorMessages.type,
        payload: errorMess,
      });
      yield put({ type: Slice.updateMemberInfoFail.type });
    }
  } catch (error) {
    yield put({ type: Slice.updateMemberInfoFail.type });
  }
}

function* deleteMemberInfoFlow({ payload }) {
  try {
    const response = yield call(APIs.deleteMemberInfo, payload);
    if (response.status === StatusCode.NO_CONTENT) {
      yield put({
        type: Slice.deleteMemberInfoSuccess.type,
      });
    } else {
      yield put({ type: Slice.deleteMemberInfoFail.type });
    }
  } catch (error) {
    yield put({ type: Slice.deleteMemberInfoFail.type });
  }
}

function* createAssignmentInfoFlow({ payload }) {
  try {
    const response = yield call(APIs.createAssignmentInfo, payload);
    if (response.status === StatusCode.SUCCESS_CREATED) {
      yield put({
        type: Slice.createAssignmentInfoSuccess.type,
        payload: response.data,
      });
    } else {
      yield put({ type: Slice.createAssignmentInfoFail.type });
    }
  } catch (error) {
    yield put({ type: Slice.createAssignmentInfoFail.type });
  }
}

function* updateAssignmentInfoFlow({ payload }) {
  try {
    const { objectId, formData } = payload;
    const response = yield call(APIs.updateAssignmentInfo, objectId, formData);
    if (response.status === StatusCode.SUCCESS_COMMON) {
      yield put({
        type: Slice.updateAssignmentInfoSuccess.type,
        payload: response.data,
      });
    } else {
      const { data, status } = response.response;
      let errorMess = ['Opps..Assignment update failed!'];
      if (status === StatusCode.BAD_REQUEST) {
        errorMess = Object.keys(data).map(k => {
          return k + ': ' + data[k].join(' ');
        });
      }
      yield put({
        type: globalSlice.setErrorMessages.type,
        payload: errorMess,
      });
      yield put({ type: Slice.updateAssignmentInfoFail.type });
    }
  } catch (error) {
    yield put({ type: Slice.updateAssignmentInfoFail.type });
  }
}

function* deleteAssignmentInfoFlow({ payload }) {
  try {
    const response = yield call(APIs.deleteAssignmentInfo, payload);
    if (response.status === StatusCode.NO_CONTENT) {
      yield put({
        type: Slice.deleteAssignmentInfoSuccess.type,
      });
    } else {
      yield put({ type: Slice.deleteAssignmentInfoFail.type });
    }
  } catch (error) {
    yield put({ type: Slice.deleteAssignmentInfoFail.type });
  }
}

function* getSkillListFlow({ payload }) {
  try {
    const response = yield call(APIs.getSkillList, payload);
    if (response.status === StatusCode.SUCCESS_COMMON) {
      yield put({
        type: Slice.getSkillListSuccess.type,
        payload: response.data,
      });
    } else {
      yield put({ type: Slice.getSkillListFail.type });
    }
  } catch (error) {
    yield put({ type: Slice.getSkillListFail.type });
  }
}

function* getLanguageListFlow({ payload }) {
  try {
    const response = yield call(APIs.getLanguageList, payload);
    if (response.status === StatusCode.SUCCESS_COMMON) {
      yield put({
        type: Slice.getLanguageListSuccess.type,
        payload: response.data,
      });
    } else {
      yield put({ type: Slice.getLanguageListFail.type });
    }
  } catch (error) {
    yield put({ type: Slice.getLanguageListFail.type });
  }
}

function* getProjectRoleListFlow({ payload }) {
  try {
    const response = yield call(APIs.getProjectRoleList, payload);
    if (response.status === StatusCode.SUCCESS_COMMON) {
      yield put({
        type: Slice.getProjectRoleListSuccess.type,
        payload: response.data,
      });
    } else {
      yield put({ type: Slice.getProjectRoleListFail.type });
    }
  } catch (error) {
    yield put({ type: Slice.getProjectRoleListFail.type });
  }
}
function* getInviteMemberFlow({ payload }) {
  try {
    const response = yield call(APIs.getInviteMember, payload);
    if (response.status === StatusCode.SUCCESS_COMMON) {
      yield put({
        type: Slice.getInviteMemberSuccess.type,
        payload: response.data,
      });
    } else {
      yield put({ type: Slice.getInviteMemberFail.type });
    }
  } catch (error) {
    yield put({ type: Slice.getInviteMemberFail.type });
  }
}

function* inviteMemberFlow({ payload }) {
  let errorMess = ['Ops..Invite member failed!'];
  try {
    const response = yield call(APIs.inviteMember, payload);
    if (response.status === StatusCode.SUCCESS_COMMON) {
      yield put({
        type: Slice.inviteMemberSuccess.type,
        payload: response.data,
      });
    } else {
      const { data, status } = response.response;
      if (status === StatusCode.BAD_REQUEST) {
        errorMess = Object.keys(data).map(k => {
          return data[k].join(', ');
        });
      }
      yield put({ type: Slice.inviteMemberFail.type, payload: { errorMess } });
    }
  } catch (error) {
    yield put({ type: Slice.inviteMemberFail.type, payload: { errorMess } });
  }
}

function* uploadMemberAvatarFlow({ payload }) {
  try {
    const formData = new FormData();

    if ('avatar' in payload) {
      formData.append(
        'avatar',
        _.get(payload, 'avatar[0]'),
        _.get(payload, 'avatar[0].name'),
      );
    }
    const response = yield call(APIs.uploadMemberAvatar, payload.id, formData);

    if (response.status === StatusCode.SUCCESS_COMMON) {
      yield put({
        type: Slice.uploadMemberAvatarSuccess.type,
        payload: response.data,
      });
    } else {
      yield put({ type: Slice.uploadMemberAvatarFail.type });
    }
  } catch (error) {
    yield put({ type: Slice.uploadMemberAvatarFail.type });
  }
}

function* getMemberWorkReportFlow({ payload }) {
  try {
    const response = yield call(APIs.getWorkDetailReport, payload);
    if (response.status === StatusCode.SUCCESS_COMMON) {
      yield put({
        type: Slice.getMemberWorkReportSuccess.type,
        payload: response.data,
      });
    } else {
      yield put({ type: Slice.getMemberWorkReportFail.type });
    }
  } catch (error) {
    yield put({ type: Slice.getMemberWorkReportFail.type });
  }
}

function* getMemberEffortFlow({ payload }) {
  try {
    const response = yield call(APIs.getWorkEffortReport, payload);
    if (response.status === StatusCode.SUCCESS_COMMON) {
      yield put({
        type: Slice.getMemberEffortSuccess.type,
        payload: response.data,
      });
    } else {
      yield put({ type: Slice.getMemberEffortFail.type });
    }
  } catch (error) {
    yield put({ type: Slice.getMemberEffortFail.type });
  }
}

function* MemberWatcher() {
  yield takeLatest(Slice.getMemberListRequest, getMemberListFlow);
  yield takeLatest(Slice.getMemberInfoRequest, getMemberInfoFlow);
  yield takeLatest(
    Slice.getMemberCurrentAssignmentRequest,
    getMemberCurrentAssignmentFlow,
  );
  yield takeLatest(
    Slice.getMemberHistoryAssignmentRequest,
    getMemberHistoryAssignmentFlow,
  );
  yield takeLatest(Slice.createMemberInfoRequest, createMemberInfoFlow);
  yield takeLatest(Slice.updateMemberInfoRequest, updateMemberInfoFlow);
  yield takeLatest(Slice.deleteMemberInfoRequest, deleteMemberInfoFlow);
  yield takeLatest(Slice.getSkillListRequest, getSkillListFlow);
  yield takeLatest(Slice.createAssignmentInfoRequest, createAssignmentInfoFlow);
  yield takeLatest(Slice.updateAssignmentInfoRequest, updateAssignmentInfoFlow);
  yield takeLatest(Slice.deleteAssignmentInfoRequest, deleteAssignmentInfoFlow);
  yield takeLatest(Slice.getLanguageListRequest, getLanguageListFlow);
  yield takeLatest(Slice.getProjectRoleListRequest, getProjectRoleListFlow);
  yield takeLatest(Slice.getInviteMemberRequest, getInviteMemberFlow);
  yield takeLatest(Slice.inviteMemberRequest, inviteMemberFlow);
  yield takeLatest(Slice.uploadMemberAvatarRequest, uploadMemberAvatarFlow);
  yield takeLatest(Slice.getMemberWorkReportRequest, getMemberWorkReportFlow);
  yield takeLatest(Slice.getMemberEffortRequest, getMemberEffortFlow);
}

export default MemberWatcher;

import { StatusCode } from 'constants/API';
import { call, put, takeLatest } from 'redux-saga/effects';
import APIs from 'APIs';
import Slice from '.';

function* getTagsFlow({ payload }) {
  try {
    const response = yield call(APIs.getTags, payload);
    if (response.status === StatusCode.SUCCESS_COMMON) {
      yield put({
        type: Slice.getTagSuccess.type,
        payload: response.data,
      });
    } else {
      yield put({ type: Slice.getTagFail.type });
    }
  } catch (error) {
    yield put({ type: Slice.getTagFail.type });
  }
}

function* tagWatcher() {
  yield takeLatest(Slice.getTagRequest, getTagsFlow);
}

export default tagWatcher;

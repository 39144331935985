import APIs from 'APIs';
import { StatusCode } from 'constants/API';
import { call, put, takeLatest } from 'redux-saga/effects';
import Slice from '.';

function* getTeamProposalListFlow({ payload }) {
  try {
    const response = yield call(APIs.getTeamProposalList, payload);
    if (response.status === StatusCode.SUCCESS_COMMON) {
      yield put({
        type: Slice.getTeamProposalListSuccess.type,
        payload: response.data,
      });
    } else {
      yield put({ type: Slice.getTeamProposalListFail.type });
    }
  } catch (error) {
    yield put({ type: Slice.getTeamProposalListFail.type });
  }
}

function* createTeamProposalFlow({ payload }) {
  try {
    const response = yield call(APIs.createTeamProposal, payload);
    if (response.status === StatusCode.SUCCESS_CREATED) {
      payload.id = response.data.id;
      yield put({
        type: Slice.createTeamProposalSuccess.type,
        payload: response.data,
      });
    } else {
      yield put({ type: Slice.createTeamProposalFail.type });
    }
  } catch (error) {
    yield put({ type: Slice.createTeamProposalFail.type });
  }
}

function* getTeamProposalFlow({ payload }) {
  try {
    const response = yield call(APIs.getTeamProposal, payload);
    if (response.status === StatusCode.SUCCESS_COMMON) {
      yield put({
        type: Slice.getTeamProposalSuccess.type,
        payload: response.data,
      });
    } else {
      yield put({ type: Slice.getTeamProposalFail.type });
    }
  } catch (error) {
    yield put({ type: Slice.getTeamProposalFail.type });
  }
}

function* updateTeamProposalFlow({ payload }) {
  try {
    const response = yield call(APIs.updateTeamProposal, payload);
    if (response.status === StatusCode.SUCCESS_COMMON) {
      yield put({
        type: Slice.updateTeamProposalSuccess.type,
        payload: response.data,
      });
    } else {
      yield put({ type: Slice.updateTeamProposalFail.type });
    }
  } catch (error) {
    yield put({ type: Slice.updateTeamProposalFail.type });
  }
}

function* deleteTeamProposalFlow({ payload }) {
  try {
    const response = yield call(APIs.deleteTeamProposal, payload);
    if (response.status === StatusCode.NO_CONTENT) {
      yield put({
        type: Slice.deleteTeamProposalSuccess.type,
      });
    } else {
      yield put({ type: Slice.deleteTeamProposalFail.type });
    }
  } catch (error) {
    yield put({ type: Slice.deleteTeamProposalFail.type });
  }
}

function* teamProposalWatcher() {
  yield takeLatest(Slice.getTeamProposalListRequest, getTeamProposalListFlow);
  yield takeLatest(Slice.getTeamProposalRequest, getTeamProposalFlow);
  yield takeLatest(Slice.createTeamProposalRequest, createTeamProposalFlow);
  yield takeLatest(Slice.updateTeamProposalRequest, updateTeamProposalFlow);
  yield takeLatest(Slice.deleteTeamProposalRequest, deleteTeamProposalFlow);
}

export default teamProposalWatcher;

import React from 'react';

// UI
import { Box, Chip } from '@mui/material';

// Library
import styled from 'styled-components/macro';

interface ITagComponent {
  data: {
    name: string;
    color?: string;
  };
}

export function TagComponent({ data, ...rest }: ITagComponent) {
  return (
    <Wrapper>
      <ChipCustom
        {...rest}
        style={{ backgroundColor: data.color }}
        label={data.name}
      />
    </Wrapper>
  );
}

const Wrapper = styled(Box)``;

const ChipCustom = styled(Chip)`
  &.MuiChip-root {
    color: #fff;
    border-radius: 4px;
    height: 24px;
    font-size: 12px;
    font-weight: 400;
    width: fit-content;
    & span {
      padding: 1px 8px;
    }
  }
  &.MuiAutocomplete-tag {
    margin: 0 2px !important;
  }
`;

import React, { useState, useEffect } from 'react';

// UI
import { Helmet } from 'react-helmet-async';
import {
  Box,
  Typography,
  Button,
  Stack,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Paper,
  AvatarGroup,
  Pagination,
} from '@mui/material';
import { Loader } from 'app/components/Loader';
import { AddCircle } from '@mui/icons-material';

import FilterBar from './components/FilterBar';
import { AvatarMember } from 'app/components/Avatar';

import { Container } from 'app/components/Container';
import { Wrapper, Content } from 'app/components/Wrapper';
import Path from 'config/clientPath';
import { ReactComponent as EditIcon } from 'assets/icons/button/edit.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/button/delete.svg';
import { checkIsRequesting } from 'utils/helper';
import { DialogForm } from './components/Form';
import { useLocation } from 'react-router-dom';
// Redux & Sagas
import { useDispatch, useSelector } from 'react-redux';
import { RequestStatus } from 'constants/API';
import { selectTeam } from './slice/selectors';
import { useTeamSlice } from './slice';
import { ITeamSchema } from './slice/types';
import { useGlobalSlice } from 'app/pages/GlobalContainer/slice';

// Library
import { useHistory } from 'react-router-dom';
import { STATUS_LIST, COUNTRY_LIST } from 'constants/common';
import queryString from 'query-string';
import _ from 'lodash';
import { useMemberSlice } from '../MemberPage/slice';
import styled from 'styled-components';

export function TeamPage({ ...props }) {
  const dispatch = useDispatch();
  const location = useLocation();
  const {
    actions: { getProjectRoleListRequest },
  } = useMemberSlice();
  const {
    actions: {
      getTeamListRequest,
      deleteTeamInfoRequest,
      resetTeamInfoRequestStatus,
      getTeamRoleListRequest,
    },
  } = useTeamSlice();
  const {
    listTeam,
    createTeamInfoStatus,
    updateTeamInfoStatus,
    deleteTeamInfoStatus,
    getTeamListStatus,
  } = useSelector(selectTeam);
  const { setBreadcumbs, setSuccessMessages } = useGlobalSlice().actions;
  // dialog
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [page, setPage] = React.useState(1);
  const [showConfirm, setShowConfirm] = useState(false);
  const history = useHistory();
  const [dataItem, setDataItem] = useState<ITeamSchema | null>();

  const reloadData = () => {
    const params = new URLSearchParams(window.location.search);
    dispatch(
      getTeamListRequest({
        code: params.get('code'),
        search: params.get('name'),
        country: params.get('country'),
        status: params.get('status'),
        page: Math.abs(_.toInteger(params.get('page')) || 1),
      }),
    );
  };

  const handleChangePage = (
    event: React.ChangeEvent<unknown> | null,
    newPage: number,
  ) => {
    const params = queryString.parse(window.location.search);
    const qs = queryString.stringify({ ...params, page: +newPage });
    history.push(`${Path.TEAM}?${qs}`);
    setPage(newPage);
  };
  useEffect(() => {
    if (
      createTeamInfoStatus === RequestStatus.SUCCESS ||
      updateTeamInfoStatus === RequestStatus.SUCCESS ||
      deleteTeamInfoStatus === RequestStatus.SUCCESS
    ) {
      dispatch(resetTeamInfoRequestStatus());
      reloadData();
    }
    if (deleteTeamInfoStatus === RequestStatus.SUCCESS) {
      dispatch(setSuccessMessages(['Team delete successful!']));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    createTeamInfoStatus,
    updateTeamInfoStatus,
    deleteTeamInfoStatus,
    resetTeamInfoRequestStatus,
    dispatch,
  ]);
  const isLoading = checkIsRequesting([getTeamListStatus]);

  useEffect(() => {
    reloadData();
    const params = new URLSearchParams(window.location.search);
    const newPage = Math.abs(_.toInteger(params.get('page')));
    setPage(newPage ? newPage : 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  useEffect(() => {
    dispatch(setBreadcumbs([{ title: 'Teams' }]));
    dispatch(getProjectRoleListRequest({}));
    dispatch(getTeamRoleListRequest({}));
    const params = new URLSearchParams(window.location.search);
    setPage(_.toInteger(params.get('page') || 1));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Helmet>
        <title>Team Management</title>
        <meta name="description" content="Customer Management" />
      </Helmet>
      <Container>
        <Wrapper>
          <Loader open={isLoading} />
          <Content spacing={2}>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
            >
              <Typography variant="h6" component="h6">
                Team management
              </Typography>
              <Button
                variant="contained"
                onClick={() => {
                  setDataItem(null);
                  setShow(true);
                }}
                startIcon={<AddCircle />}
              >
                Create
              </Button>
            </Box>

            <FilterBar />

            <DialogForm open={show} onClose={handleClose} data={dataItem} />

            <Dialog open={showConfirm}>
              <DialogTitle>Delete team info</DialogTitle>

              <DialogContent>
                <DialogContentText>
                  Do you want to delete team info bellow?
                </DialogContentText>
                <Typography variant="subtitle1" component="div">
                  Name: {dataItem?.name}
                </Typography>
                <Typography variant="subtitle1" component="div">
                  Country: {COUNTRY_LIST[dataItem?.country || 'vi']}
                </Typography>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setShowConfirm(false)}>Close</Button>
                <Button
                  color="error"
                  onClick={() => {
                    setShowConfirm(false);
                    dispatch(deleteTeamInfoRequest(dataItem?.id));
                  }}
                >
                  Delete
                </Button>
              </DialogActions>
            </Dialog>

            <WrapTable>
              <TableContainer component={Paper}>
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ minWidth: 60 }} width={60}>
                        No.
                      </TableCell>
                      <TableCell sx={{ minWidth: 80 }} width={140}>
                        Code
                      </TableCell>
                      <TableCell sx={{ minWidth: 110 }}>Name</TableCell>
                      <TableCell sx={{ minWidth: 110 }} width={200}>
                        Country
                      </TableCell>
                      <TableCell>Member</TableCell>
                      <TableCell sx={{ minWidth: 90 }} width={120}>
                        Status
                      </TableCell>
                      <TableCell width={90}>Action</TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {listTeam?.data.map((item, idx) => {
                      return (
                        <TableRow key={`team_row_${idx}`}>
                          <TableCell>{idx + 1}</TableCell>
                          <TableCell>{item.code}</TableCell>
                          <TableCell>{item.name}</TableCell>
                          <TableCell>{COUNTRY_LIST[item.country]}</TableCell>
                          <TableCell>
                            <Stack direction="row">
                              <AvatarGroup max={6}>
                                {item.members &&
                                  item.members.map((it, idx) => (
                                    <AvatarMember
                                      key={idx}
                                      title={it?.full_name}
                                      avatar={it?.avatar}
                                      member_id={it?.id}
                                      size={44}
                                    ></AvatarMember>
                                  ))}
                              </AvatarGroup>
                            </Stack>
                          </TableCell>
                          <TableCell>{STATUS_LIST[item.status]}</TableCell>
                          <TableCell align="right">
                            <Stack direction="row" spacing={1}>
                              <IconButton
                                size="small"
                                onClick={() => {
                                  setDataItem(item);
                                  setShow(true);
                                }}
                              >
                                <EditIcon />
                              </IconButton>
                              <IconButton
                                size="small"
                                color="error"
                                onClick={() => {
                                  setDataItem(item);
                                  setShowConfirm(true);
                                }}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </Stack>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </WrapTable>
            <Stack spacing={2} alignItems={'flex-end'}>
              <Pagination
                page={page}
                color="primary"
                count={listTeam?.total_page || 0}
                variant="outlined"
                shape="rounded"
                onChange={handleChangePage}
              />
            </Stack>
          </Content>
        </Wrapper>
      </Container>
    </>
  );
}

const WrapTable = styled(Box)`
  & .MuiTableContainer-root {
    max-height: calc(100vh - 230px);
  }
  & .MuiAvatarGroup-avatar {
    background-color: #ffd8bf;
    color: #fa541c;
  }
  & .MuiAvatar-root:last-child {
    margin-left: -8px !important;
  }
`;

import React from 'react';
// UI
import {
  Box,
  IconButton,
  Collapse,
  Typography,
  Paper,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Stack,
} from '@mui/material';
import styled from 'styled-components/macro';
import _ from 'lodash';

import {
  KeyboardArrowUp as KeyboardArrowUpIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,
} from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';

import { HourTag } from 'app/components/HourTag';
import { TagComponent } from 'app/components/TagComponent';
import { APPROVED_TASK_STATUS, REQUESTING_TASK_STATUS } from 'constants/common';
import { ReactComponent as EditIcon } from 'assets/icons/button/edit.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/button/delete.svg';
import { ITimesheet } from '../slice/types';

interface ICollapseTableProps {
  totalHours: number;
  project: ITimesheet[];
  showDialogUpdateTask: Function;
  showDialogDeleteTask: Function;
}

export function CollapseTable(props: ICollapseTableProps) {
  const { totalHours, project, showDialogUpdateTask, showDialogDeleteTask } =
    props;
  const [open, setOpen] = React.useState(false);
  return (
    <CollapseTableWrapper>
      <Paper elevation={0}>
        <Box
          display="flex"
          flexDirection="row"
          sx={{ cursor: 'pointer' }}
          style={{ padding: '10px 0 10px 5px' }}
          onClick={() => {
            setOpen(!open);
          }}
        >
          <IconButton
            aria-label="expand row"
            size="small"
            style={{ marginRight: '5px' }}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
          <Stack direction="row" spacing={1} alignItems="center">
            <Typography variant="h6" gutterBottom component="span" mb={0}>
              {_.head(project)?.project?.name}
            </Typography>
            <Typography variant="h6" gutterBottom component="span">
              <HourTag
                data={{ backgroundColor: '#BDD8FF', label: `${totalHours}h` }}
              ></HourTag>
            </Typography>
          </Stack>
        </Box>
        <Collapse
          in={open}
          timeout="auto"
          unmountOnExit
          style={{ borderTop: '1px solid #D9D9D9' }}
        >
          <TableContainer style={{ paddingLeft: '23.5px' }}>
            <Table>
              <TableBody>
                {project?.map((task, idx) => {
                  return (
                    <TableRow key={`task_${idx}`}>
                      <TableCell sx={{ minWidth: 130 }}>
                        <TaskContent>{task.description}</TaskContent>
                      </TableCell>
                      <TableCell sx={{ minWidth: 70 }} width={180}>
                        <Stack spacing={1}>
                          {task?.tags?.map((v, k) => (
                            <TagComponent data={v} key={k} />
                          ))}
                        </Stack>
                      </TableCell>
                      <TableCell sx={{ minWidth: 70 }} width={180}>
                        <TaskContent>{task.hours}h</TaskContent>
                      </TableCell>
                      <TableCell sx={{ minWidth: 70 }} width={150}>
                        <Stack direction="row" spacing={1}>
                          <IconButtonCustom
                            disabled={
                              _.toNumber(task?.status) ===
                                APPROVED_TASK_STATUS ||
                              _.toNumber(task?.status) ===
                                REQUESTING_TASK_STATUS
                            }
                            onClick={() => {
                              showDialogUpdateTask(task);
                            }}
                          >
                            <EditIcon />
                          </IconButtonCustom>
                          <IconButtonCustom
                            disabled={
                              _.toNumber(task?.status) ===
                                APPROVED_TASK_STATUS ||
                              _.toNumber(task?.status) ===
                                REQUESTING_TASK_STATUS
                            }
                            color="error"
                            onClick={() => {
                              showDialogDeleteTask(task);
                            }}
                          >
                            <DeleteIcon />
                          </IconButtonCustom>
                        </Stack>
                      </TableCell>
                    </TableRow>
                  );
                })}
                <TableRow>
                  <TableCell>
                    <Box
                      display="flex"
                      alignItems="center"
                      style={{ cursor: 'pointer', width: 'fit-content' }}
                      onClick={() => {
                        showDialogUpdateTask({
                          project: _.head(project)?.project,
                        });
                      }}
                    >
                      <AddIcon color="primary" />
                      <Typography color="primary">
                        Add task to project
                      </Typography>
                    </Box>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Collapse>
      </Paper>
    </CollapseTableWrapper>
  );
}
const CollapseTableWrapper = styled(Box)`
  table {
    & tr:last-child td {
      border: none;
    }
  }
`;

const IconButtonCustom = styled(IconButton)`
  &.Mui-disabled {
    cursor: not-allowed !important;
    pointer-events: unset !important;
  }
`;

const TaskContent = styled(Box)`
  font-size: 14px;
`;

export default CollapseTable;

import * as React from 'react';

// UI
import { Helmet } from 'react-helmet-async';
import styled from 'styled-components/macro';
import {
  Container,
  Typography,
  Stack,
  Paper,
  Box,
  Grid,
  Avatar,
  Divider,
  Link,
} from '@mui/material';
import { Circle } from '@mui/icons-material';
import { ReactComponent as UserIcon } from 'assets/icons/cvshare/account.svg';
import { ReactComponent as GenderIcon } from 'assets/icons/cvshare/gender.svg';
import { ReactComponent as CalendarIcon } from 'assets/icons/cvshare/calendar.svg';
import { ReactComponent as MapIcon } from 'assets/icons/cvshare/map.svg';
import { ReactComponent as QualityIcon } from 'assets/icons/cvshare/quality.svg';
import { ReactComponent as SchoolIcon } from 'assets/icons/cvshare/school.svg';
import { LEVER_SKILL } from 'constants/common';
import LoginPageBackground from 'assets/page-login-bg.svg';
// Redux & Sagas
import { useMemberSlice } from '../MemberPage/slice';
import { useDispatch, useSelector } from 'react-redux';
import { selectMember } from 'app/pages/MemberPage/slice/selectors';
// Library
import _ from 'lodash';
import { useParams } from 'react-router-dom';
interface RouteParams {
  id: string;
  uuid: string;
}

export function CVSharePage(props) {
  const dispatch = useDispatch();
  const params = useParams<RouteParams>();
  const {
    actions: {
      getMemberInfoRequest,
      getSkillListRequest,
      getLanguageListRequest,
    },
  } = useMemberSlice();

  const { memberInfo, skill_metadata, language_metadata } =
    useSelector(selectMember);
  React.useEffect(() => {
    if (params.uuid) {
      dispatch(getMemberInfoRequest({ id: params.id, uuid: params.uuid }));
    } else {
      dispatch(getMemberInfoRequest(params.id));
    }
    dispatch(getSkillListRequest({}));
    dispatch(getLanguageListRequest({}));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <Helmet>
        <title>Palette</title>
        <meta name="description" content="Palette" />
        <style>{`
          body, #root{
            background-image: url(${LoginPageBackground});
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
          }
        `}</style>
      </Helmet>
      <Container>
        <CVShareWrapper variant="outlined">
          <Stack spacing={2}>
            <Grid container>
              <Grid item xs={2}>
                <Stack spacing={2}>
                  <Avatar
                    src={memberInfo?.avatar}
                    variant="square"
                    sx={{ width: 108, height: 108 }}
                  ></Avatar>
                </Stack>
              </Grid>
              <Grid item xs={10}>
                <Stack>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Stack spacing={2} direction="row">
                        <Typography className="blue_text" fontWeight={600}>
                          チームリーダー
                        </Typography>
                        <Divider orientation="vertical" flexItem></Divider>
                        <Typography className="blue_text" fontWeight={600}>
                          所属チーム : 本社
                        </Typography>
                      </Stack>
                    </Grid>
                    <Grid item xs={6}>
                      <Stack spacing={1}>
                        <Stack spacing={2} direction="row">
                          <Box>
                            <UserIcon />
                          </Box>
                          <Typography>{`${memberInfo?.full_name}(${
                            memberInfo?.first_name_kanji
                              ? memberInfo?.first_name_kanji
                              : ''
                          })`}</Typography>
                        </Stack>
                        <Stack spacing={2} direction="row">
                          <Box>
                            <GenderIcon />
                          </Box>
                          <Typography>
                            {memberInfo?.gender === '1' ? 'Femail' : 'Male'}
                          </Typography>
                        </Stack>
                        <Stack spacing={2} direction="row">
                          <Box>
                            <CalendarIcon />
                          </Box>
                          <Typography>{memberInfo?.birthday}</Typography>
                        </Stack>
                      </Stack>
                    </Grid>
                    <Grid item xs={6}>
                      <Stack spacing={1}>
                        <Stack spacing={2} direction="row">
                          <Box>
                            <MapIcon />
                          </Box>
                          <Typography>{memberInfo?.address}</Typography>
                        </Stack>
                        <Stack spacing={2} direction="row">
                          <Box>
                            <SchoolIcon />
                          </Box>
                          <Typography>{`${memberInfo?.education}`}</Typography>
                        </Stack>
                        <Stack spacing={2} direction="row">
                          <Box>
                            <QualityIcon />
                          </Box>
                          <Typography>{memberInfo?.major_field}</Typography>
                        </Stack>
                      </Stack>
                    </Grid>
                  </Grid>
                </Stack>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={2}>
                <Stack spacing={1}>
                  <Typography variant="h6">スキルセット</Typography>
                  <Typography variant="subtitle2">
                    ◎: 実際の業務経験3年以上
                  </Typography>
                  <Typography variant="subtitle2">
                    ◯: 実際の業務経験あり
                  </Typography>
                  <Typography variant="subtitle2">△: 知識があり</Typography>
                </Stack>
              </Grid>
              <Grid item xs={10}>
                {skill_metadata?.map((skill_meta, idx) => {
                  return (
                    <Stack key={idx} mb={2}>
                      <Grid container spacing={2}>
                        <Grid item xs={3}>
                          <Typography className="skill_meta_name">
                            {skill_meta.name}
                          </Typography>
                        </Grid>
                        <Grid item xs={9}>
                          <Grid container spacing={2}>
                            {memberInfo?.skills.map((memberSkill, idx) => {
                              if (
                                _.find(skill_meta.skills, {
                                  id: memberSkill?.skill,
                                })
                              )
                                return (
                                  <Grid item xs={4}>
                                    <Stack key={`${idx}_memberskill`}>
                                      <Stack direction="row" spacing={1}>
                                        <Typography>
                                          {_.get(
                                            _.find(LEVER_SKILL, {
                                              id: _.toInteger(
                                                memberSkill.level,
                                              ),
                                            }),
                                            'label',
                                          )}
                                        </Typography>
                                        <Typography>
                                          {memberSkill.name}
                                        </Typography>
                                      </Stack>
                                    </Stack>
                                  </Grid>
                                );
                              else return <></>;
                            })}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Stack>
                  );
                })}
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={2}>
                <Stack spacing={2}>
                  <Typography variant="h6">対応可能言語</Typography>
                  <Typography variant="subtitle2">
                    ◎: ネイティブレベル
                  </Typography>
                  <Typography variant="subtitle2">〇:ビジネスレベル</Typography>
                  <Typography variant="subtitle2">△: 日常会話レベル</Typography>
                </Stack>
              </Grid>
              <Grid item xs={10}>
                <Grid container spacing={2} mb={2}>
                  <Grid item xs={3}></Grid>
                  <Grid item xs={9}>
                    <Grid container spacing={2} textAlign={'center'}>
                      <Grid item xs={3}>
                        <Typography>Reading</Typography>
                      </Grid>
                      <Grid item xs={3}>
                        <Typography>Writing</Typography>
                      </Grid>
                      <Grid item xs={3}>
                        <Typography>Speaking</Typography>
                      </Grid>
                      <Grid item xs={3}>
                        <Typography>Listening</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                {language_metadata?.map((language_meta, idx) => {
                  return (
                    <Stack key={idx} mb={2}>
                      {memberInfo?.languages.map((language, idx) => {
                        if (language?.language === language_meta.id)
                          return (
                            <Grid container spacing={2}>
                              <Grid item xs={3}>
                                <Typography className="skill_meta_name">
                                  {language_meta.name}
                                </Typography>
                              </Grid>
                              <Grid item xs={9}>
                                <Grid container spacing={2}>
                                  <Grid item xs={3} textAlign={'center'}>
                                    <Typography>
                                      {_.get(
                                        _.find(LEVER_SKILL, {
                                          id: _.toInteger(
                                            language.reading_level,
                                          ),
                                        }),
                                        'label',
                                      )}
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={3} textAlign={'center'}>
                                    <Typography>
                                      {_.get(
                                        _.find(LEVER_SKILL, {
                                          id: _.toInteger(
                                            language.writing_level,
                                          ),
                                        }),
                                        'label',
                                      )}
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={3} textAlign={'center'}>
                                    <Typography>
                                      {_.get(
                                        _.find(LEVER_SKILL, {
                                          id: _.toInteger(
                                            language.speaking_level,
                                          ),
                                        }),
                                        'label',
                                      )}
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={3} textAlign={'center'}>
                                    <Typography>
                                      {_.get(
                                        _.find(LEVER_SKILL, {
                                          id: _.toInteger(
                                            language.listening_level,
                                          ),
                                        }),
                                        'label',
                                      )}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          );
                        else return <></>;
                      })}
                    </Stack>
                  );
                })}
              </Grid>
            </Grid>
            <Typography variant="h6" mb={2}>
              最近の開発実績
            </Typography>
            <Stack direction={'row'}>
              <Box>
                <Divider
                  className="stepper"
                  orientation="vertical"
                  flexItem
                ></Divider>
              </Box>
              <Box sx={{ width: '100%' }}>
                {memberInfo?.project_histories?.map((project, idx) => {
                  return (
                    <Grid container mb={2}>
                      <Grid item xs={2}>
                        <Circle
                          className="step_dot"
                          sx={{ fontSize: 12 }}
                        ></Circle>
                        <Stack spacing={1} mt={-2}>
                          <Typography className="blue_text">
                            {project.period}
                          </Typography>
                          <Box>
                            <Typography variant="h6">プロジェクト名</Typography>
                            <Typography className="blue_text">
                              {project.project_name}
                            </Typography>
                          </Box>
                          <Box>
                            <Typography variant="h6">URL</Typography>
                            <Typography className="blue_text">
                              <Link href="{project.url}" target={'_blank'}>
                                {project.url}
                              </Link>
                            </Typography>
                          </Box>
                        </Stack>
                      </Grid>
                      <Grid item xs={10}>
                        <Grid item xs={10}>
                          <Stack spacing={2}>
                            <Typography>{project.scope}</Typography>
                            <Box>
                              <Typography variant="h6">使用言語等</Typography>
                              <Typography>{project.language_info}</Typography>
                            </Box>
                            <Box>
                              <Typography variant="h6">概要</Typography>
                              <Typography>{project.description}</Typography>
                            </Box>
                          </Stack>
                        </Grid>
                      </Grid>
                    </Grid>
                  );
                })}
              </Box>
            </Stack>
          </Stack>
        </CVShareWrapper>
      </Container>
    </>
  );
}

const CVShareWrapper = styled(Paper)`
  padding: 30px;
  width: 860px;
  margin: 20% auto;
  & .stepper {
    position: relative;
    left: 15%;
    top: 4%;
    width: 3px;
    margin-right: 26px;
    height: 100%;
    background-color: grey;
    opacity: 0.7;
  }
  & .step_dot {
    position: relative;
    right: 23%;
  }
  & .blue_text {
    color: #0052a8;
  }
  & .MuiTypography-subtitle2 {
    font-size: 9px;
  }
  & .MuiTypography-h6 {
    font-size: 12px;
    font-weight: 600;
  }
  & .skill_meta_name {
    background-color: #0052a8;
    color: white;
    font-weight: 400;
    font-size: 13px;
    padding: 2px 0px 2px 6px;
    width: 80%;
  }
`;

import React from 'react';

// UI
import {
  TableBody,
  TableRow,
  TableCell,
  FormControl,
  Select,
  MenuItem,
} from '@mui/material';

// Redux & Sagas
import { UseFormReturn, Controller } from 'react-hook-form';

import { ICommonDataSchema } from 'types/common';

// Library
import _ from 'lodash';

interface ISkillTableProps {
  title: string;
  rows?: number;
  memberForm: UseFormReturn;
  dataKey: string;
  startIndex?: number;
  data: ICommonDataSchema[];
}

export default function SkillTable(props: ISkillTableProps) {
  const { control } = props.memberForm;

  return (
    <TableBody>
      {_.times(props.rows || 1).map(rowIndex => {
        const baseIndex = (props.startIndex || 0) + rowIndex * 3;
        return (
          <TableRow key={`skilltable_${props.dataKey}_${baseIndex}`}>
            {rowIndex === 0 && (
              <TableCell rowSpan={props.rows} component="th">
                {props.title}
              </TableCell>
            )}
            <TableCell width={200}>
              <FormControl fullWidth>
                <Controller
                  control={control}
                  name={`${props.dataKey}[${baseIndex}].skill`}
                  defaultValue=""
                  render={({ field }) => {
                    const { onBlur, onChange, value } = field;
                    return (
                      <Select
                        fullWidth
                        size="small"
                        onBlur={onBlur}
                        onChange={onChange}
                        value={value}
                      >
                        {props.data.map((it, idx) => {
                          return (
                            <MenuItem
                              key={`${props.dataKey}[${baseIndex}].skill_${idx}`}
                              value={it.id}
                            >
                              {it.name}
                            </MenuItem>
                          );
                        })}
                        <MenuItem value={'0'}>{' - '}</MenuItem>
                      </Select>
                    );
                  }}
                />
              </FormControl>
            </TableCell>
            <TableCell width={100}>
              <FormControl fullWidth>
                <Controller
                  control={control}
                  name={`${props.dataKey}[${baseIndex}].level`}
                  defaultValue=""
                  render={({ field }) => {
                    const { onBlur, onChange, value } = field;
                    return (
                      <Select
                        fullWidth
                        size="small"
                        onBlur={onBlur}
                        onChange={onChange}
                        value={value}
                      >
                        <MenuItem value={'3'}>◎</MenuItem>
                        <MenuItem value={'2'}>◯</MenuItem>
                        <MenuItem value={'1'}>△</MenuItem>
                        <MenuItem value={'0'}>-</MenuItem>
                      </Select>
                    );
                  }}
                />
              </FormControl>
            </TableCell>
            <TableCell width={200}>
              <FormControl fullWidth>
                <Controller
                  control={control}
                  name={`${props.dataKey}[${baseIndex + 1}].skill`}
                  defaultValue=""
                  render={({ field }) => {
                    const { onBlur, onChange, value } = field;
                    return (
                      <Select
                        fullWidth
                        size="small"
                        onBlur={onBlur}
                        onChange={onChange}
                        value={value}
                      >
                        {props.data.map((it, idx) => {
                          return (
                            <MenuItem
                              key={`${props.dataKey}[${
                                baseIndex + 1
                              }].skill_${idx}`}
                              value={it.id}
                            >
                              {it.name}
                            </MenuItem>
                          );
                        })}
                        <MenuItem value={'0'}>{' - '}</MenuItem>
                      </Select>
                    );
                  }}
                />
              </FormControl>
            </TableCell>
            <TableCell width={100}>
              <FormControl fullWidth>
                <Controller
                  control={control}
                  name={`${props.dataKey}[${baseIndex + 1}].level`}
                  defaultValue=""
                  render={({ field }) => {
                    const { onBlur, onChange, value } = field;
                    return (
                      <Select
                        fullWidth
                        size="small"
                        onBlur={onBlur}
                        onChange={onChange}
                        value={value}
                      >
                        <MenuItem value={'3'}>◎</MenuItem>
                        <MenuItem value={'2'}>◯</MenuItem>
                        <MenuItem value={'1'}>△</MenuItem>
                        <MenuItem value={'0'}>-</MenuItem>
                      </Select>
                    );
                  }}
                />
              </FormControl>
            </TableCell>
            <TableCell width={200}>
              <FormControl fullWidth>
                <Controller
                  control={control}
                  name={`${props.dataKey}[${baseIndex + 2}].skill`}
                  defaultValue=""
                  render={({ field }) => {
                    const { onBlur, onChange, value } = field;
                    return (
                      <Select
                        fullWidth
                        size="small"
                        onBlur={onBlur}
                        onChange={onChange}
                        value={value}
                      >
                        {props.data.map((it, idx) => {
                          return (
                            <MenuItem
                              key={`${props.dataKey}[${
                                baseIndex + 2
                              }].skill_${idx}`}
                              value={it.id}
                            >
                              {it.name}
                            </MenuItem>
                          );
                        })}
                        <MenuItem value={'0'}>{' - '}</MenuItem>
                      </Select>
                    );
                  }}
                />
              </FormControl>
            </TableCell>
            <TableCell width={100}>
              <FormControl fullWidth>
                <Controller
                  control={control}
                  name={`${props.dataKey}[${baseIndex + 2}].level`}
                  defaultValue=""
                  render={({ field }) => {
                    const { onBlur, onChange, value } = field;
                    return (
                      <Select
                        fullWidth
                        size="small"
                        onBlur={onBlur}
                        onChange={onChange}
                        value={value}
                      >
                        <MenuItem value={'3'}>◎</MenuItem>
                        <MenuItem value={'2'}>◯</MenuItem>
                        <MenuItem value={'1'}>△</MenuItem>
                        <MenuItem value={'0'}>-</MenuItem>
                      </Select>
                    );
                  }}
                />
              </FormControl>
            </TableCell>
            <TableCell width={200}>
              <FormControl fullWidth>
                <Controller
                  control={control}
                  name={`${props.dataKey}[${baseIndex + 3}].skill`}
                  defaultValue=""
                  render={({ field }) => {
                    const { onBlur, onChange, value } = field;
                    return (
                      <Select
                        fullWidth
                        size="small"
                        onBlur={onBlur}
                        onChange={onChange}
                        value={value}
                      >
                        {props.data.map((it, idx) => {
                          return (
                            <MenuItem
                              key={`${props.dataKey}[${
                                baseIndex + 3
                              }].skill_${idx}`}
                              value={it.id}
                            >
                              {it.name}
                            </MenuItem>
                          );
                        })}
                        <MenuItem value={'0'}>{' - '}</MenuItem>
                      </Select>
                    );
                  }}
                />
              </FormControl>
            </TableCell>
            <TableCell width={100}>
              <FormControl fullWidth>
                <Controller
                  control={control}
                  name={`${props.dataKey}[${baseIndex + 3}].level`}
                  defaultValue=""
                  render={({ field }) => {
                    const { onBlur, onChange, value } = field;
                    return (
                      <Select
                        fullWidth
                        size="small"
                        onBlur={onBlur}
                        onChange={onChange}
                        value={value}
                      >
                        <MenuItem value={'3'}>◎</MenuItem>
                        <MenuItem value={'2'}>◯</MenuItem>
                        <MenuItem value={'1'}>△</MenuItem>
                        <MenuItem value={'0'}>-</MenuItem>
                      </Select>
                    );
                  }}
                />
              </FormControl>
            </TableCell>
          </TableRow>
        );
      })}
    </TableBody>
  );
}

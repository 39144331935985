import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import teamSaga from './saga';
import { TeamState } from './types';
import { RequestStatus } from 'constants/API';

export const initialState: TeamState = {
  getTeamListStatus: RequestStatus.IDLE,
  createTeamInfoStatus: RequestStatus.IDLE,
  updateTeamInfoStatus: RequestStatus.IDLE,
  getTeamRoleListStatus: RequestStatus.IDLE,
  deleteTeamInfoStatus: RequestStatus.IDLE,
  listTeam: null,
  team_role_metadata: null,
  listError: null,
};

const slice = createSlice({
  name: 'team',
  initialState,
  reducers: {
    getTeamListRequest: (state, action: PayloadAction<any>) => {
      if (action) state.getTeamListStatus = RequestStatus.REQUESTING;
    },
    getTeamListSuccess: (state, action: PayloadAction<any>) => {
      if (action) {
        state.getTeamListStatus = RequestStatus.SUCCESS;
        state.listTeam = action.payload;
      }
    },
    getTeamListFail: (state, action: PayloadAction<any>) => {
      state.getTeamListStatus = RequestStatus.ERROR;
    },
    getTeamRoleListRequest: (state, action: PayloadAction<any>) => {
      if (action) state.getTeamRoleListStatus = RequestStatus.REQUESTING;
    },
    getTeamRoleListSuccess: (state, action: PayloadAction<any>) => {
      if (action) {
        state.getTeamRoleListStatus = RequestStatus.SUCCESS;
        state.team_role_metadata = action.payload;
      }
    },
    getTeamRoleListFail: (state, action: PayloadAction<any>) => {
      state.getTeamRoleListStatus = RequestStatus.ERROR;
    },
    createTeamInfoRequest: (state, action: PayloadAction<any>) => {
      if (action) state.createTeamInfoStatus = RequestStatus.REQUESTING;
    },
    createTeamInfoSuccess: (state, action: PayloadAction<any>) => {
      if (action) state.createTeamInfoStatus = RequestStatus.SUCCESS;
    },
    createTeamInfoFail: (state, action: PayloadAction<any>) => {
      state.createTeamInfoStatus = RequestStatus.ERROR;
      if (action) {
        state.listError = action.payload;
      }
    },
    updateTeamInfoRequest: (state, action: PayloadAction<any>) => {
      if (action) state.updateTeamInfoStatus = RequestStatus.REQUESTING;
    },
    updateTeamInfoSuccess: (state, action: PayloadAction<any>) => {
      if (action) state.updateTeamInfoStatus = RequestStatus.SUCCESS;
    },
    updateTeamInfoFail: (state, action: PayloadAction<any>) => {
      state.updateTeamInfoStatus = RequestStatus.ERROR;
      if (action) {
        state.listError = action.payload;
      }
    },
    deleteTeamInfoRequest: (state, action: PayloadAction<any>) => {
      if (action) state.deleteTeamInfoStatus = RequestStatus.REQUESTING;
    },
    deleteTeamInfoSuccess: (state, action: PayloadAction<any>) => {
      state.deleteTeamInfoStatus = RequestStatus.SUCCESS;
    },
    deleteTeamInfoFail: (state, action: PayloadAction<any>) => {
      state.deleteTeamInfoStatus = RequestStatus.ERROR;
    },
    resetTeamInfoRequestStatus: state => {
      state.getTeamListStatus = RequestStatus.IDLE;
      state.createTeamInfoStatus = RequestStatus.IDLE;
      state.updateTeamInfoStatus = RequestStatus.IDLE;
      state.deleteTeamInfoStatus = RequestStatus.IDLE;
    },
  },
});

export default slice.actions;

export const useTeamSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: teamSaga });
  return { actions: slice.actions };
};

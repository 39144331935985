import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  html,
  body {
    height: 100%;
    width: 100%;
    background-color: ${p => p.theme.background};
    font-size: 13px;
    font-weight: 400;

    -ms-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  body {
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;

    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;

    color: #3F4254;
    .disable_autofill{
      position: absolute;
      pointer-events: none;
      width: 1px;
      height: 1px;
      opacity: 0;
    }
    iframe{
      display: none;
    }
  }
  
  body.fontLoaded{
    * {
      font-family: ${p => p.theme.fontFamily} !important;
    }
  }
  
  .MuiTableContainer-root{
    width: 100%;
    overflow-x: auto;
    max-height: 640px;
    table{
      thead{
        background-color: #fafafa;
        tr {
          th{
            text-transform: uppercase;
            font-weight: bold;
          }
        }
      }
    }
    .MuiTableHead-root.MuiLinearProgress-root{
      background: transparent;
      height: 2px;
    }
  }
  .MuiButtonBase-root.MuiPaginationItem-root{
    &.Mui-selected{
      background-color: #437DFF;
      color: #fff;
      &:hover{
        background-color: #437DFF;
      }
    }
  }
`;

import * as React from 'react';
import Path from 'config/clientPath';

// UI
import { Helmet } from 'react-helmet-async';
import styled from 'styled-components/macro';
import { useDispatch, useSelector } from 'react-redux';
import {
  Container,
  CardContent,
  Typography,
  Button,
  TextField,
  Stack,
  Paper,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { RequestStatus } from 'constants/API';
import LoginPageBackground from 'assets/page-login-bg.svg';
import { ReactComponent as ForgotPasswordLogo } from 'assets/forgot.svg';
import { ReactComponent as Logo } from 'assets/logo.svg';
// Redux & Sagas
import { useForgotPasswordSlice } from './slice';
import { selectForgotPassword } from './slice/selectors';
import { useGlobalSlice } from 'app/pages/GlobalContainer/slice';
import { NotFoundPage } from 'app/components/NotFoundPage';
// Library
import _ from 'lodash';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { REGEX_VALIDATE_EMAIL } from 'constants/common';

interface IForgotPasswordForm {
  email: string;
}
interface IResetPasswordForm {
  new_password: string;
  confirm_password: string;
}

interface RouteParams {
  token: string;
  uidb64: string;
}

export function ForgotPasswordPage() {
  const {
    resetPasswordRequest,
    checkExpiredLinkForgotPasswordRequest,
    forgotPasswordRequest,
    resetForgotPasswordStatus,
  } = useForgotPasswordSlice().actions;
  const {
    forgotPasswordStatus,
    resetPasswordStatus,
    checkExpiredLinkForgotPasswordStatus,
  } = useSelector(selectForgotPassword);
  const dispatch = useDispatch();
  const { setSuccessMessages, setErrorMessages } = useGlobalSlice().actions;
  const params = useParams<RouteParams>();
  const [isOnResetPasswordForm, setIsOnResetPasswordForm] =
    React.useState(false);
  const [isLinkValid, setIsLinkValid] = React.useState(false);
  const [isSubmited, setIsSubmited] = React.useState(false);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const handleForgotPasswordSubmit = formData => {
    dispatch(forgotPasswordRequest(formData));
  };
  const handleResetPasswordSubmit = formData => {
    let payloadData = _.omit(formData, ['email']);
    dispatch(
      resetPasswordRequest({
        uidb64: params.uidb64,
        token: params.token,
        payloadData,
      }),
    );
  };

  React.useEffect(() => {
    if (forgotPasswordStatus === RequestStatus.SUCCESS) {
      dispatch(setSuccessMessages(['Send reset email success']));
    }
    if (forgotPasswordStatus === RequestStatus.ERROR) {
      dispatch(setErrorMessages(['Send reset email have errors']));
    }
    if (resetPasswordStatus === RequestStatus.SUCCESS) {
      dispatch(setSuccessMessages(['Reset password success']));
      setIsSubmited(true);
    }
    if (resetPasswordStatus === RequestStatus.ERROR) {
      dispatch(setErrorMessages(['Reset password have errors']));
    }
    dispatch(resetForgotPasswordStatus());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forgotPasswordStatus, resetPasswordStatus]);
  React.useEffect(() => {
    if (params.token && params.uidb64) {
      setIsOnResetPasswordForm(true);
    } else {
      setIsOnResetPasswordForm(false);
    }
  }, [params]);
  React.useEffect(() => {
    if (params.uidb64) {
      dispatch(
        checkExpiredLinkForgotPasswordRequest({
          uidb64: params.uidb64,
          token: params.token,
        }),
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (checkExpiredLinkForgotPasswordStatus === RequestStatus.ERROR) {
      setIsLinkValid(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkExpiredLinkForgotPasswordStatus]);

  return (
    <>
      {isLinkValid ? (
        NotFoundPage()
      ) : (
        <>
          <Helmet>
            <title>Palette</title>
            <meta name="description" content="Palette" />
            <style>{`
          body, #root{
            background-image: url(${LoginPageBackground});
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
          }
        `}</style>
          </Helmet>
          <Container>
            <ForgotPasswordForm variant="outlined">
              <Typography
                gutterBottom
                variant="h4"
                component="h1"
                align="center"
              >
                {isOnResetPasswordForm ? <Logo /> : <ForgotPasswordLogo />}
              </Typography>
              <Typography
                gutterBottom
                variant="h5"
                component="h3"
                align="center"
              >
                {isOnResetPasswordForm ? 'Change password' : 'Forgot password'}
              </Typography>
              <CardContent className="body">
                {isSubmited ? (
                  <Stack spacing={1} alignItems="center">
                    <Typography gutterBottom>
                      Change password successful
                    </Typography>
                    <Link to={Path.LOGIN}>Login</Link>
                  </Stack>
                ) : (
                  <>
                    {isOnResetPasswordForm ? (
                      <form onSubmit={handleSubmit(handleResetPasswordSubmit)}>
                        <Stack spacing={2}>
                          <Stack spacing={1}>
                            <Typography gutterBottom>New password</Typography>
                            <TextField
                              {...register<keyof IResetPasswordForm>(
                                'new_password',
                                {
                                  required: 'This is required.',
                                },
                              )}
                              type="password"
                              placeholder="Enter password"
                              fullWidth
                              variant="outlined"
                              size="small"
                              helperText={errors.new_password?.message}
                              error={errors.new_password ? true : false}
                            />
                          </Stack>
                          <Stack spacing={1}>
                            <Typography gutterBottom>
                              Confirm new password
                            </Typography>
                            <TextField
                              {...register<keyof IResetPasswordForm>(
                                'confirm_password',
                                {
                                  required: 'This is required.',
                                  validate: value =>
                                    value === watch('new_password'),
                                },
                              )}
                              type="password"
                              placeholder="Re-enter password"
                              fullWidth
                              variant="outlined"
                              size="small"
                              helperText={
                                errors.confirm_password?.type === 'validate'
                                  ? 'Password not match'
                                  : errors.confirm_password?.message
                              }
                              error={errors.confirm_password ? true : false}
                            />
                          </Stack>
                          <Stack direction="row" justifyContent="center">
                            <Button
                              variant="contained"
                              color="primary"
                              type="submit"
                              size="large"
                            >
                              Change password
                            </Button>
                          </Stack>
                        </Stack>
                      </form>
                    ) : (
                      <form onSubmit={handleSubmit(handleForgotPasswordSubmit)}>
                        <Stack spacing={2}>
                          <Stack spacing={1}>
                            <Typography gutterBottom>Email</Typography>
                            <TextField
                              {...register<keyof IForgotPasswordForm>('email', {
                                required: 'This field is required.',
                                pattern: {
                                  value: REGEX_VALIDATE_EMAIL,
                                  message: 'Email is invalid',
                                },
                              })}
                              type="email"
                              placeholder="Enter your email"
                              fullWidth
                              variant="outlined"
                              size="small"
                              error={errors.email ? true : false}
                              helperText={errors.email?.message}
                            />
                          </Stack>
                          <Stack direction="row" justifyContent="center">
                            <Button
                              variant="contained"
                              color="primary"
                              type="submit"
                              size="large"
                            >
                              Reset password
                            </Button>
                          </Stack>
                        </Stack>
                      </form>
                    )}
                  </>
                )}
              </CardContent>
            </ForgotPasswordForm>
          </Container>
        </>
      )}
    </>
  );
}

const ForgotPasswordForm = styled(Paper)`
  width: 480px;
  margin: 20% auto;

  h1 {
    text-align: center;
    padding: 15px 0;
    margin: 0;
  }
  .body {
    padding-top: 0;
  }
  .MuiInputBase-formControl {
    fieldset,
    &:hover fieldset {
      border-color: #eef0f8;
    }
  }
`;

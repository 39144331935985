import Img from 'assets/img/landing_slide.png';
import React, { useState } from 'react';
import Slider from 'react-slick';
import styled from 'styled-components';
import { device } from './Variables';

export const HomeSlider = () => {
  const [imageIndex, setImageIndex] = useState(0);
  const settings = {
    infinite: true,
    dots: true,
    centerPadding: '0px',
    slidesToShow: 3,
    centerMode: true,
    speed: 500,
    beforeChange: (current, next) => {
      setImageIndex(next);
    },
  };
  const data = [Img, Img, Img, Img];
  return (
    <SliderWrapper>
      <Slider {...settings}>
        {data.map((img, idx) => (
          <div
            key={idx}
            className={idx === imageIndex ? 'slide activeSlide' : 'slide'}
          >
            <img src={img} alt={img} />
          </div>
        ))}
      </Slider>
    </SliderWrapper>
  );
};

const SliderWrapper = styled.div`
  margin: 0 auto;
  max-width: 80%;
  height: 500px;
  @media ${device.tablet} {
    height: 300px;
  }
  .slick-slide {
    position: relative;
    z-index: 4;
    &.slick-current {
      z-index: 10;
    }
  }
  .slick-current + div img {
    transition: transform 500ms;
    transform: translateX(-50%);
  }
  .slide img {
    width: 60rem;
    align-items: center;
    margin: 0 auto;
    @media ${device.tablet} {
      width: 30rem;
    }
    @media ${device.mobileL} {
      width: 20rem;
    }
  }

  .slide {
    transform: scale(0.8);
    transition: transform 500ms;
    opacity: 0.8;
    position: relative;
  }

  .activeSlide {
    transform: scale(1.2);
    align-items: center;
    opacity: 1;

    img {
      transform: translateX(-25%) scale(0.8);
      box-shadow: 0px 8px 4px 0 rgb(47 95 225 / 15%);
    }
  }
`;

import { StatusCode } from 'constants/API';
import { call, put, takeLatest } from 'redux-saga/effects';
import APIs from 'APIs';

import Slice from '.';

function* getAssignmentSummaryFlow({ payload }) {
  try {
    const response = yield call(APIs.getAssignmentSummary, payload);
    if (response.status === StatusCode.SUCCESS_COMMON) {
      yield put({
        type: Slice.getAssignmentSummarySuccess.type,
        payload: response.data,
      });
    } else {
      yield put({ type: Slice.getAssignmentSummaryFail.type });
    }
  } catch (error) {
    yield put({ type: Slice.getAssignmentSummaryFail.type });
  }
}

function* getAssignmentDetailFlow({ payload }) {
  try {
    const response = yield call(APIs.getAssignmentDetail, payload);
    if (response.status === StatusCode.SUCCESS_COMMON) {
      yield put({
        type: Slice.getAssignmentDetailSuccess.type,
        payload: response.data,
      });
    } else {
      yield put({ type: Slice.getAssignmentDetailFail.type });
    }
  } catch (error) {
    yield put({ type: Slice.getAssignmentDetailFail.type });
  }
}

function* updateCommitEffortFlow({ payload }) {
  try {
    const response = yield call(APIs.updateCommitEffort, payload);
    if (response.status === StatusCode.NO_CONTENT) {
      yield put({
        type: Slice.updateCommitEffortSuccess.type,
        payload: response.data,
      });
    } else {
      yield put({ type: Slice.updateCommitEffortFail.type });
    }
  } catch (error) {
    yield put({ type: Slice.updateCommitEffortFail.type });
  }
}

function* assignmentWatcher() {
  yield takeLatest(Slice.getAssignmentSummaryRequest, getAssignmentSummaryFlow);
  yield takeLatest(Slice.getAssignmentDetailRequest, getAssignmentDetailFlow);
  yield takeLatest(Slice.updateCommitEffortRequest, updateCommitEffortFlow);
}

export default assignmentWatcher;
